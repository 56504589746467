import React, { useState, useEffect, useReducer, useContext } from "react"
import { I18n } from "aws-amplify"
import { makeStyles } from "@material-ui/core/styles"

import API, { graphqlOperation } from "@aws-amplify/api"
import {
  listCoupons,
  listCountries,
  listWarehousesByCountry,
} from "../../graphql/queries"

import GlobalContext from "../../context/global-context"
import couponsReducer from "../../reducers/coupons"
import {
  FormControl,
  Select,
  InputLabel,
  Typography,
  MenuItem,
  TextField,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Grid,
  Table,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Aladdin from "../../aladdin-config"
import Coupon from "./Coupon"
import AddCouponForm from "./AddCouponForm"

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: 150,
  },
  grid: {
    padding: theme.spacing(5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    flip: false,
    textAlign: "left",
    direction: "ltr",
  },
}))
const aladdin = Aladdin()
const CouponsPage = () => {
  const classes = useStyles()
  const { currentUser } = useContext(GlobalContext)
  const [
    { coupons, singleCustomerCoupons, singleCustomerCouponsUsed },
    dispatch,
  ] = useReducer(couponsReducer, {
    coupons: [],
    singleCustomerCoupons: [],
    singleCustomerCouponsUsed: 0,
  })
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedWarehouse, setSelectedWarehouse] = useState("")
  const [countries, setCountries] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [addCouponOpen, setAddCouponOpen] = useState(false)

  useEffect(() => {
    loadCountries()
  }, [])
  useEffect(() => {
    if (selectedCountry !== "") {
      loadWarehouses()
    }
  }, [selectedCountry])
  useEffect(() => {
    if (selectedWarehouse !== "") {
      loadCoupons()
    }
  }, [selectedWarehouse])

  const loadCountries = async () => {
    const params = {
      userLocale: currentUser.locale,
    }
    try {
      const countriesData = await API.graphql(
        graphqlOperation(listCountries, params)
      )
      setCountries(countriesData.data.listCountries.items)
    } catch (error) {
      console.log(error)
    }
  }

  const loadWarehouses = async () => {
    const params = {
      countryCode: selectedCountry,
    }
    try {
      const warehousesData = await API.graphql(
        graphqlOperation(listWarehousesByCountry, params)
      )
      setWarehouses(warehousesData.data.listWarehousesByCountry.items)
    } catch (error) {
      console.log(error)
    }
  }

  const countrySelected = async (countryCode) => {
    setSelectedCountry(countryCode)
  }

  const warehouseSelected = async (warehouseId) => {
    setSelectedWarehouse(warehouseId)
  }

  const loadCoupons = async () => {
    const params = {
      filter: {
        countryCode: selectedCountry,
        warehouseId: selectedWarehouse,
      },
    }

    const response = await API.graphql(graphqlOperation(listCoupons, params))
    const coupons = response.data.listCoupons
    dispatch({ type: "LOAD_COUPONS", coupons: coupons })
  }

  const closeAddCoupon = () => {
    setAddCouponOpen(false)
  }

  const newCoupon = () => {
    setAddCouponOpen(true)
  }

  return (
    <Paper>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item className={classes.gridItem}>
          <Typography variant="h5" color="textPrimary">
            {I18n.get("label_warehouse")}
          </Typography>
        </Grid>
        <Grid item>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="country-select">
              {I18n.get("label_country")}
            </InputLabel>
            <Select
              onChange={(e) => countrySelected(e.target.value)}
              value={selectedCountry}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "country-select",
              }}
            >
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.countryCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="warehouse-select">
              {I18n.get("label_warehouse")}
            </InputLabel>
            <Select
              onChange={(e) => warehouseSelected(e.target.value)}
              value={selectedWarehouse}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "warehouse-select",
              }}
            >
              {warehouses.map((warehouse, index) => (
                <MenuItem key={index} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            onClick={newCoupon}
            disabled={selectedWarehouse === ""}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Typography>
        {I18n.get("label_single_customer_coupons_count")}:{" "}
        {singleCustomerCoupons.length}
      </Typography>
      <Typography>
        {I18n.get("label_single_customer_coupons_used")}:{" "}
        {singleCustomerCouponsUsed}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{I18n.get("label_code")}</TableCell>
              <TableCell>{I18n.get("label_name")}</TableCell>
              <TableCell>{I18n.get("label_description")}</TableCell>
              <TableCell>{I18n.get("label_coupon_type")}</TableCell>
              <TableCell>{I18n.get("label_value")}</TableCell>
              <TableCell>{I18n.get("label_create_date")}</TableCell>
              <TableCell>{I18n.get("label_end_date")}</TableCell>
              <TableCell>{I18n.get("label_order_limit")}</TableCell>
              <TableCell>{I18n.get("label_coupon_reusable")}</TableCell>
              <TableCell>{I18n.get("label_orders_count")}</TableCell>
              <TableCell>{I18n.get("label_customers_count")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon, index) => (
              <Coupon key={index} coupon={coupon} dispatch={dispatch} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={addCouponOpen}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {I18n.get("label_new_coupon")}
        </DialogTitle>
        <DialogContent>
          <AddCouponForm
            countryCode={selectedCountry}
            warehouseId={selectedWarehouse}
            closeHandler={closeAddCoupon}
            dispatch={dispatch}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  )
}

export default CouponsPage
