const occasionsReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_OCCASION":
      let newOccasionsList = state.occasions.map((occasion) => {
        if (occasion.createDate === action.occasion.createDate) {
          return action.occasion
        }
        return occasion
      })
      return { ...state, occasions: newOccasionsList }
    case "LOAD_OCCASIONS":
      return { ...state, occasions: action.occasions }
    case "ADD_OCCASION":
      return { ...state, occasions: [action.occasion, ...state.occasions] }
    default:
      return state
  }
}
export default occasionsReducer
