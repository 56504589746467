import React, { useContext, useState, useEffect } from "react"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { addItemToOccasion } from "../../graphql/mutations"
import { listItemsOfOccasion, searchItemsByName } from "../../graphql/queries"
import GlobalContext from "../../context/global-context"
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

const OccasionDetails = (props) => {
  const { currentUser } = useContext(GlobalContext)
  const [occasionItems, setOccasionItems] = useState([])
  const [options, setOptions] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const {
    match: { params },
  } = props
  const warehouseId = params.warehouseId
  const occasionId = params.occasionId

  let subscribed = true

  useEffect(() => {
    loadItemsOfOccasion()
    return () => {
      subscribed = false
    }
  }, [])

  const loadItemsOfOccasion = () => {
    const params = {
      occasionId,
      warehouseId,
      userLocale: currentUser.locale,
      limit: 1000,
    }
    API.graphql(graphqlOperation(listItemsOfOccasion, params)).then(
      (response) => {
        const items = response.data.listItemsOfOccasion.items
        if (subscribed) {
          setOccasionItems(items)
        }
      }
    )
  }

  const searchInputChanged = (event, value, reason) => {
    if (value.length >= 3) {
      searchItems(value)
    }
  }

  const searchItems = (text) => {
    const params = {
      name: text,
      warehouseId,
      userLocale: currentUser.locale,
      limit: 100,
    }
    API.graphql(graphqlOperation(searchItemsByName, params)).then(
      (response) => {
        const items = response.data.searchItemsByName.items
        setOptions(items)
      }
    )
  }
  const itemSelected = (event, value, reason) => {
    if (value) {
      setSelectedItem(value)
    }
  }
  const addItem = async () => {
    if (selectedItem) {
      const params = {
        sku: selectedItem.sku,
        warehouseId,
        occasionId,
      }
      try {
        await API.graphql(graphqlOperation(addItemToOccasion, params))
        setOccasionItems([...occasionItems, selectedItem])
        setSelectedItem(null)
        setOptions([])
      } catch (error) {}
    }
  }
  return (
    <Paper>
      <Typography>{occasionItems.length}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={options}
            onChange={itemSelected}
            onInputChange={searchInputChanged}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={I18n.get("action_search")}
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={addItem}
          >
            {I18n.get("action_add")}
          </Button>
        </Grid>
      </Grid>
      <Table>
        <TableBody>
          {occasionItems.map((item) => (
            <TableRow key={item.sku}>
              <TableCell>{item.sku}</TableCell>
              <TableCell>{item.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default OccasionDetails
