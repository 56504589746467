import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'

import ItemsContext from '../../context/items-context'



const RunOutShelvesSummary = ()=>{
    const {runOutShelves} = useContext(ItemsContext)
    

    return (
        <React.Fragment>
            <Typography >{runOutShelves.length} {I18n.get('message_number_run_out_shelves')}</Typography>
            <Link to="/runOutShelves">{I18n.get('label_details')}</Link>
        </React.Fragment>
    )

}

export default RunOutShelvesSummary