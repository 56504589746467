import React, { useReducer, useContext, useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'
import { API, graphqlOperation } from 'aws-amplify'

import ProvidersContext from '../../context/providers-context'
import providersReducer from '../../reducers/providers'
import { listProviders } from '../../graphql/queries'
import ProvidersList from './ProvidersList'
import ProviderFormDialog from './ProviderFormDialog'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120

    },
    grid: {
        padding: theme.spacing(5)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ProvidersPage = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(providersReducer, { providers: [] })
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const newProvider = () => {
        setOpen(true)
    }

    useEffect(() => {
        let isSubscribed = true
        if(isSubscribed){
            loadProviders()
        }
        
        return () => {
            isSubscribed = false
        }

    }, [])

    const populateLoadedProviders = (loadedProviders) => {
        console.log(loadedProviders)
        dispatch({ type: 'LOAD_PROVIDERS', providers: loadedProviders })
    }

    const loadProviders = () => {
        loadProvidersFromDB()
            .then((data) => {
                populateLoadedProviders(data.data.listProviders.items)
            }
            ).catch((data) => {
                console.log(data.errors)
            })
    }

    async function loadProvidersFromDB() {
        let params = { }
        return API.graphql(graphqlOperation(listProviders, params))

    }

    return (
        <Paper >
            <ProvidersContext.Provider value={{ ...state, dispatch }} >
                <Grid className={classes.grid} container spacing={3} justify="space-between">
                    <Grid item>
                        <Typography variant="h5" color='textPrimary'>
                            {I18n.get('label_providers')}
                            <IconButton color="primary" onClick={newProvider}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Typography>

                    </Grid>
                </Grid>
                <ProvidersList />
                <ProviderFormDialog
                    open={open}
                    onClose={handleClose}
                />
            </ProvidersContext.Provider>
        </Paper>
    )
}

export default ProvidersPage