
const comparatorByName = (a, b)=>{
    return a.name > b.name ? 1 : -1
}

const comparatorBySku = (a, b)=>{
    return a.sku > b.sku ? 1 : -1
}

const productsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PARENT':
            return { ...state, parent: action.parent }
        case 'LOAD_PRODUCTS':
            let sorted1 = action.products
            return { ...state, products: sorted1.sort(comparatorByName) }
        case 'LOAD_MORE_PRODUCTS':
            let sorted2 = [...state.products, ...action.products]
            return { ...state, products: sorted2.sort(comparatorByName) }
        case 'CLEAR_PRODUCTS':
            return { ...state, products: [] }
        case 'CREATE_PRODUCT':
            return { ...state, products: [...state.products, action.product] }
        case 'UPDATE_PRODUCT':
            let newProductsList = state.products.map((product) => {
                if (product.sku === action.product.sku) {
                    return action.product
                }
                return product
            })
            return { ...state, products: newProductsList }
            case 'REORDER':
                let sorted3 = state.products
                if(action.by === 'name'){
                    return {...state, products: sorted3.sort(comparatorByName)}
                }else{
                    return {...state, products: sorted3.sort(comparatorBySku)}
                }
                
        default:
            return state
    }
}
export default productsReducer