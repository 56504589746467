import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { createCoupon } from "../../graphql/mutations"
import Aladdin from "../../aladdin-config"
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  LinearProgress,
} from "@material-ui/core"
const aladdin = Aladdin()

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: 150,
  },
  grid: {
    padding: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    flip: false,
    textAlign: "left",
    direction: "ltr",
  },
}))
const AddCouponForm = ({
  countryCode,
  warehouseId,
  closeHandler,
  dispatch,
}) => {
  const classes = useStyles()
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [couponType, setCouponType] = useState("")
  const [value, setValue] = useState("")
  const [endDate, setEndDate] = useState()
  const [orderTotalLimit, setOrderTotalLimit] = useState("")
  const [reusable, setReusable] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [codeError, setCodeError] = useState()
  const [typeError, setTypeError] = useState()
  const [valueError, setValueError] = useState()
  const [beingUpdated, setBeingUpdated] = useState(false)
  const [couponTarget, setCouponTarget] = useState("")
  const [ordersCount, setOrdersCount] = useState("")
  const [sku, setSku] = useState("")
  const [ordersCountError, setOrdersCountError] = useState()
  const [skuError, setSkuError] = useState()

  const coupontTypeOptions = aladdin.couponType.map((type) => {
    const label = I18n.get(type.code)
    return { label, value: type.value }
  })

  const couponTargetOptions = aladdin.couponTarget.map((target) => {
    if (target.value !== "SINGLE_CUSTOMER") {
      const label = I18n.get(target.code)
      return { label, value: target.value }
    }
  })

  const yesNoOptions = [
    { label: I18n.get("label_yes"), value: true },
    { label: I18n.get("label_no"), value: false },
  ]

  const codeChanged = (e) => {
    const newCode = e.target.value.toUpperCase()
    setCode(newCode)
    let valuePart = ""
    if (couponType === "PERCENTAGE") valuePart = `${value} %`
    if (couponType === "AMOUNT") valuePart = `${value} EUR`
    setName(`${newCode} ${valuePart}`)
  }
  const typeChanged = (e) => {
    const newType = e.target.value
    setCouponType(newType)
    let valuePart = ""
    if (newType === "PERCENTAGE") valuePart = `${value} %`
    if (newType === "AMOUNT") valuePart = `${value} EUR`
    setName(`${code} ${valuePart}`)
  }
  const valueChanged = (e) => {
    const newValue = e.target.value
    setValue(newValue)
    let valuePart = ""
    if (couponType === "PERCENTAGE") valuePart = `${newValue} %`
    if (couponType === "AMOUNT") valuePart = `${newValue} EUR`
    setName(`${code} ${valuePart}`)
  }

  const reusableChanged = (e) => {
    setReusable(e.target.value)
  }

  const isActiveChanged = (e) => {
    setIsActive(e.target.value)
  }
  const endDateChanged = (e) => {
    setEndDate(e.target.value)
  }

  const targetChanged = (e) => {
    const value = e.target.value
    setCouponTarget(value)
    if (!value) {
      setSku("")
      setOrdersCount("")
      return
    }
    switch (value) {
      case "NEW_CUSTOMERS":
        setSku("")
        setOrdersCount("")
        break
      case "LOYAL_CUSTOMERS":
        setSku("")
        break
      case "SECTION":
        setOrdersCount("")
        break
      case "SINGLE_PRODUCT":
        setOrdersCount("")
        break
    }
  }

  const ordersCountChanged = (e) => {
    setOrdersCount(e.target.value)
  }

  const skuChanged = (e) => {
    setSku(e.target.value)
  }

  const addCoupon = async () => {
    setBeingUpdated(true)
    if (validateInput()) {
      const params = {
        input: {
          countryCode,
          warehouseId,
          code,
          couponType,
          value:
            couponType === "AMOUNT"
              ? value
              : parseFloat((value / 100).toFixed(2)),
          name,
          description,
          endDate,
          isActive,
          reusable,
          orderTotalLimit: orderTotalLimit === "" ? null : orderTotalLimit,
          couponTarget: couponTarget === "" ? null : couponTarget,
          ordersCount: ordersCount === "" ? null : ordersCount,
          sku: sku === "" ? null : sku,
        },
      }
      try {
        const response = await API.graphql(
          graphqlOperation(createCoupon, params)
        )
        dispatch({ type: "ADD_COUPON", coupon: response.data.createCoupon })
        closeHandler()
      } catch (error) {
        setBeingUpdated(false)
        setCodeError("exists")
      }
    }
  }

  const validateInput = () => {
    let valid = true
    if (!code) {
      valid = false
      setCodeError(I18n.get("error_code_required"))
    }
    if (!couponType) {
      valid = false
      setTypeError(I18n.get("erro_coupon_type_required"))
    }
    if (!value) {
      valid = false
      setValueError(I18n.get("erro_value_required"))
    }
    switch (couponTarget) {
      case "LOYAL_CUSTOMERS":
        if (!ordersCount) {
          valid = false
          setOrdersCountError(I18n.get("error_min_orders_count_required"))
        }
        break
      case "SECTION":
        if (!sku) {
          valid = false
          setSkuError(I18n.get("error_sku_required"))
        }
        break
      case "SINGLE_PRODUCT":
        if (!sku) {
          valid = false
          setSkuError(I18n.get("error_sku_required"))
        }
        break
    }
    return valid
  }

  const cancelClicked = () => {
    closeHandler()
  }

  return (
    <form>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item>
          <TextField
            className={classes.input}
            variant="outlined"
            label={I18n.get("label_code")}
            value={code}
            onChange={codeChanged}
            error={codeError ? true : false}
            helperText={codeError}
          />
        </Grid>

        <Grid item>
          <TextField
            className={classes.input}
            variant="outlined"
            label={I18n.get("label_name")}
            value={name}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item>
          <FormControl
            className={classes.gridItem}
            error={typeError ? true : false}
          >
            <InputLabel shrink htmlFor="type-select">
              {I18n.get("label_coupon_type")}
            </InputLabel>
            <Select
              onChange={typeChanged}
              value={couponType}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "type-select",
              }}
            >
              <MenuItem key="empty-des" value="">
                ---
              </MenuItem>
              {coupontTypeOptions.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{typeError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label={I18n.get("label_value")}
            value={value}
            onChange={valueChanged}
            type="number"
            error={valueError ? true : false}
            helperText={valueError}
          />
        </Grid>
      </Grid>
      <TextField
        style={{ width: "70%" }}
        variant="outlined"
        label={I18n.get("label_description")}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Grid container spacing={3} className={classes.grid}>
        <Grid item>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="reusable-select">
              {I18n.get("label_coupon_reusable")}
            </InputLabel>
            <Select
              onChange={reusableChanged}
              value={reusable}
              className={classes.selectEmpty}
              inputProps={{
                id: "reusable-select",
              }}
            >
              {yesNoOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="isActive-select">
              {I18n.get("action_activate")}
            </InputLabel>
            <Select
              onChange={isActiveChanged}
              value={isActive}
              className={classes.selectEmpty}
              inputProps={{
                id: "isActive-select",
              }}
            >
              {yesNoOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item>
          <TextField
            variant="outlined"
            label={I18n.get("label_order_limit")}
            value={orderTotalLimit}
            onChange={(e) => setOrderTotalLimit(e.target.value)}
            type="number"
          />
        </Grid>

        <Grid item>
          <TextField
            id="end_date"
            label={I18n.get("label_end_date")}
            type="date"
            onChange={endDateChanged}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="target-select">
              {I18n.get("label_coupon_target")}
            </InputLabel>
            <Select
              onChange={targetChanged}
              value={couponTarget}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "target-select",
              }}
            >
              <MenuItem key="empty-des" value="">
                ---
              </MenuItem>
              {couponTargetOptions.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {couponTarget === "LOYAL_CUSTOMERS" && (
            <TextField
              variant="outlined"
              label={I18n.get("label_min_orders_count")}
              value={ordersCount}
              onChange={ordersCountChanged}
              type="number"
              error={ordersCountError ? true : false}
              helperText={ordersCountError}
            />
          )}
          {(couponTarget === "SECTION" ||
            couponTarget === "SINGLE_PRODUCT") && (
            <TextField
              variant="outlined"
              label={I18n.get("label_sku")}
              value={sku}
              onChange={skuChanged}
              error={skuError ? true : false}
              helperText={skuError}
            />
          )}
        </Grid>
      </Grid>
      <FormControl className={classes.gridItem}>
        <Button variant="contained" color="primary" onClick={addCoupon}>
          {I18n.get("action_add")}
        </Button>
      </FormControl>
      <FormControl className={classes.gridItem}>
        <Button variant="outlined" color="primary" onClick={cancelClicked}>
          {I18n.get("action_cancel")}
        </Button>
      </FormControl>
      {beingUpdated && <LinearProgress color="secondary" />}
    </form>
  )
}

export default AddCouponForm
