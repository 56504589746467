import React, { useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"

import ProductsContext from "../../context/products-context"
import Product from "./Product"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}))

const ProductsList = ({ currentPage }) => {
  const { products } = useContext(ProductsContext)
  const [expanded, setExpanded] = React.useState(false)
  const classes = useStyles()
  const panelExpansionHandler = (sku, isExpanded) => {
    setExpanded(isExpanded ? sku : false)
  }

  return (
    <div className={classes.root}>
      {products.map(
        (product, index) =>
          index < currentPage * 10 && (
            <Product
              key={product.sku}
              product={product}
              expanded={expanded === product.sku}
              panelExpansionHandler={panelExpansionHandler}
            />
          )
      )}
    </div>
  )
}

export default ProductsList
