const couponsReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_COUPON":
      let newCouponsList = state.coupons.map((coupon) => {
        if (coupon.code === action.coupon.code) {
          return action.coupon
        }
        return coupon
      })
      return { ...state, coupons: newCouponsList }
    case "LOAD_COUPONS":
      let coupons = []
      let singleCustomerCoupons = []
      let singleCustomerCouponsUsed = 0
      for (let coupon of action.coupons) {
        if (coupon.couponTarget && coupon.couponTarget === "SINGLE_CUSTOMER") {
          singleCustomerCoupons.push(coupon)
          if (coupon.customers.length !== 0) {
            singleCustomerCouponsUsed += 1
          }
        } else {
          coupons.push(coupon)
        }
      }
      return {
        ...state,
        coupons,
        singleCustomerCoupons,
        singleCustomerCouponsUsed,
      }
    case "ADD_COUPON":
      return { ...state, coupons: [action.coupon, ...state.coupons] }
    default:
      return state
  }
}
export default couponsReducer
