import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'

import TranslationEditDialog from './TranslationEditDialog'

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 20,
        height: 20,
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.spacing(1),
    }
}));

const Translation = (props) => {

    const [open, setOpen] = useState(false);
    const classes = useStyles()

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = (locale, newTranslation) => {
        props.editHandler(locale, newTranslation)
        setOpen(false)
    }


    return (
        <React.Fragment>
            <ListItem divider dense={true}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        {props.translation.locale.toUpperCase()}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.translation.translation}
                />
                <ListItemSecondaryAction>
                    {props.editHandler && <IconButton color="primary" onClick={handleOpen}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    }
                    {props.removeHandler && <IconButton disabled={!props.canRemove} color="primary">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    }

                </ListItemSecondaryAction>

            </ListItem>
            <TranslationEditDialog
                open={open}
                locale={props.translation.locale}
                onClose={handleClose}
                onSave={handleSave}
            />
        </React.Fragment>
    )
}

export default Translation