import React, {useContext} from 'react'

import {I18n} from 'aws-amplify'

import EmployeesContext from '../../context/employees-context'
import Employee from './Employee'


const EmployeesList = ()=>{

    const {employees} = useContext(EmployeesContext)
    return (
        <div>
            <h3>{I18n.get('label_employees')}</h3>
            <div className="list-header">
                <div className="col-2-of-10">{I18n.get('label_email')}</div>
                <div className="col-1-half-of-10">{I18n.get('label_name')}</div>
                <div className="col-1-of-10">{I18n.get('label_telephone')}</div>
                <div className="col-2-of-10">{I18n.get('label_address')}</div>
                <div className="col-1-of-10">{I18n.get('label_country')}</div>
                <div className="col-1-of-10">{I18n.get('label_language')}</div>
                <div className="col-1-of-10">{I18n.get('label_position')}</div>
                <div className="col-half-of-10">{I18n.get('label_actions')}</div>
            </div>
            { employees && employees.length > 0 ? (
                    <>
                        {employees.map((employee) => (
                            <Employee key={employee.username} employee={employee} />
                        ))}
                    </>
                ) : (
                    <div>No employees yet</div>
                )
            }
        </div>
        

    )
    
    
}


export default EmployeesList