import React, { useState, userContext } from 'react'

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';


const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: 0,
    },
    tableCell: {
        paddingTop: 0,
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16
    }
}));

const Provider = ({ provider }) => {

    const classes = useStyles()
    const addressParts = provider.address.split('#')
    const formattedAddress = `${addressParts[2]}, ${addressParts[1]}, ${addressParts[0]}`
    return (
        <React.Fragment>
            <TableRow hover className={classes.tableRow}>
                <TableCell className={classes.tableCell}
                    key='name'
                    style={{ minWidth: 50 }}
                >
                    <Typography>
                        {provider.providerName}
                    </Typography>
                </TableCell>


                <TableCell className={classes.tableCell}
                    key='address'
                    style={{ minWidth: 150 }}
                >
                    <Typography>
                        {formattedAddress}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='contactName'
                    style={{ minWidth: 50 }}
                >
                    <Typography>
                        {provider.contactName}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='telephone'
                    style={{ minWidth: 50 }}
                >
                    <Typography>
                        {provider.telephone}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='website'
                    style={{ minWidth: 50 }}
                >
                    <Typography>
                        {provider.website}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='eidt'
                    style={{ minWidth: 50 }}
                >
                    <Typography>
                        {provider.providerName}
                    </Typography>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default Provider