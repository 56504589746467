import {createMuiTheme} from '@material-ui/core'
import {indigo} from '@material-ui/core'

export const AladdinTheme = createMuiTheme({
    palette:{
        primary:{
            main: '#4792f3',
            light: '#83c2ff',
            dark: '#0065c0'
        },
        secondary:{
            main: '#ffc107',
            light: '#fff350',
            dark: '#c79100'
        }
    },
    typography:{
        fontFamily:[
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Roboto"',
            '"Oxygen"',
            '"Ubuntu"',
            '"Cantarell"',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif'
        ].join(',')
    }
})