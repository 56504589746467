import React, { useState, useEffect } from "react"
import { I18n } from "aws-amplify"
import { API, graphqlOperation } from "aws-amplify"
import { activateDeactivateCoupon } from "../../graphql/mutations"
import { Button, TableRow, TableCell } from "@material-ui/core"
import Aladdin from "../../aladdin-config"
import { getFormattedShortDate } from "../../utils/dateUtil"
const aladdin = Aladdin()
const Coupon = ({ coupon, dispatch }) => {
  const [version, setVersion] = useState(1)

  useEffect(() => {
    setVersion(version + 1)
  }, [coupon])

  const changeStatus = async () => {
    const params = {
      code: coupon.code,
      isActive: !coupon.isActive,
    }
    const response = await API.graphql(
      graphqlOperation(activateDeactivateCoupon, params)
    )
    const newCoupon = response.data.activateDeactivateCoupon
    dispatch({ type: "UPDATE_COUPON", coupon: newCoupon })
  }

  const getCouponValue = () => {
    if (coupon.couponType === "AMOUNT") {
      return coupon.value
    }
    if (coupon.couponType === "PERCENTAGE") {
      return formatPrecentage(coupon.value)
    }
  }

  const formatPrecentage = (value) => {
    const v = value * 100
    return `${Math.floor(v)} %`
  }
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{coupon.code}</TableCell>
        <TableCell>{coupon.name}</TableCell>
        <TableCell>{coupon.description}</TableCell>
        <TableCell>{I18n.get(`coupon_type_${coupon.couponType}`)}</TableCell>
        <TableCell>{getCouponValue()}</TableCell>
        <TableCell>{getFormattedShortDate(coupon.createDate)}</TableCell>
        <TableCell>
          {coupon.endDate ? getFormattedShortDate(coupon.endDate) : "-"}
        </TableCell>
        <TableCell>{coupon.orderTotalLimit}</TableCell>
        <TableCell>
          {coupon.reusable ? I18n.get("label_yes") : I18n.get("label_no")}
        </TableCell>
        <TableCell>{coupon.orders.length}</TableCell>
        <TableCell>{coupon.customers.length}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={changeStatus} color="primary">
            {coupon.isActive
              ? I18n.get("action_deactivate")
              : I18n.get("action_activate")}
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default Coupon
