import React, {useContext} from 'react';

import ShipmentCost from '../helpers/ShipmentCost'
import Translation from '../helpers/Translation';
const Country = ({country}) => {
    return (
        <div className="list-item">
            <div  className="col-1-of-10">{country.name}</div>
            <div className="col-2-of-10">
                {country.translations.map((translation) =>(
                    <Translation key={translation.locale} translation={translation} />
                ))}
            </div>
            <div className="col-1-of-10">
                {country.VATs.map((vat)=>(<li key={vat}>{Math.floor(vat*100)}%</li>))}
                
            </div>
            <div className="col-2-of-10">
                {country.shipmentCosts.map((shipmentCost) =>(
                    <ShipmentCost key={shipmentCost.threshold} shipmentCost={shipmentCost} />
                ))}
            </div>
            <div className="col-2-of-10">
                {country.paymentType.map((payment)=>(<li key={payment}>{payment}</li>))}
            </div>
            <div className="col-2-of-10">{country.managerName}</div>
        </div>
    )
}
export { Country as default}