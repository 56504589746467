import React, { useEffect, useState } from "react"
import { API, graphqlOperation, I18n } from "aws-amplify"
import { getCustomersWhoRespondedToFriendCodeReminder } from "../../graphql/queries"
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles({
  container: {
    width: "70%",
  },
  tableWrapper: {
    height: 600,
    overflow: "auto",
  },
  customerRow: {
    cursor: "pointer",
  },
})

const FriendCodeReminderDetails = (props) => {
  const classes = useStyles()
  const {
    match: { params },
  } = props
  const createDate = params.createDate
  const [customers, setCustomers] = useState([])

  let subscribed = true
  useEffect(() => {
    getRespondedCustomers()
    return () => {
      subscribed = false
    }
  }, [])

  const getRespondedCustomers = () => {
    const params = {
      createDate,
    }
    API.graphql(
      graphqlOperation(getCustomersWhoRespondedToFriendCodeReminder, params)
    )
      .then((response) => {
        if (subscribed) {
          setCustomers(
            response.data.getCustomersWhoRespondedToFriendCodeReminder
          )
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const openDetails = (number) => {
    props.history.push(`/customerDetails/${number}`)
  }
  return (
    <Paper>
      <Typography>
        {I18n.get("label_customers_responded_friend_code_reminder")}:{" "}
        {customers.length}
      </Typography>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key="customerNumber" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_customer_number")}
                </Typography>
              </TableCell>
              <TableCell key="customerNmae" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_name")}
                </Typography>
              </TableCell>

              <TableCell key="lastOrder" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_orders_count")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow
                className={classes.customerRow}
                key={customer.customerNumber}
                onClick={(e) => openDetails(customer.customerNumber)}
              >
                <TableCell style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {customer.customerNumber}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  {customer.billingAddress && (
                    <Typography variant="subtitle1" color="textPrimary">
                      {`${customer.billingAddress.firstName} ${customer.billingAddress.lastName}`}
                    </Typography>
                  )}
                </TableCell>

                <TableCell style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {customer.ordersCount}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default FriendCodeReminderDetails
