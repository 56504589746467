import React, { useReducer, useContext, useState, useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"

import { I18n } from "aws-amplify"
import { API, graphqlOperation } from "aws-amplify"

import GlobalContext from "../../context/global-context"
import ProductsContext from "../../context/products-context"
import productsReducer from "../../reducers/products"
import {
  listProducts,
  listSections,
  listCategories,
} from "../../graphql/queries"
import CreateProductDialog from "./CreateProductDialog"
import ProductsList from "./ProductsList"
import EditBarcodeDialog from "./EditBarcodeDialog"

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
  grid: {
    padding: theme.spacing(5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const ProductsPage = () => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(productsReducer, {
    parent: null,
    products: [],
  })
  const { currentUser } = useContext(GlobalContext)
  const [sections1, setSections1] = useState([])
  const [sections2, setSections2] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedSection1SKU, setSelectedSection1SKU] = useState("")
  const [selectedSection2SKU, setSelectedSection2SKU] = useState("")
  const [selectedCategorySKU, setSelectedCategorySKU] = useState("")
  const [selectedAncestorSKU, setSelectedAncestorSKU] = useState("")
  const [open, setOpen] = useState(false)
  const [nextToken, setNextToken] = useState()
  const [more, setMore] = useState(false)
  const [productToEditBarcode, setProducToEditBarcode] = useState()
  const [productToEdit, setProductToEdit] = useState()
  const [editOpen, setEditOpen] = useState(false)
  const [orderBy, setOrderBy] = useState("name")
  const [currentPage, setCurrentPage] = useState(1)

  const userLocale = currentUser.locale
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadSections1()
    }

    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    loadSections2()
  }, [selectedSection1SKU])

  useEffect(() => {
    if (selectedSection2SKU) {
      loadCategories()
    }
  }, [selectedSection2SKU])
  useEffect(() => {
    //Since the nextChildSKU of the parent has been changed, it needs to be replaced
    replaceReloadedCategory()
  }, [state.parent])

  const queryChanged = () => {
    setOrderBy("name")
    setCurrentPage(1)
    setNextToken(null)
    setMore(false)
    dispatch({ type: "CLEAR_PRODUCTS" })
  }

  useEffect(() => {
    loadProducts()
  }, [selectedAncestorSKU])

  const replaceReloadedCategory = () => {
    if (state.parent) {
      let newCategoriesList = categories.map((category) => {
        if (category.sku === state.parent.sku) {
          return state.parent
        }
        return category
      })
      setCategories(newCategoriesList)
    }
  }

  const categorySelected = (sku) => {
    let selectedParent = null
    categories.map((category) => {
      if (sku === category.sku) {
        selectedParent = category
      }
    })
    if (selectedParent) {
      dispatch({ type: "SET_PARENT", parent: selectedParent })
    }
    if (sku != selectedAncestorSKU) {
      queryChanged()
      setSelectedAncestorSKU(sku)
    }

    setSelectedCategorySKU(sku)
  }

  const section1Seclected = (sku) => {
    queryChanged()
    setSelectedSection1SKU(sku)
    setSelectedAncestorSKU(sku)
    setSelectedSection2SKU("")
    setSelectedCategorySKU("")
    dispatch({ type: "SET_PARENT", parent: null })
  }

  const section2Seclected = (sku) => {
    queryChanged()
    setSelectedSection2SKU(sku)
    setSelectedAncestorSKU(sku)
    setSelectedCategorySKU("")
    dispatch({ type: "SET_PARENT", parent: null })
  }

  const populateLoadedProducts = (loadedProducts) => {
    dispatch({ type: "LOAD_MORE_PRODUCTS", products: loadedProducts })
  }

  const loadSections1 = () => {
    loadSectionsFromDB()
      .then((data) => {
        setSections1(data.data.listSections.items)
      })
      .catch((data) => {
        console.log(data)
      })
  }

  const loadSections2 = () => {
    loadSectionsFromDB(selectedSection1SKU)
      .then((data) => {
        setSections2(data.data.listSections.items)
      })
      .catch((data) => {
        console.log(data)
      })
  }

  const loadCategories = () => {
    loadCategoriesFromDB(selectedSection2SKU)
      .then((data) => {
        setCategories(data.data.listCategories.items)
      })
      .catch((data) => {
        console.log(data)
      })
  }

  const loadProducts = () => {
    loadProductsFromDB()
      .then((data) => {
        populateLoadedProducts(data.data.listProducts.items)
        const token = data.data.listProducts.nextToken
        setNextToken(data.data.listProducts.nextToken)
        if (token) {
          setMore(true)
        } else {
          setMore(false)
        }
      })
      .catch((data) => {
        console.log(data.errors)
      })
  }

  async function loadSectionsFromDB(selectedParent) {
    let params = { userLocale }
    if (selectedParent) {
      params["parent"] = selectedParent
    }
    return API.graphql(graphqlOperation(listSections, params))
  }

  async function loadCategoriesFromDB(selectedParent) {
    let params = { userLocale }
    params["parent"] = selectedParent
    return API.graphql(graphqlOperation(listCategories, params))
  }

  async function loadProductsFromDB() {
    let params = { userLocale, nextToken, limit: 1000 }
    if (selectedAncestorSKU) {
      params["parent"] = selectedAncestorSKU
    }
    return API.graphql(graphqlOperation(listProducts, params))
  }
  const newProduct = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleEditClose = () => {
    setProductToEdit()
    setEditOpen(false)
  }

  const startEditBarcodeHandler = (product) => {
    setProducToEditBarcode(product)
  }

  const startEditProductHandler = (product) => {
    setProductToEdit(product)
    setEditOpen(true)
  }

  const closeEditBarcodeHandler = () => {
    setProducToEditBarcode()
  }
  const handleOrderChanged = (e) => {
    setOrderBy(e.target.value)
    setCurrentPage(1)
    dispatch({ type: "REORDER", by: e.target.value })
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  return (
    <Paper>
      <ProductsContext.Provider
        value={{
          ...state,
          dispatch,
          startEditBarcodeHandler,
          startEditProductHandler,
        }}
      >
        <Grid
          className={classes.grid}
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {I18n.get("label_products")}
              <IconButton
                color="primary"
                onClick={newProduct}
                disabled={selectedCategorySKU === ""}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="section1-select">
                {I18n.get("label_section1")}
              </InputLabel>
              <Select
                onChange={(e) => section1Seclected(e.target.value)}
                value={selectedSection1SKU}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "section1-select",
                }}
              >
                {sections1.map((section1, index) => (
                  <MenuItem key={index} value={section1.sku}>
                    {section1.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="section2-select">
                {I18n.get("label_section2")}
              </InputLabel>
              <Select
                onChange={(e) => section2Seclected(e.target.value)}
                value={selectedSection2SKU}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "section2-select",
                }}
              >
                {sections2.map((section2, index) => (
                  <MenuItem key={index} value={section2.sku}>
                    {section2.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="category-select">
                {I18n.get("label_category")}
              </InputLabel>
              <Select
                onChange={(e) => categorySelected(e.target.value)}
                value={selectedCategorySKU}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "category-select",
                }}
              >
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category.sku}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormControl component="fieldset">
          <FormLabel component="legend">{I18n.get("label_order_by")}</FormLabel>
          <RadioGroup
            aria-label="position"
            name="position"
            value={orderBy}
            onChange={handleOrderChanged}
            row
          >
            <FormControlLabel
              value="name"
              control={<Radio color="primary" />}
              label={I18n.get("label_name")}
              labelPlacement="end"
            />
            <FormControlLabel
              value="sku"
              control={<Radio color="primary" />}
              label={I18n.get("label_sku")}
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
        <ProductsList currentPage={currentPage} />
        {currentPage * 10 < state.products.length && (
          <IconButton
            color="secondary"
            //onClick={() => loadProducts()}
            onClick={() => nextPage()}
          >
            <MoreHorizOutlinedIcon />
          </IconButton>
        )}

        {selectedCategorySKU && (
          <CreateProductDialog open={open} onClose={handleClose} />
        )}
        {productToEdit && (
          <CreateProductDialog
            open={editOpen}
            onClose={handleEditClose}
            product={productToEdit}
          />
        )}
        {productToEditBarcode && (
          <EditBarcodeDialog
            closeHandler={closeEditBarcodeHandler}
            product={productToEditBarcode}
          />
        )}
      </ProductsContext.Provider>
    </Paper>
  )
}

export default ProductsPage
