import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'

import Ticket from './Ticket'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 800,
    overflow: 'auto',
  },
});

const TicketsList = ({ ticketStatus, tickets, assignTicketHandler, openTicketHandler }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              key='createDate'
              style={{ width: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_date')}
              </Typography>
            </TableCell>
            <TableCell
              key='customerLocale'
              style={{ width: 100 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_language')}
              </Typography>
            </TableCell>
            <TableCell
              key='customerNumber'
              style={{ width: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_customer_number')}
              </Typography>
            </TableCell>
            <TableCell
              key='employeeName'
              style={{ width: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_employee_name')}
              </Typography>
            </TableCell>

            <TableCell
              key='actions'
              style={{ width: 100 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {ticketStatus === 'NEW' ? I18n.get('action_start_ticket') : I18n.get('action_open_ticket')}
              </Typography>

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((ticket) => (
            <Ticket key={ticket.ticketId} ticket={ticket} assignTicketHandler={assignTicketHandler} openTicketHandler={openTicketHandler} />
          ))}
        </TableBody>
      </Table>
    </div>
  )

}
export default TicketsList