import React, { useEffect, useState } from "react"
import { API, graphqlOperation, I18n } from "aws-amplify"
import { listDiscountsCampaigns } from "../../graphql/queries"
import { Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { getFormattedShortDate } from "../../utils/dateUtil"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"

const useStyles = makeStyles({
  container: {},
  tableWrapper: {
    height: 600,
    overflow: "auto",
    width: "70%",
  },
  row: {
    cursor: "pointer",
  },
})

const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"

const PersonalizedDiscountsCampaignsPage = (props) => {
  const classes = useStyles()
  const [campaigns, setCampaigns] = useState([])
  let subscribed = true
  useEffect(() => {
    loadCampaigns()
    return () => {
      subscribed = false
    }
  }, [])

  const loadCampaigns = () => {
    const params = {
      warehouseId: warehouseId,
    }
    API.graphql(graphqlOperation(listDiscountsCampaigns, params))
      .then((response) => {
        if (subscribed) {
          setCampaigns(response.data.listDiscountsCampaigns)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const openDetails = (campaignDate) => {
    props.history.push(
      `/discountsCampaignDetails/${campaignDate}/${warehouseId}`
    )
  }
  return (
    <Paper>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key="date" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_create_date")}
                </Typography>
              </TableCell>
              <TableCell key="customers" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_customers_count")}
                </Typography>
              </TableCell>
              <TableCell key="discountTyps" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_discount_type")}
                </Typography>
              </TableCell>
              <TableCell key="hasOrders" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_customer_has_orders")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign) => (
              <TableRow
                className={classes.row}
                key={campaign.campaignDate}
                onClick={(e) => openDetails(campaign.campaignDate)}
              >
                <TableCell style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {getFormattedShortDate(campaign.campaignDate)}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {campaign.customers.length}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {campaign.discountTypes.map((type) => type + " ")}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {campaign.customerHasOrders
                      ? I18n.get("label_yes")
                      : I18n.get("label_no")}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default PersonalizedDiscountsCampaignsPage
