import React, { useState, useContext } from "react"
import { withStyles } from "@material-ui/core/styles"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getDiscountsDetails } from "../../graphql/queries"
import {
  Paper,
  Button,
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import GlobalContext from "../../context/global-context"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import { getFormattedShortDate } from "../../utils/dateUtil"

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    "& div": {
      marginBottom: theme.spacing(1),
    },
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  panelText: {
    fontSize: theme.typography.pxToRem(12),
  },

  tableContainer: {
    width: "100%",
    maxHeight: 200,
  },
}))

const DiscountsDetailsPage = () => {
  const { currentUser } = useContext(GlobalContext)
  const classes = useStyle()
  const [discounts, setDiscounts] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [expanded, setExpanded] = React.useState("")
  const [discountsBeingLoaded, setDiscountsBeingLoaded] = useState(false)
  const countryCode = "de"
  const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"

  const startDateChanged = (e) => {
    setStartDate(e.target.value)
  }
  const endDateChanged = (e) => {
    setEndDate(e.target.value)
  }

  const searchClicked = () => {
    setDiscounts()
    setDiscountsBeingLoaded(true)
    loadDiscounts()
  }

  const loadDiscounts = async () => {
    let params = {
      countryCode,
      warehouseId,
      userLocale: currentUser.locale,
    }
    if (startDate) {
      params.startDate = startDate
    }
    if (endDate) {
      params.endDate = endDate
    }
    const response = await API.graphql(
      graphqlOperation(getDiscountsDetails, params)
    )
    setDiscountsBeingLoaded(false)
    const sortedDiscounts = response.data.getDiscountsDetails.sort((a, b) => {
      if (a.startDate >= b.startDate) return 1
      return -1
    })
    setDiscounts(sortedDiscounts)
  }
  const formatPercentage = (value) => {
    const v = (value * 100).toFixed(0)
    return `${v} %`
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const getMargin = (price, cost) => {
    if (cost) {
      const margin = price - cost

      const absMargin = parseFloat(Math.abs(margin).toFixed(2))
      return (
        <Typography
          className={classes.panelText}
          color={margin < 0 ? "error" : "secondary"}
        >
          {I18n.get("label_avg_margin")}: {absMargin}
        </Typography>
      )
    } else {
      return <Typography className={classes.panelText}>-</Typography>
    }
  }
  return (
    <React.Fragment>
      <Grid container justify="flex-start">
        <TextField
          id="start_date"
          label={I18n.get("label_from")}
          type="date"
          className={classes.textField}
          onChange={startDateChanged}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="end_date"
          label={I18n.get("label_to")}
          type="date"
          className={classes.textField}
          onChange={endDateChanged}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Button
        className={classes.action}
        variant="contained"
        color="primary"
        onClick={searchClicked}
      >
        {I18n.get("action_search")}
      </Button>

      <Paper className={classes.container}>
        <Typography>{I18n.get("label_discounts_details")}</Typography>
        {discounts ? (
          <React.Fragment>
            {discounts.map((discount) => (
              <Accordion
                style={{ width: "100%" }}
                key={discount.id}
                square
                expanded={expanded === discount.id}
                onChange={handleChange(discount.id)}
              >
                <AccordionSummary>
                  <Grid container justify="space-between">
                    <Grid item sm={1}>
                      <Typography className={classes.panelText}>
                        {discount.sku}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.panelText}>
                        {discount.name}
                      </Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Typography className={classes.panelText}>
                        {getFormattedShortDate(discount.startDate)}
                      </Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Typography className={classes.panelText}>
                        {I18n.get("label_discount")}:{" "}
                        {formatPercentage(discount.discount)}
                      </Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Typography className={classes.panelText}>
                        {I18n.get("label_count")}: {discount.soldItems.length}
                      </Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Typography className={classes.panelText}>
                        {I18n.get("label_total_quantity")}:{" "}
                        {discount.totalQuantity}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{I18n.get("label_quantity")}</TableCell>
                          <TableCell>
                            {I18n.get("label_final_net_price")}
                          </TableCell>
                          <TableCell>{I18n.get("label_final_cost")}</TableCell>
                          <TableCell>{I18n.get("label_margin")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {discount.soldItems.map((soldItem, index) => (
                          <TableRow key={index}>
                            <TableCell>{soldItem.quantity}</TableCell>
                            <TableCell>{soldItem.price}</TableCell>
                            <TableCell>{soldItem.cost}</TableCell>
                            <TableCell>
                              {getMargin(soldItem.price, soldItem.cost)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </React.Fragment>
        ) : (
          discountsBeingLoaded && <CircularProgress color="secondary" />
        )}
      </Paper>
    </React.Fragment>
  )
}

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default DiscountsDetailsPage
