//TODO: This should be merged in independent component with ItemSetupDialog which creates the item
import React, { useState, useContext } from 'react'

import clsx from 'clsx'
import {Decimal} from 'decimal.js'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from "@material-ui/core/styles";

import { I18n} from 'aws-amplify'

const useStyles = makeStyles(theme => ({
    input: {
        width: 100,
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    fullName: {
        width: 400
    }

}));


const SetMaxInCartDialog = ({item, saveHandler, open, closeHandler}) => {

    const classes = useStyles()
    const [maxInCart, setMaxInCart] = useState(item.maxInCart)

    const handleClose = () =>{
        clear()
        closeHandler()
    }
    const handleSave = () => { 
        saveHandler({...item, maxInCart})
        handleClose()
    }
    const clear = ()=>{
        setMaxInCart('')
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">{I18n.get('label_max_in_cart')}</DialogTitle>
            <form >
                <DialogContent>
                    <TextField
                        className={clsx(classes.input, classes.fullName)}
                        autoFocus={true}
                        label={I18n.get('label_max_in_cart')}
                        value={maxInCart}
                        onChange={e => setMaxInCart(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {I18n.get('action_cancel')}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {I18n.get('action_save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default SetMaxInCartDialog