import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';

import GlobalContext from '../context/global-context'

import ResponsiveDrawer from '../components/ResponsiveDrawer'

const CMRoute = ({component: Component, ...rest}) => {
  const {currentUser} = useContext(GlobalContext)
  const rolesSet = new Set(currentUser.groups)
  return (
      <Route {...rest} component={(props) => (
        rolesSet.has('CM') ? (
          <ResponsiveDrawer >
              <Component  {...props} />
            </ResponsiveDrawer>
        ) : (
            <Redirect to="/" />
          )
      )} />
    )
}

export default CMRoute