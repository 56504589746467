import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import { PhotoPicker, S3Image } from 'aws-amplify-react'

import { I18n } from 'aws-amplify'

const PhotoViewer = (props) => {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null)
    const [previewSrc, setPreviewSrc] = useState(props.placeholder)

    const handleOpen = () => {
        if(!props.disableUpload){
            setOpen(true);
        }
        
    }

    const handleClose = () => {
        setOpen(false);
        //setPreviewSrc(props.placeholder)
    }

    const handleSave = () => {
        setOpen(false)
        props.uploadHandler(file.file)
    }

    const setPreview = (data) => {
        //S3Image onLoad sends palceholder even if no image was loaded.
        //Then we need to check if there's no image key from the object,
        //then we need to leave our image placeholder rendered
        
        if (props.imgKey) {
            setPreviewSrc(data)            
        }
    }

    const errorLoadingImage = ()=>{
        setPreviewSrc(null) 
    }

    return (
        <React.Fragment>
            <S3Image imgKey={props.imgKey} hidden onLoad={data => setPreview(data)} onError={() =>{errorLoadingImage()}} />
            <img className={props.className} src={previewSrc} onClick={handleOpen} />

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <PhotoPicker
                        title={I18n.get('action_photo_pick')}
                        preview
                        onPick={file => setFile(file)}
                    />


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {I18n.get('action_cancel')}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {I18n.get('action_save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default PhotoViewer