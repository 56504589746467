import React, {useReducer, useEffect, useContext} from 'react';

import CountriesContext from '../../context/countries-context';
import GlobalContext from '../../context/global-context'

import countriesReducer from '../../reducers/countries';
import CountriesList from './CountiesList';
import CreateCountryForm from './CreateCountryForm'

import API, { graphqlOperation } from '@aws-amplify/api'
import {listCountries} from '../../graphql/queries'

const CountriesPage = () => {
    const [state, dispatch] = useReducer(countriesReducer, {countries:[]})
    const {currentUser} = useContext(GlobalContext)
    useEffect(() =>{
        let isSubscribed = true
        loadCountries()
        .then((data)=>{
            if(isSubscribed){
                setCountries(data.data.listCountries)
            }
        }
        ).catch((data)=>{
            console.log(data.errors)
        })
        
        return ()=>{
            isSubscribed = false
        }
    }, [])

    const setCountries = (listCountriesResul) => {
        const countries = listCountriesResul.items
        dispatch({type:'LOAD_COUNTRIES', countries})
    }

    async function loadCountries(){
        return await API.graphql(graphqlOperation(listCountries, {userLocale:currentUser.locale}))
        
    }
    return (
        <CountriesContext.Provider value={{...state, dispatch}}>
            <div className="form-above-list">
                <CreateCountryForm />
            </div>
            <CountriesList/>
        </CountriesContext.Provider>
    )

}
export { CountriesPage as default}
