import React from 'react'

const ShipmentCost = (props)=>{
    return (
        <>
            <div>{props.shipmentCost.threshold} : {props.shipmentCost.cost}</div>
        </>
    )
}

export default ShipmentCost