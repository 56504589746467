import React, { useState, useEffect, useReducer, useContext } from "react"
import { I18n } from "aws-amplify"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  listBanners,
  listCountries,
  listWarehousesByCountry,
  listOccasions,
} from "../../graphql/queries"
import { createBanner } from "../../graphql/mutations"

import bannersReducer from "../../reducers/banners"
import {
  FormControl,
  Select,
  InputLabel,
  Typography,
  MenuItem,
  List,
  TextField,
  Button,
} from "@material-ui/core"
import Banner from "./Banner"
import GlobalContext from "../../context/global-context"
import Aladdin from "../../aladdin-config"
import { FormatColorReset } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: 150,
  },
  grid: {
    padding: theme.spacing(5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const aladdin = Aladdin()
const BannersPage = () => {
  const classes = useStyles()
  const { currentUser } = useContext(GlobalContext)
  const [{ banners }, dispatch] = useReducer(bannersReducer, { banners: [] })
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedWarehouse, setSelectedWarehouse] = useState("")
  const [countries, setCountries] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [selectedDestination, setSelectedDestination] = useState("")
  const [parameter, setParameter] = useState("")
  const [isDiscountSelcted, setIsDiscountSelected] = useState(false)
  const [selectedDiscountType, setSelectedDiscountType] = useState("SALES")
  const [isOccasionSelected, setIsOccasionSelectee] = useState(false)
  const [occasions, setOccasions] = useState([])
  const [selectedOccasion, setSelectedOccasion] = useState()

  const destinationOptions = aladdin.appDestination.map((destination) => {
    const label = I18n.get(destination.code)
    return { label, value: destination.value }
  })
  const discountOptions = aladdin.discountType.map((discountType) => {
    const label = I18n.get(discountType.code)
    return { label, value: discountType.value }
  })

  useEffect(() => {
    loadCountries()
  }, [])
  useEffect(() => {
    if (selectedCountry !== "") {
      loadWarehouses()
    }
  }, [selectedCountry])
  useEffect(() => {
    if (selectedWarehouse !== "") {
      loadBanners()
    }
  }, [selectedWarehouse])
  useEffect(() => {
    setIsDiscountSelected(selectedDestination === "DISCOUNTS")
    setIsOccasionSelectee(selectedDestination === "OCCASION")
  }, [selectedDestination])

  useEffect(() => {
    if (isOccasionSelected === true) {
      loadOccasions()
    }
  }, [isOccasionSelected])

  const loadCountries = async () => {
    const params = {
      userLocale: currentUser.locale,
    }
    try {
      const countriesData = await API.graphql(
        graphqlOperation(listCountries, params)
      )
      setCountries(countriesData.data.listCountries.items)
    } catch (error) {
      console.log(error)
    }
  }

  const loadWarehouses = async () => {
    const params = {
      countryCode: selectedCountry,
    }
    try {
      const warehousesData = await API.graphql(
        graphqlOperation(listWarehousesByCountry, params)
      )
      setWarehouses(warehousesData.data.listWarehousesByCountry.items)
    } catch (error) {
      console.log(error)
    }
  }

  const countrySelected = async (countryCode) => {
    setSelectedCountry(countryCode)
  }

  const warehouseSelected = async (warehouseId) => {
    setSelectedWarehouse(warehouseId)
  }

  const loadBanners = async () => {
    const params = {
      countryCode: selectedCountry,
      warehouseId: selectedWarehouse,
    }

    const response = await API.graphql(graphqlOperation(listBanners, params))
    const banners = response.data.listBanners
    dispatch({ type: "LOAD_BANNERS", banners: banners })
  }

  const addBanner = async () => {
    let params = {
      countryCode: selectedCountry,
      warehouseId: selectedWarehouse,
    }
    if (selectedDestination !== "") {
      params["appDestination"] = selectedDestination
    }
    if (selectedDestination === "DISCOUNTS") {
      params["parameter"] = selectedDiscountType
    } else if (selectedDestination === "OCCASION") {
      if (!selectedOccasion) return
      params["parameter"] = selectedOccasion
    } else if (parameter !== "") {
      params["parameter"] = `${parameter}`
    }
    try {
      const response = await API.graphql(graphqlOperation(createBanner, params))
      dispatch({ type: "ADD_BANNER", banner: response.data.createBanner })
      setSelectedDestination("")
      setParameter("")
    } catch (error) {
      console.log(error)
    }
  }

  const loadOccasions = async () => {
    const params = {
      warehouseId: selectedWarehouse,
      userLocale: currentUser.locale,
      isActive: true,
    }

    const response = await API.graphql(graphqlOperation(listOccasions, params))
    const data = response.data.listOccasions
    setOccasions(data)
  }

  return (
    <Paper>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item>
          <Typography variant="h5" color="textPrimary">
            {I18n.get("label_warehouse")}
          </Typography>
        </Grid>
        <Grid item>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="country-select">
              {I18n.get("label_country")}
            </InputLabel>
            <Select
              onChange={(e) => countrySelected(e.target.value)}
              value={selectedCountry}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "country-select",
              }}
            >
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.countryCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="warehouse-select">
              {I18n.get("label_warehouse")}
            </InputLabel>
            <Select
              onChange={(e) => warehouseSelected(e.target.value)}
              value={selectedWarehouse}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "warehouse-select",
              }}
            >
              {warehouses.map((warehouse, index) => (
                <MenuItem key={index} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {selectedWarehouse && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {I18n.get("label_new_banner")}
            </Typography>
          </Grid>
          <Grid item>
            <FormControl className={classes.gridItem}>
              <InputLabel shrink htmlFor="destination-select">
                {I18n.get("label_banner_destination")}
              </InputLabel>
              <Select
                onChange={(e) => setSelectedDestination(e.target.value)}
                value={selectedDestination}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "destination-select",
                }}
              >
                <MenuItem key="empty-des" value="">
                  ---
                </MenuItem>
                {destinationOptions.map((destination, index) => (
                  <MenuItem key={index} value={destination.value}>
                    {destination.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {isDiscountSelcted && (
            <Grid item>
              <FormControl className={classes.gridItem}>
                <InputLabel shrink htmlFor="discountType-select">
                  {I18n.get("label_discount_type")}
                </InputLabel>
                <Select
                  onChange={(e) => setSelectedDiscountType(e.target.value)}
                  value={selectedDiscountType}
                  className={classes.selectEmpty}
                  inputProps={{
                    id: "discountType-select",
                  }}
                >
                  {discountOptions.map((discountType, index) => (
                    <MenuItem key={index} value={discountType.value}>
                      {discountType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {isOccasionSelected && (
            <Grid item>
              <FormControl className={classes.gridItem}>
                <InputLabel shrink htmlFor="discountType-select">
                  {I18n.get("label_name")}
                </InputLabel>
                <Select
                  onChange={(e) => setSelectedOccasion(e.target.value)}
                  value={selectedOccasion}
                  className={classes.selectEmpty}
                  inputProps={{
                    id: "discountType-select",
                  }}
                >
                  {occasions.map((occasion, index) => (
                    <MenuItem key={index} value={occasion.createDate}>
                      {occasion.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {!isDiscountSelcted && !isOccasionSelected && (
            <Grid item>
              <FormControl className={classes.gridItem}>
                <TextField
                  label={I18n.get("label_banner_barameter")}
                  value={parameter}
                  onChange={(e) => setParameter(e.target.value)}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item>
            <FormControl className={classes.gridItem}>
              <Button variant="contained" onClick={addBanner} color="primary">
                {I18n.get("action_add")}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <List>
        {banners.map((banner, index) => (
          <Banner key={index} banner={banner} dispatch={dispatch} />
        ))}
      </List>
    </Paper>
  )
}

export default BannersPage
