import React, { useState, useContext } from "react"

import clsx from "clsx"
import { Decimal } from "decimal.js"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"

import { I18n, API, graphqlOperation } from "aws-amplify"

import GlobalContext from "../../context/global-context"
import ProductsToBeItemsContext from "../../context/productsToBeItems-context"
import { createItem } from "../../graphql/mutations"

const useStyles = makeStyles((theme) => ({
  input: {
    width: 100,
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  fullName: {
    width: 400,
  },
}))

const ItemSetupDialog = () => {
  const classes = useStyles()
  const { currentUser } = useContext(GlobalContext)
  const { isItemSetup, product, warehouse, dispatch } = useContext(
    ProductsToBeItemsContext
  )
  const [listPrice, setListPrice] = useState()
  const [vat, setVat] = useState(0)
  const [vatValue, setVatValue] = useState()
  const [netPrice, setNetPrice] = useState()
  const [hasError, setHasError] = useState(false)
  const [formErrors, setFormErrors] = useState([])

  const listPriceChanged = (newValue) => {
    if (newValue === "") {
      setListPrice(null)
    } else if (newValue.match(/^[0-9]*(\.|\,)$/)) {
      //If the string ends with '.' then the next digit after '.' is to be waited
      //If the user uses comma as decimal then replace it with '.'
      newValue = newValue.replace(",", ".")
      setListPrice(newValue)
    } else if (newValue.match(/^[0-9]*(\.|\,){0,1}[0-9]{1,}$/)) {
      newValue = newValue.replace(",", ".")
      setListPrice(newValue)
      calculateNewValues(newValue, vat)
    }
  }

  const vatChanged = (newValue) => {
    if (newValue === "") {
      setVat(0)
    }
    try {
      const newValueDecimal = new Decimal(newValue)
      const vatDecimal = newValueDecimal.dividedBy(100).toDP(2)
      setVat(vatDecimal)
      calculateNewValues(listPrice, vatDecimal)
    } catch (error) {
      //do nothing, just ignore user input
    }
  }

  const calculateNewValues = (newListPrice, newVat) => {
    if (newListPrice && newVat) {
      const vatDecimal = new Decimal(newVat)
      const listPriceDecimal = new Decimal(newListPrice)
      const netPriceDecimal = listPriceDecimal
        .dividedBy(vatDecimal.plus(1))
        .toDP(2)
      const vatValueDecimal = listPriceDecimal.minus(netPriceDecimal)

      setNetPrice(netPriceDecimal)
      setVatValue(vatValueDecimal)
    }
  }

  const handleClose = () => {
    dispatch({ type: "FINISH_ITEM_SETUP" })
  }

  const handleSave = () => {
    const errors = validateInput()
    if (errors.length > 0) {
      setHasError(true)
      setFormErrors(errors)
    } else {
      createItemInDB()
    }
  }

  const validateInput = () => {
    let errors = []
    if (vat === 0) {
      errors.push(I18n.get("error_vat_required"))
    }
    if (vat > 0.25) {
      errors.push(I18n.get("error_vat_2_big"))
    }
    if (!listPrice) {
      errors.push(I18n.get("error_list_price_required"))
    }
    return errors
  }

  async function createItemInDB() {
    const locales = product.locales

    let input = {
      userLocale: currentUser.locale,
      sku: product.sku,
      warehouseId: warehouse.id,
      locales,
      VATs: vat,
      VATsValue: vatValue,
      netPrice: netPrice,
      listPrice: listPrice,
    }
    try {
      await API.graphql(graphqlOperation(createItem, { input }))
      dispatch({ type: "FINISH_ITEM_SETUP", sku: product.sku })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog
      open={isItemSetup}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {I18n.get("action_item_setup")}
      </DialogTitle>
      <form>
        <DialogContent>
          {hasError && (
            <Box component="div" m={1}>
              {formErrors.map((error, index) => (
                <Typography key={index} variant="body2" color="error">
                  {" "}
                  {error}
                </Typography>
              ))}
            </Box>
          )}
          <TextField
            className={classes.input}
            autoFocus={false}
            label={I18n.get("label_sku")}
            value={product.sku}
            disabled={true}
          />
          <TextField
            className={clsx(classes.input, classes.fullName)}
            autoFocus={false}
            label={I18n.get("label_name")}
            value={product.name}
            disabled={true}
          />
          <Grid container>
            <Grid item>
              <TextField
                className={classes.input}
                label={I18n.get("label_vat")}
                autoFocus={true}
                value={vat > 0 ? Math.floor(vat * 100) : ""}
                onChange={(e) => vatChanged(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                label={I18n.get("label_list_price")}
                value={listPrice ? listPrice : ""}
                onChange={(e) => listPriceChanged(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <TextField
                className={classes.input}
                label={I18n.get("label_vat_value")}
                value={vatValue ? vatValue : ""}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                label={I18n.get("label_net_price")}
                value={netPrice ? netPrice : ""}
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {I18n.get("action_cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
            {I18n.get("action_save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ItemSetupDialog
