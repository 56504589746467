import React, {useContext} from 'react'

import {I18n} from 'aws-amplify'
import WarehousesContext from '../../context/warehouses-context';
import Warehouse from './Warehouse';

const WarehousesList = () => {
    const {warehouses} = useContext(WarehousesContext)

    return (
        <div>
            <h3>{I18n.get('label_warehouses')}</h3>
            <div className="list-header">
                <div  className="col-2-of-10">{I18n.get('label_name')}</div>
                <div  className="col-1-of-10">{I18n.get('label_country')}</div>
                <div  className="col-4-of-10">{I18n.get('label_address')}</div>
                <div  className="col-3-of-10">{I18n.get('label_manager')}</div>
            </div>
            { warehouses && warehouses.length > 0 ? (
                    <React.Fragment>
                        {warehouses.map((warehouse) => (
                            <Warehouse key={warehouse.id} warehouse={warehouse} />
                        ))}
                    </React.Fragment>
                ) : (
                    <div>{I18n.get('no_warehouses_yet')}</div>
                )
            }
        </div>
    )
}

export default WarehousesList