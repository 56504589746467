import React, { useEffect, useState } from "react"
import { API, graphqlOperation, I18n } from "aws-amplify"
import { getFriendCodeReminders } from "../../graphql/queries"
import { useYourFriendCode } from "../../graphql/mutations"
import { Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { getFormattedShortDate } from "../../utils/dateUtil"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"

const useStyles = makeStyles({
  container: {},
  tableWrapper: {
    height: 600,
    overflow: "auto",
    width: "70%",
  },
  row: {
    cursor: "pointer",
  },
})

const FriendCodeRemindersPage = (props) => {
  const classes = useStyles()
  const [reminders, setReminders] = useState([])
  let subscribed = true
  useEffect(() => {
    loadReminders()
    return () => {
      subscribed = false
    }
  }, [])

  const loadReminders = () => {
    const params = {}
    API.graphql(graphqlOperation(getFriendCodeReminders, params))
      .then((response) => {
        if (subscribed) {
          setReminders(response.data.getFriendCodeReminders)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const openDetails = (createDate) => {
    props.history.push(`/friendCodeReminderDetails/${createDate}`)
  }
  return (
    <Paper>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key="date" style={{ width: 200 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_create_date")}
                </Typography>
              </TableCell>
              <TableCell key="customers" style={{ width: 200 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_customers_count")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reminders.map((reminder) => (
              <TableRow
                className={classes.row}
                key={reminder.createDate}
                onClick={(e) => openDetails(reminder.createDate)}
              >
                <TableCell style={{ width: 200 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {getFormattedShortDate(reminder.createDate)}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 200 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {reminder.customers.length}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default FriendCodeRemindersPage
