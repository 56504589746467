import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'

import BrandsContext from '../../context/brands-context'
import Brand from './Brand'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 600,
    overflow: 'auto',
  },
});

const BrandsList = () => {
  const { brands } = useContext(BrandsContext)
  const classes = useStyles();
  return (

    <div className={classes.tableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              key='name'
              style={{ minWidth: 150 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_name')}
              </Typography>

            </TableCell>

            <TableCell
              key='translations'
              style={{ minWidth: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_translations')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {brands.map((brand) => (
            <Brand key={brand.id} brand={brand} />
          ))}
        </TableBody>
      </Table>
    </div>


  )

}

export default BrandsList