import React, { useContext, useState } from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';

import { API, graphqlOperation } from 'aws-amplify'

import GlobalContext from '../../context/global-context'
import CategoriesContext from '../../context/categories-context'
import Translation from '../helpers/Translation'
import { updateCategoryTranslation } from '../../graphql/mutations'
import TranslationEditDialog from '../helpers/TranslationEditDialog'
import List from '@material-ui/core/List';

const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: 0,
    },
    tableCell: {
        paddingTop: 0,
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16
    }
}));

const Category = ({ category }) => {
    const { currentUser } = useContext(GlobalContext)
    const { dispatch } = useContext(CategoriesContext)
    const [open, setOpen] = useState(false);
    const classes = useStyles()

    const handleRemoveTranslation = (locale) => {
        if (locale === currentUser.locale) {
            console.log('Cannot remove')
        }
    }

    async function translationEditHandler(locale, newTranslation) {
        const input = {
            sku: category.sku,
            userLocale: currentUser.locale,
            locale,
            name: newTranslation
        }
        try {
            let result = await API.graphql(graphqlOperation(updateCategoryTranslation, input))
            dispatch({ type: 'UPDATE_CATEGORY', category: result.data.updateCategoryTranslation })
        } catch (error) {
            console.log(error)
        }
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleCreateTranslation = (locale, translation) => {
        translationEditHandler(locale, translation)
        setOpen(false)
    }
    return (
        <React.Fragment>
            <TableRow hover className={classes.tableRow}>
                <TableCell className={classes.tableCell}
                    key='sku'
                    style={{ minWidth: 50 }}
                >
                    <Typography>
                        {category.sku}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='name'
                    style={{ minWidth: 120 }}
                >
                    <Typography>
                        {category.name}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='translations'
                    style={{ minWidth: 150 }}
                >
                    <List dense={true}>
                        {category.translations.map((translation) => (
                            <Translation
                                key={translation.locale}
                                translation={translation}
                                editHandler={translationEditHandler}
                                canRemove={translation.locale !== currentUser.locale}
                                removeHandler={() => (handleRemoveTranslation(translation.locale))}
                            />
                        ))}
                    </List>
                    <IconButton color="primary" onClick={handleOpen}>
                        <AddCircleOutlineIcon fontSize="small" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TranslationEditDialog
                open={open}
                onClose={handleClose}
                onSave={handleCreateTranslation}
            />
        </React.Fragment>
    )
}
export default Category