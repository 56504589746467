import React, { useState, useEffect, useReducer, useContext } from "react"
import { I18n } from "aws-amplify"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  listCountries,
  listWarehousesByCountry,
  listOccasions,
} from "../../graphql/queries"

import {
  FormControl,
  Select,
  InputLabel,
  Typography,
  MenuItem,
  List,
} from "@material-ui/core"
import GlobalContext from "../../context/global-context"

import occasionsReducer from "../../reducers/occasions"
import Occasion from "./Occasion"

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: 150,
  },
  grid: {
    padding: theme.spacing(5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const OccasionsPage = (props) => {
  const classes = useStyles()
  const { currentUser } = useContext(GlobalContext)
  const [{ occasions }, dispatch] = useReducer(occasionsReducer, {
    occasions: [],
  })
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedWarehouse, setSelectedWarehouse] = useState("")
  const [countries, setCountries] = useState([])
  const [warehouses, setWarehouses] = useState([])

  useEffect(() => {
    loadCountries()
  }, [])
  useEffect(() => {
    if (selectedCountry !== "") {
      loadWarehouses()
    }
  }, [selectedCountry])
  useEffect(() => {
    if (selectedWarehouse !== "") {
      loadOccasions()
    }
  }, [selectedWarehouse])

  const loadCountries = async () => {
    const params = {
      userLocale: currentUser.locale,
    }
    try {
      const countriesData = await API.graphql(
        graphqlOperation(listCountries, params)
      )
      setCountries(countriesData.data.listCountries.items)
    } catch (error) {
      console.log(error)
    }
  }

  const loadWarehouses = async () => {
    const params = {
      countryCode: selectedCountry,
    }
    try {
      const warehousesData = await API.graphql(
        graphqlOperation(listWarehousesByCountry, params)
      )
      setWarehouses(warehousesData.data.listWarehousesByCountry.items)
    } catch (error) {
      console.log(error)
    }
  }

  const loadOccasions = async () => {
    const params = {
      warehouseId: selectedWarehouse,
      userLocale: currentUser.locale,
    }

    const response = await API.graphql(graphqlOperation(listOccasions, params))
    const data = response.data.listOccasions
    dispatch({ type: "LOAD_OCCASIONS", occasions: data })
  }

  const countrySelected = async (countryCode) => {
    setSelectedCountry(countryCode)
  }

  const warehouseSelected = async (warehouseId) => {
    setSelectedWarehouse(warehouseId)
  }

  return (
    <Paper>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item>
          <Typography variant="h5" color="textPrimary">
            {I18n.get("label_warehouse")}
          </Typography>
        </Grid>
        <Grid item>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="country-select">
              {I18n.get("label_country")}
            </InputLabel>
            <Select
              onChange={(e) => countrySelected(e.target.value)}
              value={selectedCountry}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "country-select",
              }}
            >
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.countryCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="warehouse-select">
              {I18n.get("label_warehouse")}
            </InputLabel>
            <Select
              onChange={(e) => warehouseSelected(e.target.value)}
              value={selectedWarehouse}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "warehouse-select",
              }}
            >
              {warehouses.map((warehouse, index) => (
                <MenuItem key={index} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <List>
        {occasions.map((occasion, index) => (
          <Occasion
            key={index}
            occasion={occasion}
            dispatch={dispatch}
            history={props.history}
          />
        ))}
      </List>
    </Paper>
  )
}

export default OccasionsPage
