import React,{useContext} from 'react'

import EmployeesContext from '../../context/employees-context'

const EditEmployeeForm = () => {

    const {employeeId, dispatch} = useContext(EmployeesContext)

    const finishEditing = () => {
        dispatch({type:'FINISH_EDITING'})
    }

    const submitEmployee = (e) => {
        e.preventDefault()
        finishEditing()
    }
    return (
        <form onSubmit={submitEmployee}>
            <div>Editing employee: {employeeId}</div>
            <button>Save</button>
        </form>
    )
}

export default EditEmployeeForm