import React from 'react'
import { I18n } from 'aws-amplify'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'
import ChatIcon from '@material-ui/icons/Chat';

const Ticket = ({ ticket, assignTicketHandler, openTicketHandler }) => {
    const assignTicket = () => {
        assignTicketHandler(ticket)
    }

    const openTicket = () => {
        openTicketHandler(ticket)
    }

    const getLanguageName = () => {
        const languageCode = `language_${ticket.customerLocale}`
        return I18n.get(languageCode)
    }

    return (
        <TableRow >
            <TableCell
                style={{ width: 200 }}
            >
                <Typography variant="body2">
                    {ticket.createDate}
                </Typography>
            </TableCell>
            <TableCell
                style={{ width: 100 }}
            >
                <Typography variant="body2">
                    {getLanguageName()}
                </Typography>
            </TableCell>
            <TableCell
                style={{ width: 200 }}
            >
                <Typography variant="body2">
                    {ticket.customerNumber}
                </Typography>
            </TableCell>
            <TableCell
                key='employeeName'
                style={{ width: 200 }}
            >
                <Typography variant="subtitle1" color='textPrimary'>
                    {ticket.employeeName}
                </Typography>
            </TableCell>
            <TableCell
                style={{ width: 100 }}
            >
                <IconButton color="primary" onClick={ticket.ticketStatus === 'NEW' ? assignTicket : openTicket}>
                    <ChatIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default Ticket