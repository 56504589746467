import React,{useContext} from 'react';

import {I18n} from 'aws-amplify'

import CountriesContext from '../../context/countries-context';
import Country from './Country';

const CountriesList = () => {

    const {countries} = useContext(CountriesContext)
    return (
        <React.Fragment>
            <h3>{I18n.get('label_countries')}</h3>
            <div className="list-header">
                <div  className="col-1-of-10">{I18n.get('label_name')}</div>
                <div className="col-2-of-10">{I18n.get('label_translations')}</div>
                <div className="col-1-of-10">{I18n.get('label_vats')}</div>
                <div className="col-2-of-10">{I18n.get('label_shipment_costs')}</div>
                <div className="col-2-of-10">{I18n.get('label_payment_types')}</div>
                <div className="col-2-of-10">{I18n.get('label_manager')}</div>
            </div>
            { countries && countries.length > 0 ? (
                    <React.Fragment>
                        {countries.map((country) => (
                            <Country key={country.countryCode} country={country} />
                        ))}
                    </React.Fragment>
                ) : (
                    <div>{I18n.get('no_countries_yet')}</div>
                )
            }
        </React.Fragment>
        

    )
    
    
}
export { CountriesList as default}