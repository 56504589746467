const ticketMessagesReducer = (state, action) => {
    switch(action.type){
        case 'SET_CUSTOMER':
            return {...state, customer: action.customer}
        case 'LOAD_MMESSAGES':
            return {...state, messages: action.messages}
        case 'ADD_NEW_MESSAGE':
            return {...state, messages: [...state.messages, action.message]}
        default:
            return state
    }
}
export default ticketMessagesReducer