import React, { useState, useContext, useEffect} from 'react';
import Select from 'react-select'

import API, { graphqlOperation } from '@aws-amplify/api'
import {I18n} from 'aws-amplify'

import Aladdin from '../../aladdin-config'
import {createCountry} from '../../graphql/mutations'
import {listEmployees} from '../../graphql/queries'
import CountriesContext from '../../context/countries-context';
import GlobalContext from '../../context/global-context'


const aladdin = Aladdin()


const CountryForm = () => {
    const [selectedCountryOption, setSelectedCountryOption] = useState({label: null, value: null})
    const [selectedManagerOption, setSelectedManagerOption] = useState({label: null, value: null})
    const [nameTranslations, setNameTranslations] = useState([])
    const [VATs, setVATs] = useState([])
    const [selectedPaymentTypeOptions, setSelectedPaymentTypeOptions] = useState([])
    const [shipmentCosts, setShipmentCosts] = useState([])
    const [managersOptions, setManagersOptions] = useState([])
    const [formErrors, setFormErrors] = useState([])
    const [hasError, setHasError] = useState(false)
    const [created, setCreated] = useState(false)
    

    const {dispatch} = useContext(CountriesContext)
    const {currentUser} = useContext(GlobalContext)

    let paymentTypesOptions = []
    aladdin.payments.map((payment)=>{
        const label = I18n.get(payment.code)
        const value = payment.value
        paymentTypesOptions.push({label, value})
    })
    let countriesOptions = []
    aladdin.countries.map((country)=> {
        const label = I18n.get(country.code)
        const value = country.countryCode
        countriesOptions.push({label, value})
    })
    let languagesOptions = []
    aladdin.languages.map((language) => {
        const label = I18n.get(language.code)
        const value = language.locale
        languagesOptions.push({label, value})
    })
    

    useEffect(()=>{
        loadManagers()
    }, [selectedCountryOption])
    async function loadManagers() {
        if(selectedCountryOption){
            let tempManagersOptions = []
        const queryParams = {
            limit: 10,
            filter:{
                //When the company has branches in more countries, this filter should be uncommented
                //countryCode:{eq:selectedCountryOption.value}, 
                groups:{contains:"CM"}
            }
        }
        const employeesData = await API.graphql(graphqlOperation(listEmployees, queryParams))
       
        const employees = employeesData.data.listEmployees.items
        employees.map((employee)=>{
            tempManagersOptions.push({label: employee.employeeName, value: employee.username})
        })
        
        setManagersOptions(tempManagersOptions)
        setSelectedManagerOption({label: null, value: null})
        }
        
    }

    const submitCountry = (e) => {
        e.preventDefault()
        const inputsErrors = validateInputs()
        if(inputsErrors.length === 0){
            createCountryInDB()
        }else{
            setFormErrors(inputsErrors)
            setHasError(true)
        }

    }

    async function createCountryInDB(){
        let locales = []
        let names = []
        nameTranslations.map((nameTranslation) => {
            locales.push(nameTranslation.locale)
            names.push(nameTranslation.value)
        })
        let paymentTypes = selectedPaymentTypeOptions.map(selectedPaymentType => (selectedPaymentType.value))
        const input = {
            userLocale: currentUser.locale,
            countryCode: selectedCountryOption.value,
            locales,
            names,
            VATs,
            managerId: selectedManagerOption.value,
            managerName: selectedManagerOption.label,
            paymentType: paymentTypes,
            shipmentCosts
        }
        return API.graphql(graphqlOperation(createCountry, {input}))
        .then((data) => {
            const createdCountry = data.data.createCountry
            setCreated(true)
            dispatch({
                type: 'CREATE_COUNTRY',
                country: createdCountry
            })
        })
        .catch((data) => {
            const errors = data.errors
            errors.map((error) => {
                if(error.errorType === "DynamoDB:ConditionalCheckFailedException"){
                    setFormErrors([...formErrors, I18n.get('error_country_exists')])
                    setHasError(true)
                }else{
                    let path =  ''
                    if(error.path){
                        path = `${error.path[0]}, `
                    }
                    const message = error.message
                    const errorString = `${path}${message}`
                    setFormErrors([...formErrors, errorString])
                    setHasError(true)
                }
            })
        })
        
    }

    const validateInputs = () => {
        let errors = []
        if(selectedCountryOption.value === null){
            errors = [...errors, I18n.get('error_country_required')]
        }
        if(selectedManagerOption.value === null){
            errors = [...errors, I18n.get('error_manager_required')]
        }
        if(selectedPaymentTypeOptions.length === 0){
            errors = [...errors, I18n.get('error_payment_type_required')]
        }
        if(nameTranslations.length === 0){
            errors = [...errors, I18n.get('error_name_required')]
        }else{
            nameTranslations.map((nameTranslation) => {
                if(nameTranslation.locale === ''){
                    errors = [...errors, I18n.get('error_select_language')]
                }else if(nameTranslation.value === ''){
                    const languageCode = 'language_' + nameTranslation.locale
                    const errorMessage = I18n.get('error_translation_for_language_required').concat(': ', I18n.get(languageCode))
                    errors = [...errors, errorMessage]
                }
            })
        }
        if(VATs.length === 0){
            errors = [...errors, I18n.get('error_vat_required')]
        }
        if(shipmentCosts.length === 0){
            errors = [...errors, I18n.get('error_shipment_cost_required')]
        }

        return errors
    }

    const clearForm = () => {
        setSelectedCountryOption({label: null, value: null})
        setSelectedManagerOption({label: null, value: null})
        setSelectedPaymentTypeOptions([])
        setNameTranslations([])
        setVATs([])
        setSelectedPaymentTypeOptions([])
        setFormErrors([])
        setHasError(false)
        setCreated(false)
        setShipmentCosts([])
        setManagersOptions([])
    }

    const handlePaymentTypeChanged = (selectedOptions) =>{
        /*
        let selectedPayments = []
        if(selectedOptions){
            selectedOptions.map((selectedOption) => {
                selectedPayments.push(selectedOption.value)
            })
        }   
        
        setPaymentTypes(selectedPayments)
        */
       setSelectedPaymentTypeOptions(selectedOptions)
        
    }
    const addTranslationControlls = () => {
        setNameTranslations([...nameTranslations, {locale:'', value:''}])
    }

    const addTranslationLocale = (selectedIndex, selectedLocale) => {
        let tempTranslations = []
        nameTranslations.map((translation, index) => {
            let locale = translation.locale
            const value = translation.value
            if(index === selectedIndex){
                locale = selectedLocale
            }
            tempTranslations.push({locale, value})
        })
        setNameTranslations(tempTranslations)
        
    }

    const addTranslationValue = (selectedIndex, newValue) => {
        let tempTranslations = []
        nameTranslations.map((translation, index) => {
            const locale = translation.locale
            let value = translation.value
            if(index === selectedIndex){
                value = newValue
            }
            tempTranslations.push({locale, value})
        })
        setNameTranslations(tempTranslations)
    }

    const removeTranslation = (selectedIndex) =>{
         //= []
         let tempTranslations = nameTranslations.filter((translation, index) => {
            if(index != selectedIndex){
                return translation
            }
        })
        setNameTranslations(tempTranslations)
    }

    const addVATControll = () => {
        setVATs([...VATs, null])
    }

    const addVATValue = (selectedIndex, newValue) => {
        let templVATs = []
        VATs.map((vat, index) => {
            let vatValue = vat
            if(index === selectedIndex){
                vatValue = Number.parseFloat(newValue)/100
            }
            templVATs.push(vatValue)
        })
        setVATs(templVATs)
        
    }

    const removeVAT = (selectedIndex) => {
        let tempVATs = VATs.filter((vat, index) => {
            if(index != selectedIndex){
                return vat
            }
        })
        setVATs(tempVATs)
    }

    const addShipmentCostControl = () => {
        setShipmentCosts([...shipmentCosts, {threshold: null, cost: null}])
    }

    const addShipmentThreshol = (selectedIndex, newThreshol) => {
        const endsWithDotOrComma = newThreshol.match(/^[0-9]*(\.|\,)$/)
        let tempCosts = []
        shipmentCosts.map((shipmentCost, index) => {
            let threshold = shipmentCost.threshold
            const cost = shipmentCost.cost
            if(index === selectedIndex){
                if(newThreshol === ''){
                    threshold = null
                }else if(endsWithDotOrComma){
                    //If the string ends with '.' then it should not be parsed to Float and the next digit after '.' is to be waited
                    //If the user uses comma as decimal then replace it with '.'
                    threshold = newThreshol.replace(',', '.')
                }else{
                    threshold = Number.parseFloat(newThreshol)
                }
            }
            tempCosts.push({threshold, cost})
        })
        setShipmentCosts(tempCosts)
    }
    const addShipmentCost = (selectedIndex, newCost) => {
        const endsWithDotOrComma = newCost.match(/^[0-9]*(\.|\,)$/)
        let tempCosts = []
        shipmentCosts.map((shipmentCost, index) => {
            const threshold = shipmentCost.threshold
            let cost = shipmentCost.cost
            if(index === selectedIndex){
                if(newCost === ''){
                    cost = null
                }else if(endsWithDotOrComma){
                    //If the string ends with '.' then it should not be parsed to Float and the next digit after '.' is to be waited
                    //If the user uses comma as decimal then replace it with '.'
                    cost = newCost.replace(',', '.')
                }else{
                    cost = Number.parseFloat(newCost)
                }
                
            }
            tempCosts.push({threshold, cost})
        })
        setShipmentCosts(tempCosts)
    }
    const removeShipmentCost = (selectedIndex) => {
        let tempCosts = shipmentCosts.filter((shipmentCost, index) => {
            if(index != selectedIndex){
                return shipmentCost
            }
        })
        setShipmentCosts(tempCosts)
    }

    useEffect(()=>{
        if(created){
            clearForm()
        }
    },[created])

    return (
        <React.Fragment>  
            {hasError && <div className="error-row">
                    {formErrors.map((error, index)=>(
                        <div key={index}> {error}</div>
                    ))
                    }
                </div>
            }
            
            <h3>{I18n.get('create_country')}</h3>
            <form onSubmit={submitCountry}>
                <div className="input-row">
                    <div className="input-group">
                        <label>{I18n.get('label_country')}</label>
                        <div className="single-select">
                            <Select
                                value={selectedCountryOption}
                                onChange={(selectionOption) => setSelectedCountryOption(selectionOption)}
                                options={countriesOptions}
                            />
                        </div>
                    </div>
                    <span className="input-space--long"></span>
                    <div className="input-group">
                        <label>{I18n.get('label_manager')}</label>
                        <div className="single-select">
                        <Select
                            value={selectedManagerOption}
                            onChange={(selectedOption) => {setSelectedManagerOption(selectedOption)}}
                            options={managersOptions}
                        />
                        </div>
                    </div>
                </div>
                <div className="input-row">
                <div className="input-group">
                    <label>{I18n.get('label_payment_types')}</label>
                    <div className="multi-select">
                        <Select
                            isMulti={true}
                            value={selectedPaymentTypeOptions}
                            onChange={(selectedOptions) => (handlePaymentTypeChanged(selectedOptions))}
                            options={paymentTypesOptions}
                        />
                    </div>
                </div>
                </div>
                <div className="input-row">
                    <label className="bottom-border">{I18n.get('label_name')}
                        <span className="input-space--long"/>
                        <ion-icon name="add-circle-outline" onClick={addTranslationControlls}/>
                        <span className="input-space"/>
                        <label >{I18n.get('action_new_translation')}</label>
                    </label>
                </div>
                {nameTranslations.map((translation, index) => (
                    <div key={index} className="input-row">
                        <div className="input-group">
                            <label>{I18n.get('label_language')}</label>
                            <div className="single-select">
                                <Select
                                    onChange={(selectionOption) => addTranslationLocale(index, selectionOption.value)}
                                    options={languagesOptions}
                                />
                            </div>
                        </div>
                        <span className="input-space--long"/>
                        <div className="input-group">
                            <label>{I18n.get('label_translation')}</label>
                            <input className="text-input--long" value={translation.value} onChange={(e)=> {addTranslationValue(index, e.target.value)}}></input>
                        </div>
                        <span className="input-space--long"/>
                        <div className="icon">
                            <ion-icon name="trash" onClick={() => {removeTranslation(index)}}/>
                        </div>
                    </div>
                ))}
                
                <div className="input-row">
                    <label className="bottom-border">{I18n.get('label_vats')}
                        <span className="input-space--long"/>
                        <ion-icon name="add-circle-outline" onClick={addVATControll}/>
                        <span className="input-space"/>
                        <label >{I18n.get('action_new_vat')}</label>
                    </label>
                </div>
                <div className="input-row">
                    {VATs.map((vat, index) => (
                        <div key={index}>
                            <input className="text-input--small" value={vat ? Math.floor(vat*100) : ''} onChange={(e) => {addVATValue(index, e.target.value)}}></input>%
                            <span className="input-space"/>
                            <ion-icon name="trash" onClick={() => {removeVAT(index)}}/>
                            <span className="input-space--long"/>
                        </div>
                    ))}
                </div>
                <div className="input-row">
                    <label className="bottom-border">{I18n.get('label_shipment_costs')}
                        <span className="input-space--long"/>
                        <ion-icon name="add-circle-outline" onClick={addShipmentCostControl}/>
                        <span className="input-space"/>
                        <label >{I18n.get('action_new_shipment_cost')}</label>
                    </label>
                </div>
                {shipmentCosts.map((shipmentCost, index) => (
                    <div key={index} className="input-row">
                        <div className="input-group">
                            <label>{I18n.get('label_shipment_threshold')}</label>
                            <input className="text-input--small" value={shipmentCost.threshold != null ? shipmentCost.threshold : ''} onChange={(e) => {addShipmentThreshol(index, e.target.value)}}></input>
                        </div>
                        <span className="input-space--long">:</span>
                        <div className="input-group">
                            <label>{I18n.get('label_shipment_cost')}</label>
                            <input className="text-input--small" value={shipmentCost.cost != null ? shipmentCost.cost : ''} onChange={(e) => {addShipmentCost(index, e.target.value)}}></input>
                        </div>
                        <span className="input-space"/>
                        <div className="icon">
                            <ion-icon name="trash" onClick={() => {removeShipmentCost(index)}}/>
                        </div>
                    </div>
                ))}
                
                <div className="input-row">
                    <button className="form-button">{I18n.get('action_save')}</button>
                </div>
            </form>
        </React.Fragment>
    )
}

export { CountryForm as default }