import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import { I18n } from "aws-amplify"
import { API, graphqlOperation } from "aws-amplify"
import { getDiscountsOfItem } from "../../graphql/queries"
import GlobalContext from "../../context/global-context"
import { getFormattedDateTime } from "../../utils/dateUtil"
import SetDiscountDialog from "./SetDiscountDialog"
import { createDiscount } from "../../graphql/mutations"

const MangeItemDiscounts = ({ item, onClose, dispatch }) => {
  const { currentUser, warehouse } = useContext(GlobalContext)
  const [discounts, setDiscounts] = useState([])
  const [allowCreate, setAllowCreate] = useState(false)
  const [createDiscountDialogOpen, setCreateDiscountOpen] = useState(false)
  useEffect(() => {
    loadDiscounts()
    return () => {
      clear()
    }
  }, [])

  const loadDiscounts = async () => {
    const params = {
      countryCode: warehouse.countryCode,
      warehouseId: warehouse.id,
      sku: item.sku,
    }
    const response = await API.graphql(
      graphqlOperation(getDiscountsOfItem, params)
    )
    const result = response.data.getDiscountsOfItem
    if (result) {
      setDiscounts(result)
      let activeOrPlannedFound = false
      const now = Date.now()
      for (let discount of result) {
        const startDate = new Date(discount.startDate)
        if (discount.isActive === true || now < startDate) {
          activeOrPlannedFound = true
          break
        }
      }
      if (!activeOrPlannedFound) setAllowCreate(true)
    }
  }

  const newDiscount = () => {
    setCreateDiscountOpen(true)
  }

  const saveDiscountHandler = async (
    startDate,
    endDate,
    discountType,
    locales,
    finalPrice,
    discount
  ) => {
    const args = {
      sku: item.sku,
      countryCode: warehouse.countryCode,
      warehouseId: warehouse.id,
      discount: parseFloat((discount * 100).toFixed(2)),
      finalPrice,
      locales,
      startDate,
      endDate,
      discountType,
      employeeId: currentUser.username,
      employeeName: currentUser.employeeName,
    }
    try {
      const response = await API.graphql(graphqlOperation(createDiscount, args))
      const createdDiscount = response.data.createDiscount
      if (createdDiscount) {
        let modifiedItem = {
          ...item,
          discountType: createdDiscount.discountType,
          discount: createdDiscount.discount,
          finalPrice: createdDiscount.finalListPrice,
          finalNetPrice: createdDiscount.finalNetPrice,
        }

        dispatch({ type: "UPDATE_ITEM", item: modifiedItem })
        setDiscounts([...discounts, createdDiscount])
        setAllowCreate(false)
      } else {
        console.log("discount could not be created")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const clear = () => {
    setDiscounts([])
    setAllowCreate(false)
  }

  const closeManageDiscounts = () => {
    onClose()
  }

  const closeCreateDiscountHandler = () => {
    setCreateDiscountOpen(false)
  }
  const formatPrecentage = (value) => {
    const v = value * 100
    return `${Math.floor(v)} %`
  }
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item>{item.sku}</Grid>
        <Grid item>{item.name}</Grid>
        <Grid item>
          {I18n.get("label_final_cost")}: {item.cost}
        </Grid>
        <Grid item>
          {I18n.get("action_add_discount")}
          <IconButton
            color="primary"
            onClick={newDiscount}
            disabled={allowCreate === false}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={closeManageDiscounts}
      >
        {I18n.get("action_close")}
      </Button>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{I18n.get("label_start_date")}</TableCell>
              <TableCell>{I18n.get("label_end_date")}</TableCell>
              <TableCell>{I18n.get("label_isActive")}</TableCell>
              <TableCell>{I18n.get("label_list_price")}</TableCell>
              <TableCell>{I18n.get("label_net_price")}</TableCell>
              <TableCell>{I18n.get("label_discount")}</TableCell>
              <TableCell>{I18n.get("label_discount_type")}</TableCell>
              <TableCell>{I18n.get("label_final_price")}</TableCell>
              <TableCell>{I18n.get("label_final_net_price")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discounts.map((discount, index) => (
              <TableRow key={index}>
                <TableCell>
                  {getFormattedDateTime(discount.startDate)}
                </TableCell>
                <TableCell>
                  {discount.endDate
                    ? getFormattedDateTime(discount.endDate)
                    : ""}
                </TableCell>
                <TableCell>
                  {discount.isActive
                    ? I18n.get("label_yes")
                    : I18n.get("label_no")}
                </TableCell>
                <TableCell>{discount.listPrice}</TableCell>
                <TableCell>{discount.netPrice}</TableCell>
                <TableCell>{formatPrecentage(discount.discount)}</TableCell>
                <TableCell>
                  {I18n.get(`discount_type_${discount.discountType}`)}
                </TableCell>
                <TableCell>{discount.finalListPrice}</TableCell>
                <TableCell>{discount.finalNetPrice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SetDiscountDialog
        open={createDiscountDialogOpen}
        saveHandler={saveDiscountHandler}
        item={item}
        closeHandler={closeCreateDiscountHandler}
      />
    </Paper>
  )
}

export default MangeItemDiscounts
