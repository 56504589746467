import React, { useContext, useState, useEffect } from 'react'
import Select from 'react-select'

import { I18n } from 'aws-amplify'
import API, { graphqlOperation } from '@aws-amplify/api'
import { listEmployees } from '../../graphql/queries'
import { createWarehouse } from '../../graphql/mutations'

import GlobalContext from '../../context/global-context'
import WarehousesContext from '../../context/warehouses-context';

const CreateWarehouseForm = () => {
    const { dispatch } = useContext(WarehousesContext)
    const { currentUser } = useContext(GlobalContext)
    const countryCode = currentUser.countryCode

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [street, setStreet] = useState('')
    const [houseNr, setHouseNr] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [managersOptions, setManagersOptions] = useState([])
    const [selectedManagerOption, setSelectedManagerOption] = useState({ label: null, value: null })
    const [formErrors, setFormErrors] = useState([])
    const [hasError, setHasError] = useState(false)
    const [created, setCreated] = useState(false)

    useEffect(() => {
        let isSubsribed = true
        if (isSubsribed) {
            loadManagers()
        }
        return () => {
            isSubsribed = false
        }
    }, [])

    const loadManagers = () => {
        loadManagersFromDB()
    }

    async function loadManagersFromDB() {
        let tempManagersOptions = []
        const queryParams = {
            limit: 10,
            filter: {
                groups: { contains: "WM" },
                countryCode: { eq: `${countryCode}` }

            }
        }
        const employeesData = await API.graphql(graphqlOperation(listEmployees, queryParams))
        const employees = employeesData.data.listEmployees.items
        employees.map((employee) => {
            tempManagersOptions.push({ label: employee.employeeName, value: employee.username })
        })

        setManagersOptions(tempManagersOptions)
        setSelectedManagerOption({ label: null, value: null })
    }

    useEffect(() => {
        if (countryCode && zipCode && city && street && houseNr) {
            const fullAddress = `${countryCode.toUpperCase()}#${zipCode} ${city}#${street} ${houseNr}`
            setAddress(fullAddress)
        }

    }, [street, houseNr, zipCode, city])

    async function createWarehouseInDB() {
        const input = {
            countryCode: countryCode,
            name,
            address,
            managerId: selectedManagerOption.value,
            managerName: selectedManagerOption.label
        }
        try{
        let data = await API.graphql(graphqlOperation(createWarehouse, { input }))
        const createdWarehouse = data.data.createWarehouse
                setCreated(true)
                dispatch({
                    type: 'CREATE_WAREHOUSE',
                    warehouse: createdWarehouse
                })
            
            }catch(data) {
                console.log(data)
                const errors = data.errors
                errors.map((error) => {
                    if (error.errorType === "DynamoDB:ConditionalCheckFailedException") {
                        setFormErrors([...formErrors, I18n.get('error_warehouse_exists')])
                        setHasError(true)
                    } else {
                        let path = ''
                        if (error.path) {
                            path = `${error.path[0]}, `
                        }
                        const message = error.message
                        const errorString = `${path}${message}`
                        setFormErrors([...formErrors, errorString])
                        setHasError(true)
                    }
                })
            }
    }

    const submitWarehouse = (e) => {
        e.preventDefault()
        const inputsErrors = validateInputs()
        if (inputsErrors.length === 0) {
            createWarehouseInDB()
        } else {
            setFormErrors(inputsErrors)
            setHasError(true)
        }

    }
    const validateInputs = () => {
        let errors = []
        if (selectedManagerOption.value === null) {
            errors = [...errors, I18n.get('error_manager_required')]
        }
        if (street === '') {
            errors = [...errors, I18n.get('error_street_required')]
        }
        if (houseNr === '') {
            errors = [...errors, I18n.get('error_houseNr_required')]
        }
        if (zipCode === '') {
            errors = [...errors, I18n.get('error_zip_required')]
        }
        if (city === '') {
            errors = [...errors, I18n.get('error_city_required')]
        }
        if (name === '') {
            errors = [...errors, I18n.get('error_name_required')]
        }

        return errors
    }

    const clearForm = () => {
        setSelectedManagerOption({ label: null, value: null })
        setName('')
        setAddress('')
        setStreet('')
        setHouseNr('')
        setZipCode('')
        setCity('')
        setFormErrors([])
        setHasError(false)
    }
    useEffect(() => {
        if (created) {
            clearForm()
        }
    }, [created])

    return (
        <React.Fragment>
            {hasError && <div className="error-row">
                {formErrors.map((error, index) => (
                    <div key={index}> {error}</div>
                ))
                }
            </div>
            }
            <h3>{I18n.get('create_warehouse')}</h3>
            <form onSubmit={submitWarehouse}>
                <div className="input-row">
                    <div className="input-group">
                        <input className="text-input--long" value={street} placeholder={I18n.get('label_street')} onChange={(e) => { setStreet(e.target.value) }} />
                    </div>
                    <span className="input-space"></span>
                    <div className="input-group">
                        <input className="text-input--small" value={houseNr} placeholder={I18n.get('label_house_nr')} onChange={(e) => { setHouseNr(e.target.value) }} />
                    </div>
                    <span className="input-space--long"></span>
                    <div className="input-group">
                        <input className="text-input--small" value={zipCode} placeholder={I18n.get('label_zip_code')} onChange={(e) => { setZipCode(e.target.value) }} />
                    </div>
                    <span className="input-space"></span>
                    <div className="input-group">
                        <input className="text-input--long" value={city} placeholder={I18n.get('label_city')} onChange={(e) => { setCity(e.target.value) }} />
                    </div>
                </div>
                <div className="input-row">
                    <div className="input-group">
                        <input className="text-input--long" value={name} placeholder={I18n.get('label_name')} onChange={(e) => { setName(e.target.value) }} />
                    </div>
                </div>
                <div className="input-row">
                    <div className="input-group">
                        <label>{I18n.get('label_manager')}</label>
                        <div className="single-select">
                            <Select
                                value={selectedManagerOption}
                                onChange={(selectedOption) => { setSelectedManagerOption(selectedOption) }}
                                options={managersOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-row">
                    <button className="form-button">{I18n.get('action_save')}</button>
                </div>
            </form>
        </React.Fragment>
    )

}

export default CreateWarehouseForm