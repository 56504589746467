const countriesReducer = (state, action) => {
    switch(action.type){
        case 'CREATE_COUNTRY':
            return {...state, countries: [...state.countries, action.country]}
        case 'LOAD_COUNTRIES':
            return {...state, countries: [...state.countries, ...action.countries]}
        case 'CLEAR':
            return {}
        default:
            return state
    }
}

export { countriesReducer as default}