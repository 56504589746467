import React, { useState } from "react"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import { I18n } from "aws-amplify"
import { TextField } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  dateGridItem: {
    width: 75,
  },
  statusGridItem: {
    width: 150,
  },
  grid: {
    padding: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const OrdersFilterForm = ({ filterOrders }) => {
  const classes = useStyles()

  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [paymentMethod, setPaymentMethod] = useState("")
  const [paymentStatus, setPaymentStatus] = useState("")
  const [orderStatus, setOrderStatus] = useState("PLACED")

  const countryCode = "de"
  const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"

  let paymentMethods = []
  paymentMethods.push({ label: "SOFORT", value: "SOFORT" })
  paymentMethods.push({ label: "CREDIT", value: "CREDIT" })
  paymentMethods.push({ label: "PAYPAL", value: "PAYPAL" })
  paymentMethods.push({
    label: `${I18n.get("order_payment_type_ALADDIN_INVOICE")}`,
    value: "ALADDIN_INVOICE",
  })
  paymentMethods.push({
    label: `${I18n.get("order_payment_type_KLARNA_INVOICE")}`,
    value: "KLARNA_INVOICE",
  })
  paymentMethods.push({
    label: `${I18n.get("order_payment_type_ALADDIN_IN_ADVANCE")}`,
    value: "ALADDIN_IN_ADVANCE",
  })

  let paymentStatuses = []
  paymentStatuses.push({
    label: `${I18n.get("order_aladdin_payment_status_IN_TIME")}`,
    value: "IN_TIME",
  })
  paymentStatuses.push({
    label: `${I18n.get("order_aladdin_payment_status_LATE")}`,
    value: "LATE",
  })
  paymentStatuses.push({
    label: `${I18n.get("order_aladdin_payment_status_PENDING")}`,
    value: "PENDING",
  })
  paymentStatuses.push({
    label: `${I18n.get("order_aladdin_payment_status_ERROR_SEND_EMAIL")}`,
    value: "ERROR_SEND_INVOICE",
  })

  let orderStatuses = []
  orderStatuses.push({
    label: `${I18n.get("order_status_PLACED")}`,
    value: "PLACED",
  })
  orderStatuses.push({
    label: `${I18n.get("order_status_SHIPPED")}`,
    value: "SHIPPED",
  })
  orderStatuses.push({
    label: `${I18n.get("order_status_BEING_COLLECTED")}`,
    value: "BEING_COLLECTED",
  })
  orderStatuses.push({
    label: `${I18n.get("order_status_COLLECTED")}`,
    value: "COLLECTED",
  })
  orderStatuses.push({
    label: `${I18n.get("order_status_BEING_SHIPPED")}`,
    value: "BEING_SHIPPED",
  })
  orderStatuses.push({
    label: `${I18n.get("order_status_CANCELLED")}`,
    value: "CANCELLED",
  })

  const doFilter = () => {
    const pm = paymentMethod === "" ? null : paymentMethod
    const ps = paymentStatus === "" ? null : paymentStatus
    const os = orderStatus === "" ? null : orderStatus
    filterOrders(countryCode, warehouseId, pm, ps, os, fromDate, toDate)
  }

  const fromDateChanged = (e) => {
    setFromDate(e.target.value)
  }

  const toDateChanged = (e) => {
    setToDate(e.target.value)
  }

  return (
    <React.Fragment>
      <Grid className={classes.grid} container spacing={3}>
        <Grid item>
          <Typography className={classes.dateGridItem} color="textPrimary">
            {I18n.get("label_from")}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            id="start_date"
            label={I18n.get("label_from")}
            type="date"
            className={classes.textField}
            onChange={fromDateChanged}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item>
          <Typography className={classes.dateGridItem} color="textPrimary">
            {I18n.get("label_to")}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            id="start_date"
            label={I18n.get("label_to")}
            type="date"
            className={classes.textField}
            onChange={toDateChanged}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid className={classes.grid} container spacing={3}>
        <Grid item>
          <FormControl className={classes.statusGridItem}>
            <InputLabel shrink htmlFor="paymentMethod-select">
              {I18n.get("label_payment_type")}
            </InputLabel>
            <Select
              onChange={(e) => setPaymentMethod(e.target.value)}
              value={paymentMethod}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "paymentMethod-select",
              }}
            >
              <MenuItem value="">--</MenuItem>
              {paymentMethods.map((paymentMethod, index) => (
                <MenuItem key={index} value={paymentMethod.value}>
                  {paymentMethod.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {paymentMethod === "ALADDIN_INVOICE" && (
          <Grid item>
            <FormControl className={classes.statusGridItem}>
              <InputLabel shrink htmlFor="paymentStatus-select">
                {I18n.get("label_payment_status")}
              </InputLabel>
              <Select
                onChange={(e) => setPaymentStatus(e.target.value)}
                value={paymentStatus}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "paymentStatus-select",
                }}
              >
                <MenuItem value="">--</MenuItem>
                {paymentStatuses.map((paymentStatus, index) => (
                  <MenuItem key={index} value={paymentStatus.value}>
                    {paymentStatus.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid className={classes.grid} container spacing={3}>
        <Grid item>
          <FormControl className={classes.statusGridItem}>
            <InputLabel shrink htmlFor="orderStatu-select">
              {I18n.get("label_status")}
            </InputLabel>
            <Select
              onChange={(e) => setOrderStatus(e.target.value)}
              value={orderStatus}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "orderStatu-select",
              }}
            >
              {orderStatuses.map((orderStatus, index) => (
                <MenuItem key={index} value={orderStatus.value}>
                  {orderStatus.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button variant="contained" onClick={doFilter} color="primary">
        {I18n.get("action_search")}
      </Button>
    </React.Fragment>
  )
}

export default OrdersFilterForm
