import React, { useReducer, useContext, useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';

import { I18n } from 'aws-amplify'
import { API, graphqlOperation } from 'aws-amplify'

import GlobalContext from '../../context/global-context'
import productsToBeItems from '../../reducers/productsToBeItems'
import ProductsToBeItemsContext from '../../context/productsToBeItems-context'
import {
    listProductsToBeItems,
    listSections,
    listCategories,
    listCountries,
    listWarehousesByCountry,
    getWarehouseByManager
} from '../../graphql/queries'
import ProductsToBeItemsList from './ProductsToBeItemsList'
import ItemSetupDialog from './ItemSetupDialog'


const useStyles = makeStyles(theme => ({
    gridItem: {
        width: 150

    },
    grid: {
        padding: theme.spacing(5)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ProductsToBeItems = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(productsToBeItems, { warehouse: null, parent: null, products: [], isItemSetup: false, product: null })
    const { currentUser } = useContext(GlobalContext)
    const [sections1, setSections1] = useState([])
    const [sections2, setSections2] = useState([])
    const [categories, setCategories] = useState([])
    const [warehouses, setWarehouses] = useState([])
    const [countries, setCountries] = useState([])
    const [selectedCountryCode, setSelectedCountryCode] = useState("")
    const [selectedSection1SKU, setSelectedSection1SKU] = useState("")
    const [selectedSection2SKU, setSelectedSection2SKU] = useState("")
    const [selectedCategorySKU, setSelectedCategorySKU] = useState("")
    const [selectedAncestorSKU, setSelectedAncestorSKU] = useState("")
    const [nextToken, setNextToken] = useState()
    const [more, setMore] = useState(false)


    const rolesSet = new Set(currentUser.groups)
    const isGM = rolesSet.has('GM')
    //CM but not GM
    const isCM = rolesSet.has('CM') && !isGM
    //WM but not CM nor GM
    const isWM = rolesSet.has('WM') && !isCM && !isGM

    const userLocale = currentUser.locale
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            loadSections1()
            configureWarehouseFilter()
        }

        return () => {
            isSubscribed = false
        }

    }, [])

    useEffect(() => {
        loadSections2()
    }, [selectedSection1SKU])

    useEffect(() => {
        if (selectedSection2SKU) {
            loadCategories()
        }

    }, [selectedSection2SKU])
    useEffect(() => {
        //Since the nextChildSKU of the parent has been changed, it needs to be replaced
        replaceReloadedCategory()
    }, [state.parent])


    const queryChanged = () => {
        setNextToken(null)
        setMore(false)
        dispatch({ type: 'CLEAR_PRODUCTS' })
    }

    useEffect(() => {
        if(state.warehouse){
            loadProducts()
        }
        
    }, [selectedAncestorSKU])

    useEffect(()=>{
        if(state.warehouse){
            loadProducts()
        }
    },[state.warehouse])

    const configureWarehouseFilter = () => {
        //If isWM we need to get the warehouse and disable any filter
        //If isCM we need to get all warehouses of the manager's country to enable only warehouses filter
        //If isGM we need get all countries to enable country and warehouses filters
        if (isWM) {
            loadWarehouse()
        } else if (isCM) {
            loadWarehousesOfCountry()
        } else { //isGM
            loadCountries()
        }
    }

    async function loadWarehouse() {
        const params = {
            managerId: currentUser.username,
            countryCode: currentUser.countryCode
        }
        try {
            const warehouseData = await API.graphql(graphqlOperation(getWarehouseByManager, params))
            dispatch({ type: 'SET_WAREHOUSE', warehouse: warehouseData.data.getWarehouseByManager })
        } catch (error) {
            console.log(error)
        }
    }

    async function loadWarehousesOfCountry(selectedCountryCode) {
        const params = {
            countryCode: selectedCountryCode ? selectedCountryCode : currentUser.countryCode
        }
        try {
            const warehousesData = await API.graphql(graphqlOperation(listWarehousesByCountry, params))
            setWarehouses(warehousesData.data.listWarehousesByCountry.items)
        } catch (error) {
            console.log(error)
        }
    }

    async function loadCountries() {
        const params = {
            userLocale: currentUser.locale
        }
        try {
            const countriesData = await API.graphql(graphqlOperation(listCountries, params))
            setCountries(countriesData.data.listCountries.items)

        } catch (error) {
            console.log(error)
        }
    }

    const countrySelected = (selectedCountryCode) => {
        setSelectedCountryCode(selectedCountryCode)
        loadWarehousesOfCountry(selectedCountryCode)
    }

    const warehouseSelected = (warehouseId) => {
        let selectedWarehouse = null
        warehouses.some((warehouse) => {
            if (warehouse.id === warehouseId) {
                selectedWarehouse = warehouse
                return
            }
        })
        dispatch({ type: 'SET_WAREHOUSE', warehouse: selectedWarehouse })
        queryChanged()
    }

    const replaceReloadedCategory = () => {
        if (state.parent) {
            let newCategoriesList = categories.map((category) => {
                if (category.sku === state.parent.sku) {
                    return state.parent
                }
                return category
            })
            setCategories(newCategoriesList)
        }

    }

    const categorySelected = (sku) => {

        let selectedParent = null
        categories.map((category) => {
            if (sku === category.sku) {
                selectedParent = category
            }
        })
        if (selectedParent) {
            dispatch({ type: 'SET_PARENT', parent: selectedParent })
        }
        if (sku != selectedAncestorSKU) {
            queryChanged()
            setSelectedAncestorSKU(sku)
        }

        setSelectedCategorySKU(sku)
    }

    const section1Seclected = (sku) => {
        queryChanged()
        setSelectedSection1SKU(sku)
        setSelectedAncestorSKU(sku)
        setSelectedSection2SKU("")
        setSelectedCategorySKU("")
        dispatch({ type: 'SET_PARENT', parent: null })

    }

    const section2Seclected = (sku) => {
        queryChanged()
        setSelectedSection2SKU(sku)
        setSelectedAncestorSKU(sku)
        setSelectedCategorySKU("")
        dispatch({ type: 'SET_PARENT', parent: null })
    }

    const populateLoadedProducts = (loadedProducts) => {
        dispatch({ type: 'LOAD_MORE_PRODUCTS', products: loadedProducts })
    }

    const loadSections1 = () => {
        loadSectionsFromDB()
            .then((data) => {
                setSections1(data.data.listSections.items)
            }
            ).catch((data) => {
                console.log(data)
            })
    }

    const loadSections2 = () => {
        loadSectionsFromDB(selectedSection1SKU)
            .then((data) => {
                setSections2(data.data.listSections.items)
            }
            ).catch((data) => {
                console.log(data)
            })
    }

    const loadCategories = () => {
        loadCategoriesFromDB(selectedSection2SKU)
            .then((data) => {
                setCategories(data.data.listCategories.items)
            }
            ).catch((data) => {
                console.log(data)
            })
    }

    const loadProducts = () => {
        loadProductsFromDB()
            .then((data) => {
                populateLoadedProducts(data.data.listProductsToBeItems.items)
                const token = data.data.listProductsToBeItems.nextToken
                setNextToken(data.data.listProductsToBeItems.nextToken)
                if (token) {
                    setMore(true)
                } else {
                    setMore(false)
                }
            }
            ).catch((data) => {
                console.log(data.errors)
            })
    }

    async function loadSectionsFromDB(selectedParent) {
        let params = { userLocale }
        if (selectedParent) {
            params["parent"] = selectedParent
        }
        return API.graphql(graphqlOperation(listSections, params))

    }

    async function loadCategoriesFromDB(selectedParent) {
        let params = { userLocale }
        params["parent"] = selectedParent
        return API.graphql(graphqlOperation(listCategories, params))

    }

    async function loadProductsFromDB() {
        let params = { userLocale, nextToken, warehouseId: state.warehouse.id, limit:1000 }
        if (selectedAncestorSKU) {
            params["parent"] = selectedAncestorSKU
        }

        return API.graphql(graphqlOperation(listProductsToBeItems, params))

    }
    return (
        <Paper >
            <ProductsToBeItemsContext.Provider value={{ ...state, dispatch }} >
                {/* If isWM we don't need to show the filter, otherwise the user is either CM or GM */}
                {!isWM && <Grid className={classes.grid} container spacing={3} justify="space-between">
                    <Grid item lg={3}>
                        <Typography className={classes.gridItem} variant="h5" color='textPrimary'>
                            {I18n.get('label_warehouse')}
                        </Typography>

                    </Grid>
                    {/* If not isCM then the user is definitly GM and the country filter should be shown */}
                    {!isCM && <Grid item lg={3}>
                        <FormControl className={classes.gridItem} >
                            <InputLabel shrink htmlFor="country-select">{I18n.get('label_country')}</InputLabel>
                            <Select
                                onChange={e => countrySelected(e.target.value)}
                                value={selectedCountryCode}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{
                                    id: "country-select"
                                }}
                            >
                                {countries.map((country, index) => (
                                    <MenuItem
                                        key={index}
                                        value={country.countryCode}
                                    >
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                    <Grid item lg={3}>
                        <FormControl className={classes.gridItem}>
                            <InputLabel shrink htmlFor="warehouse-select">{I18n.get('label_warehouse')}</InputLabel>
                            <Select
                                onChange={e => warehouseSelected(e.target.value)}
                                value={state.warehouse ? state.warehouse.id : ""}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{
                                    id: "warehouse-select"
                                }}
                            >
                                {warehouses.map((warehouse, index) => (
                                    <MenuItem
                                        key={index}
                                        value={warehouse.id}
                                    >
                                        {warehouse.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3}></Grid>
                </Grid>
                }
                <Grid className={classes.grid} container spacing={3} justify="space-between">
                    <Grid item lg={3}>
                        <Typography className={classes.gridItem} variant="h5" color='textPrimary'>
                            {I18n.get('label_categories')}
                        </Typography>

                    </Grid>
                    <Grid item lg={3}>
                        <FormControl className={classes.gridItem}>
                            <InputLabel shrink htmlFor="section1-select">{I18n.get('label_section1')}</InputLabel>
                            <Select
                                onChange={e => section1Seclected(e.target.value)}
                                value={selectedSection1SKU}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{
                                    id: "section1-select"
                                }}
                            >
                                {sections1.map((section1, index) => (
                                    <MenuItem
                                        key={index}
                                        value={section1.sku}
                                    >
                                        {section1.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3}>
                        <FormControl className={classes.gridItem}>
                            <InputLabel shrink htmlFor="section2-select">{I18n.get('label_section2')}</InputLabel>
                            <Select
                                onChange={e => section2Seclected(e.target.value)}
                                value={selectedSection2SKU}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{
                                    id: "section2-select"
                                }}
                            >
                                {sections2.map((section2, index) => (
                                    <MenuItem
                                        key={index}
                                        value={section2.sku}
                                    >
                                        {section2.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3}>
                        <FormControl className={classes.gridItem}>
                            <InputLabel shrink htmlFor="category-select">{I18n.get('label_category')}</InputLabel>
                            <Select
                                onChange={e => categorySelected(e.target.value)}
                                value={selectedCategorySKU}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{
                                    id: "category-select"
                                }}
                            >
                                {categories.map((category, index) => (
                                    <MenuItem
                                        key={index}
                                        value={category.sku}
                                    >
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <ProductsToBeItemsList />
                {(more) && <IconButton
                    color="secondary"
                    onClick={() => loadProducts()}
                >
                    <MoreHorizOutlinedIcon />
                </IconButton>}
                {state.isItemSetup && <ItemSetupDialog />}
            </ProductsToBeItemsContext.Provider>
        </Paper>

    )

}

export default ProductsToBeItems