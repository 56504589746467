import React, {useReducer, useEffect, useContext} from 'react'

import API, {graphqlOperation} from '@aws-amplify/api'
import {listWarehousesByCountry} from '../../graphql/queries'

import WarehousesContext from '../../context/warehouses-context'
import GlobalContext from '../../context/global-context'
import warehousesReducer from '../../reducers/warehouses'
import CreateWarehouseForm from './CreateWarehouseForm';
import WarehousesList from './WarehousesList';

const WarehousesPage = () => {
    const [state, dispatch] = useReducer(warehousesReducer, {warehouses: []})
    const {currentUser} = useContext(GlobalContext)
    const countryCode = currentUser.countryCode
    useEffect(()=>{
        let isSubscribed = true
        loadWarehouses()
        .then((data)=> {
            if(isSubscribed){
                setWarehouses(data.data.listWarehousesByCountry)
            }
            
        })
        .catch((data)=>{
            console.log(data)
        })
        return ()=>{
            isSubscribed = false
        }
    }, [])

    function loadWarehouses(){
        return API.graphql(graphqlOperation(listWarehousesByCountry, {countryCode}))
    }

    const setWarehouses = (warehousesResult) =>{
        const warehouses = warehousesResult.items
        dispatch({
            'type': 'LOAD_WAREHOUSES',
            warehouses
        })
    }

    return (
        <WarehousesContext.Provider value={{...state, dispatch}}>
            <div className="form-above-list">
                <CreateWarehouseForm />
            </div>
            <WarehousesList />
        </WarehousesContext.Provider>

    )

}

export default WarehousesPage