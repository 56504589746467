import React, {useState, useEffect} from 'react'
import { I18n } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import {changeBannerStatus, addBannerImage} from '../../graphql/mutations'
import { Button, Divider, ListItem, GridListTile, GridList, GridListTileBar } from '@material-ui/core';
import PhotoViewer from '../helpers/PhotoViewer';
import Aladdin from '../../aladdin-config'

const useStyles = makeStyles(theme => ({
    coloumn:{
        width: 150
    },
    imagePreview: {
        width: '20em',
        height: '10em',
        cursor: 'pointer'
    },
    bannersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
      },
      gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },
      title: {
        color: theme.palette.primary.light,

      },
      tile:{
          width: '21em'
      },
      titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },

}));
const imagePlaceholder = './images/image-placeholder-800-400.png'
const aladdin = Aladdin()
const languages = aladdin.languages
const Banner = ({banner, dispatch})=>{
    const classes = useStyles();
    const [version, setVersion] = useState(1)

    useEffect(()=>{
        setVersion(version + 1)
    }, [banner])
    
    const changeStatus = async()=>{
        const params = {
            id: banner.id,
            countryCode: banner.countryCode,
            warehouseId: banner.warehouseId,
            active: !banner.active
        }
        const response = await API.graphql(graphqlOperation(changeBannerStatus, params))
        const newBanner = response.data.changeBannerStatus
        dispatch({type: 'UPDATE_BANNER', banner: newBanner})
    }

    const getImageKey = (locale)=>{
        if(banner.locales && banner.locales.includes(locale)){
            return `images/banners/${locale}/${banner.id}`
        }else{
            return ''
        }
        
        
    }

    const uploadImageHandler = async(file, locale) => {
        
        if (file) {
            //const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            //const uploadPathpath = getImageKey(locale)
            const key =  `images/banners/${locale}/${banner.id}`//`${uploadPathpath}.${extension}`
            try {
                await Storage.put(key, file, {
                    contentType: mimeType
                })
                const input = {
                    id: banner.id,
                    countryCode: banner.countryCode,
                    warehouseId: banner.warehouseId,
                    locale: locale
                }
                const response = await API.graphql(graphqlOperation(addBannerImage, input))
                const newBanner = response.data.addBannerImage
                dispatch({type: 'UPDATE_BANNER', banner: newBanner})
            } catch (err) {
                console.log('error: ', err)
            }
        }
        
        
    }

    return(
        <React.Fragment>
            <ListItem>
                <Grid container>
                    <Grid item className={classes.coloumn}>
                        <div>{I18n.get('label_banner_destination')}: {banner.appDestination ? I18n.get(`app_destination_${banner.appDestination}`) : I18n.get('label_not_exists')}</div>
                    </Grid>
                    <Grid item className={classes.coloumn}>
                        <div>{I18n.get('label_banner_barameter')}: {banner.parameter ? banner.parameter : I18n.get('label_not_exists')}</div>
                    </Grid>
                    <Grid item className={classes.coloumn}>
                        <Button  variant="contained" onClick={changeStatus} color="primary">
                                {banner.active ? I18n.get('action_deactivate') : I18n.get('action_activate')}
                        </Button>
                    </Grid>
                </Grid>
                    
                
            </ListItem>
            <div className={classes.bannersContainer}>
            <GridList className={classes.gridList} cols={2}>
                    {languages.map((language)=>(
                        <GridListTile className={classes.tile} key={`${language.locale}-${version}`}>
                                <PhotoViewer className={classes.imagePreview} imgKey={getImageKey(language.locale)} placeholder={imagePlaceholder} uploadHandler={file=> uploadImageHandler(file, language.locale)} />
                                <GridListTileBar 
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    title={language.locale}
                                />
                        </GridListTile>
                    ))}
                    
                </GridList>
                </div>
            <Divider/>
        </React.Fragment>
    )
}

export default Banner