import React, {useContext} from 'react'

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import IconButton from '@material-ui/core/IconButton'

import ProductsToBeItemsContext from '../../context/productsToBeItems-context'
import {fullNameOfProduct} from '../../utils/products'

const ProductToBeItem = ({product}) => {
    const {dispatch} = useContext(ProductsToBeItemsContext)

    const startItemSetup = () => {
        dispatch({type: 'START_ITEM_SETUP', product: product})
    }
    return (
        <TableRow >
            <TableCell
                style={{width:200}}
            >
                <Typography variant="body2">
                    {product.sku}
                </Typography>
            </TableCell>
            <TableCell
                style={{width:200}}
            >
                <Typography variant="body2">
                    {product.barcode}
                </Typography>
            </TableCell>
            <TableCell
                style={{minWidth:200}}
            >
                <Typography variant="body2">
                    {fullNameOfProduct(product)}
                </Typography>
            </TableCell>
            <TableCell
                style={{width:200}}
            >
                <IconButton color="primary" onClick={startItemSetup}>
                    <ExtensionOutlinedIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default ProductToBeItem