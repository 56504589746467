export const fullNameOfProduct = ({name, brand, colorName, attributes, size}) => {
    let fullName = name
    if (brand) {
        fullName = fullName.concat(' ', brand.name)
    }

    if (colorName) {
        fullName = fullName.concat(' ', colorName)
    }
    if (attributes) {
        fullName = fullName.concat(' ', attributes)
    }
    if (size) {
        fullName = fullName.concat(' ', size)
    }
    return fullName
}

export const fullNameOfProductWithBrandName = ({name, brandName, colorName, attributes, size}) => {
    let fullName = name
    if (brandName) {
        fullName = fullName.concat(' ', brandName)
    }

    if (colorName) {
        fullName = fullName.concat(' ', colorName)
    }
    if (attributes) {
        fullName = fullName.concat(' ', attributes)
    }
    if (size) {
        fullName = fullName.concat(' ', size)
    }
    return fullName
}

export const fullNameTranslationsOfProduct = ({brand, nameTranslations, colorNameTranslations, sizeTranslations, attributesTranslations}) => {
    let translations = []
    let brandTranslations = null
    if (brand) {
        brandTranslations = brand.translations
    }

    nameTranslations.map((translation, index) => {
        let name = translation.translation
        const locale = translation.locale
        if (brandTranslations) {
            const brand = brandTranslations.filter((brandTranslation) => {
                if (brandTranslation.locale === locale) {
                    return brandTranslation
                }
            })
            if (brand[0]) {
                name = name.concat(' ', brand[0].translation)
            }
        }


        const color = colorNameTranslations[index]
        if (color) {
            name = name.concat(' ', color.translation)
        }

        const attributes = attributesTranslations[index]
        if (attributes) {
            name = name.concat(' ', attributes.translation)
        }
        const size = sizeTranslations[index]
        if (size) {
            name = name.concat(' ', size.translation)
        }
        translations.push({ locale, translation: name })
    })
    return translations
}