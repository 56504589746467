import React , {useReducer, useEffect, useContext} from 'react'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import GlobalContext from '../../context/global-context'
import ticketsReducer from '../../reducers/tickets'
import API, {graphqlOperation} from '@aws-amplify/api'
import {listNewTickets, listAssignedTicket} from '../../graphql/queries'
import {assignTicket} from '../../graphql/mutations'
import {onTicketCreatedInCountry, onTicketAssignedInCountry} from '../../graphql/subscriptions'
import TicketsList from './TicketsList'
import { I18n } from 'aws-amplify'


const TicketsPage = (props) => {
    const { match: { params } } = props;
    const {currentUser} = useContext(GlobalContext)
    const [{newTickets, assignedTickets}, dispatch] = useReducer(ticketsReducer, { newTickets: [], assignedTickets:[] })

    //const ticketsStatus = params.status
    const countryCode = currentUser.countryCode
    const employeeId = currentUser.username
    const employeeName = currentUser.employeeName

    let onTicketCreatedSubscription
    let onTicketAssignedSubscription

    useEffect(() => {
        listTickets('NEW')
        listTickets('ASSIGNED')
        subscribeToNewTickets()
        subscribeToAssignTicket()
        
        return ()=>{
            onTicketCreatedSubscription.unsubscribe()
            onTicketAssignedSubscription.unsubscribe()
            
        }
    
    }, [])


    const subscribeToNewTickets = ()=>{
        onTicketCreatedSubscription = API.graphql(graphqlOperation(onTicketCreatedInCountry, { countryCode})).subscribe({
            next: (evt) =>{
                const ticket = evt.value.data.onTicketCreatedInCountry;
                dispatch({ type: 'ADD_NEW_TICKET', newTicket: ticket })
            },
            error: (error)=>{
                console.log(error)
            }
          });
        //   API.graphql(graphqlOperation(onTicketCreated, { countryCode, customerLocale:'de' })).subscribe({
        //     next: (evt) =>{
        //         const ticket = evt.value.data.onTicketCreated;
        //         dispatch({ type: 'ADD_NEW_TICKET', newTicket: ticket })
        //     },
        //     error: (error)=>{
        //         console.log(error)
        //     }
        //   });
    }

    const subscribeToAssignTicket = ()=>{
        onTicketAssignedSubscription= API.graphql(graphqlOperation(onTicketAssignedInCountry, { countryCode})).subscribe({
            next: (evt) =>{
                const ticket = evt.value.data.onTicketAssignedInCountry;
                dispatch({ type: 'ASSIGN_TICKET', ticket: ticket })
            },
            error: (error)=>{
                console.log(error)
            }
          });
    }


    const listTickets = async(ticketsStatus)=>{
        const args = {
            countryCode
        }
        try {
            if(ticketsStatus === 'NEW'){
                const response = await API.graphql(graphqlOperation(listNewTickets, args))
                dispatch({ type: 'LOAD_NEW_TICKETS', newTickets: response.data.listNewTickets })
            }else if(ticketsStatus === 'ASSIGNED'){
                const response = await API.graphql(graphqlOperation(listAssignedTicket, args))
                dispatch({ type: 'LOAD_ASSIGNED_TICKETS', assignedTickets: response.data.listAssignedTicket })
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    const handleAssignTicket = async (ticket)=>{
        const args = {
            ticketId: ticket.ticketId,
            customerNumber: ticket.customerNumber,
            createDate: ticket.createDate,
            countryCode: ticket.countryCode,
            employeeId: employeeId,
            employeeName: employeeName
        }
        try {
            const response = await API.graphql(graphqlOperation(assignTicket, args))
            //dispatch({ type: 'ASSIGN_TICKET', ticketId: response.data.assignTicket.ticketId })
            props.history.push(`/open-ticket/${ticket.customerNumber}/${ticket.ticketId}/${ticket.createDate}/${ticket.countryCode}`)
            
        } catch (error) {
            console.log(error)
        }
        
    }

    const handleOpenTicket = (ticket)=>{
        props.history.push(`/open-ticket/${ticket.customerNumber}/${ticket.ticketId}/${ticket.createDate}/${ticket.countryCode}`)
        
    }

    
    return (
        <Paper >
            <Typography variant="body2">
                {I18n.get('label_open_tickets')}
            </Typography>
            <TicketsList
                ticketStatus={'ASSIGNED'}
                tickets={assignedTickets}
                assignTicketHandler={handleAssignTicket}
                openTicketHandler={handleOpenTicket}
            />
            <Typography variant="body2">
                {I18n.get('label_new_tickets')}
            </Typography>
            <TicketsList
                ticketStatus={'NEW'}
                tickets={newTickets}
                assignTicketHandler={handleAssignTicket}
                openTicketHandler={handleOpenTicket}
            />
        </Paper>
        
    )
}

export default TicketsPage

