import React, {useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'

import ProvidersContext from '../../context/providers-context'
import Provider from './Provider'

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    tableWrapper: {
      maxHeight: 600,
      overflow: 'auto',
    },
  });

const ProvidersList = () => {

    const {providers} = useContext(ProvidersContext)
    const classes = useStyles();
    return (

        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  key='name'
                  style={{ minWidth: 50 }}
                >
                  <Typography variant="subtitle1" color='textPrimary'>
                    {I18n.get('label_name')}
                  </Typography>
    
                </TableCell>
                <TableCell
                  key='address'
                  style={{ minWidth: 150 }}
                >
                  <Typography variant="subtitle1" color='textPrimary'>
                    {I18n.get('label_address')}
                  </Typography>
    
                </TableCell>
                <TableCell
                  key='contactName'
                  style={{ minWidth: 50 }}
                >
                  <Typography variant="subtitle1" color='textPrimary'>
                    {I18n.get('label_contact_name')}
                  </Typography>
    
                </TableCell>
                <TableCell
                  key='telephone'
                  style={{ minWidth: 50 }}
                >
                  <Typography variant="subtitle1" color='textPrimary'>
                    {I18n.get('label_telephone')}
                  </Typography>
    
                </TableCell>
                <TableCell
                  key='website'
                  style={{ minWidth: 50 }}
                >
                  <Typography variant="subtitle1" color='textPrimary'>
                    {I18n.get('label_website')}
                  </Typography>
    
                </TableCell>
                <TableCell
                  key='edit'
                  style={{ minWidth: 50 }}
                >
                  <Typography variant="subtitle1" color='textPrimary'>
                    {I18n.get('label_edit')}
                  </Typography>
    
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {providers.map((provider) => (
                <Provider key={provider.id} provider={provider} />
              ))}
            </TableBody>
          </Table>
        </div>
    
    
      )
}

export default ProvidersList