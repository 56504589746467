import React, {useState, useContext} from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';

import { I18n } from 'aws-amplify'
import API, { graphqlOperation } from '@aws-amplify/api'
import { updateProductBarcode } from '../../graphql/mutations'

import ProductsContext from '../../context/products-context'
import GlobalContext from '../../context/global-context'

const EditBarcodeDialog = (props) => {
    
    const {dispatch} = useContext(ProductsContext)
    const {currentUser} = useContext(GlobalContext)
    const [barcode, setBarcode] = useState('')
    const [open, setOpen] = useState(props.product.sku === '' ? false : true)
    const [hasError, setHasError] = useState(false)
    const [formErrors, setFormErrors] = useState([])

    const handleClose = ()=>{
        clear()
        setOpen(false)
        props.closeHandler()
    }

    const handleSave = ()=>{
        const inputErrors = validateInputs()
        if(inputErrors.length === 0){
            saveBarcodeInDB()
        }else{
            setHasError(true)
            setFormErrors(inputErrors)
        }
        
    }

    const validateInputs = () =>{
        let errors = []
        if(barcode === ''){
            errors = [...errors, I18n.get('error_barcode_required')]
        }
        return errors
    }
    const saveBarcodeInDB = () =>{
        let params = {
            sku: props.product.sku,
            barcode
        }
        API.graphql(graphqlOperation(updateProductBarcode, params))
            .then((data) => {
                let updatedProduct = props.product
                updatedProduct.barcode = barcode
                dispatch({ type: 'UPDATE_PRODUCT', product: updatedProduct })
                handleClose()
            })
            .catch((data) => {
                const errors = data.errors
                errors.map((error) => {
                    if (error.errorType === "DynamoDB:ConditionalCheckFailedException") {
                        setFormErrors([...formErrors, I18n.get('error_provider_exists')])
                        setHasError(true)
                    } else {
                        let path = ''
                        if (error.path) {
                            path = `${error.path[0]}, `
                        }
                        const message = error.message
                        const errorString = `${path}${message}`
                        setFormErrors([...formErrors, errorString])
                        setHasError(true)
                    }
                })
            })
    }

    const clear = ()=>{
        setBarcode('')
        setHasError(false)
        setFormErrors([])
    }

    const copyBarcode = () => {
        setBarcode(props.product.sku)
    }


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
            <DialogTitle id="form-dialog-title">{I18n.get('action_set_barcode')}</DialogTitle>
            <form>
                <DialogContent >
                    {hasError && <Box component="div" m={1}>
                        {formErrors.map((error, index) => (
                            <Typography key={index} variant="body2" color="error"> {error}</Typography>
                        ))
                        }
                    </Box>
                    }
                    
                    <TextField
                            autoFocus={false}
                            label={I18n.get('label_sku')}
                            value={props.product.sku}
                            disabled={true}
                        />
                        <IconButton color="primary" onClick={copyBarcode}>
                            {currentUser.locale==='ar' ? (
                                <KeyboardArrowLeftOutlinedIcon />
                            ) : (
                                <KeyboardArrowRightOutlinedIcon />
                            )}
                            
                        </IconButton>
                        <TextField
                        autoFocus={true}
                        required
                        label={I18n.get('label_barcode')}
                        value={barcode}
                        onChange={e => setBarcode(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {I18n.get('action_cancel')}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {I18n.get('action_save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditBarcodeDialog