import React, { useContext, useState } from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';

import { API, Storage, graphqlOperation } from 'aws-amplify'

import GlobalContext from '../../context/global-context'
import SectionsContext from '../../context/sections-context'
import Translation from '../helpers/Translation'
import { updateSectionTranslation, updateSectionThumbnail } from '../../graphql/mutations'
import PhotoViewer from '../helpers/PhotoViewer'
import TranslationEditDialog from '../helpers/TranslationEditDialog'
import List from '@material-ui/core/List';

import config from '../../aws-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const imagePlaceholder = './images/image-placeholder-250-250.png'

const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: 0,
    },
    tableCell: {
        paddingTop: 0,
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16
    },
    imagePreview: {
      width: '48px',
      height: '48px',
      objectFit: 'cover',
      borderRadius: '30%',
      cursor: 'pointer',
      '&:hover':{
        width: '25em',
        height: '25em',
      }
    },
    
}));

const Section = ({ section }) => {
    const { currentUser } = useContext(GlobalContext)
    const { dispatch } = useContext(SectionsContext)
    const [open, setOpen] = useState(false);
    const classes = useStyles()

    const handleRemoveTranslation = (locale) => {
        if (locale === currentUser.locale) {
            console.log('Cannot remove')
        }
    }

    async function translationEditHandler(locale, newTranslation) {
        const input = {
            sku: section.sku,
            userLocale: currentUser.locale,
            locale,
            name: newTranslation
        }
        try {
            let result = await API.graphql(graphqlOperation(updateSectionTranslation, input))
            dispatch({ type: 'UPDATE_SECTION', section: result.data.updateSectionTranslation })
        } catch (error) {
            console.log(error)
        }
    }

    async function uploadHandler(file) {
        if (file) {
            const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/sections/${section.sku}.${extension}`
            try {
                await Storage.put(key, file, {
                    contentType: mimeType
                })
                const input = {
                    sku: section.sku,
                    userLocale: currentUser.locale,
                    thumbnail: key
                }
                let result = await API.graphql(graphqlOperation(updateSectionThumbnail, input))
                dispatch({ type: 'UPDATE_SECTION', section: result.data.updateSectionThumbnail })
            } catch (err) {
                console.log('error: ', err)
            }
        }
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleCreateTranslation = (locale, translation) => {
        translationEditHandler(locale, translation)
        setOpen(false)
    }
    return (
        <React.Fragment>
            <TableRow hover className={classes.tableRow}>
                <TableCell className={classes.tableCell}
                    key='sku'
                    style={{ minWidth: 20 }}
                >
                    <Typography>
                        {section.sku}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='name'
                    style={{ minWidth: 100 }}
                >
                    <Typography>
                        {section.name}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}
                    key='translations'
                    style={{ minWidth: 120 }}
                >
                    <List dense={true}>
                        {section.translations.map((translation) => (
                            <Translation
                                key={translation.locale}
                                translation={translation}
                                editHandler={translationEditHandler}
                                canRemove={translation.locale !== currentUser.locale}
                                removeHandler={() => (handleRemoveTranslation(translation.locale))}
                            />
                        ))}
                    </List>
                    <IconButton color="primary" onClick={handleOpen}>
                        <AddCircleOutlineIcon fontSize="small" />
                    </IconButton>
                </TableCell>
                <TableCell className={classes.tableCell}
                    align="center"
                    key='image'
                    style={{ minWidth: 120 }}
                >
                    <PhotoViewer className={classes.imagePreview} imgKey={section.thumbnail} placeholder={imagePlaceholder} uploadHandler={uploadHandler} />
                </TableCell>
            </TableRow>
            <TranslationEditDialog
                open={open}
                onClose={handleClose}
                onSave={handleCreateTranslation}
            />
        </React.Fragment>
    )
}
export default Section