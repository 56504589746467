import React, {useContext} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'

import ProductsToBeItemsContext from '../../context/productsToBeItems-context'
import ProductToBeItem from './ProductToBeItem'

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    tableWrapper: {
      maxHeight: 600,
      overflow: 'auto',
    },
  });

const ProductsToBeItemsList = () =>{
    const classes = useStyles();
    const {products} = useContext(ProductsToBeItemsContext)

    return (
        <div className={classes.tableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              key='sku'
              style={{ width: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_sku')}
              </Typography>
            </TableCell>
            <TableCell
              key='barcode'
              style={{ width: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_barcode')}
              </Typography>
            </TableCell>

            <TableCell
              key='name'
              style={{ minWidth: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('label_full_name')}
              </Typography>

            </TableCell>
            <TableCell
              key='actions'
              style={{ width: 200 }}
            >
              <Typography variant="subtitle1" color='textPrimary'>
                {I18n.get('action_item_setup')}
              </Typography>

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <ProductToBeItem key={product.sku} product={product} />
          ))}
        </TableBody>
      </Table>
    </div>
    )

}
export default ProductsToBeItemsList