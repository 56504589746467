import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'

import OrderItem from './OrderItem'

const useStyles = makeStyles({
    tableWrapper: {
        height: 600,
        overflow: 'auto',
    },
});

const OrderItemsList = ({ orderItems }) => {

    const classes = useStyles();

    return (
        <div className={classes.tableWrapper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell
                            key='sku'
                            style={{ width: 75 }}
                        >
                            <Typography variant="subtitle1" color='textPrimary'>
                                {I18n.get('label_sku')}
                            </Typography>
                        </TableCell>
                        <TableCell
                            key='name'
                            style={{ width: 200 }}
                        >
                            <Typography variant="subtitle1" color='textPrimary'>
                                {I18n.get('label_name')}
                            </Typography>
                        </TableCell>
                        <TableCell
                            key='quantity'
                            style={{ width: 25 }}
                        >
                            <Typography variant="subtitle1" color='textPrimary'>
                                {I18n.get('label_quantity')}
                            </Typography>
                        </TableCell>
                        <TableCell
                            key='listPrice'
                            style={{ width: 50 }}
                        >
                            <Typography variant="subtitle1" color='textPrimary'>
                                {I18n.get('label_list_price')}
                            </Typography>
                        </TableCell>
                        <TableCell
                            key='discount'
                            style={{ width: 50 }}
                        >
                            <Typography variant="subtitle1" color='textPrimary'>
                                {I18n.get('label_discount')}
                            </Typography>
                        </TableCell>
                        <TableCell
                            key='finalPrice'
                            style={{ width: 50 }}
                        >
                            <Typography variant="subtitle1" color='textPrimary'>
                                {I18n.get('label_final_price')}
                            </Typography>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderItems.map((orderItem) => (
                        <OrderItem key={orderItem.sku} orderItem={orderItem} />
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default OrderItemsList