import React, { useState, useContext, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";

import { I18n } from 'aws-amplify'
import API, { graphqlOperation } from '@aws-amplify/api'

import { createProvider } from '../../graphql/mutations'
import ProvidersContext from '../../context/providers-context'
import Aladdin from '../../aladdin-config'

const useStyles = makeStyles(theme => ({
    textInput: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const aladdin = Aladdin()

const ProviderFormDialog = (props) => {
    const classes = useStyles()
    const { dispatch } = useContext(ProvidersContext)
    const [providerName, setProviderName] = useState('')
    const [contactName, setContactName] = useState('')
    const [website, setWebsite] = useState('')
    const [telephone, setTelephone] = useState('')
    const [address, setAddress] = useState('')
    const [street, setStreet] = useState('')
    const [houseNr, setHouseNr] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [countryCode, setCountryCode] = useState('de')
    const [hasError, setHasError] = useState(false)
    const [formErrors, setFormErrors] = useState([])

    useEffect(() => {
        if (countryCode && zipCode && city && street && houseNr) {
            const fullAddress = `${countryCode.toUpperCase()}#${zipCode} ${city}#${street} ${houseNr}`
            setAddress(fullAddress)
        }

    }, [street, houseNr, zipCode, city, countryCode])

    const handleClose = () => {
        clear()
        props.onClose()
    }

    const handleSave = () => {
        const inputsErrors = validateInputs()
        if (inputsErrors.length === 0) {
            createProviderInDB()
        } else {
            setFormErrors(inputsErrors)
            setHasError(true)
        }
    }
    const validateInputs = () => {
        let errors = []
        if(providerName === ''){
            errors = [...errors, I18n.get('error_provider_name_required')]
        }
        if(contactName === ''){
            errors = [...errors, I18n.get('error_contact_name_required')]
        }
        if(street === ''){
            errors = [...errors, I18n.get('error_street_required')]
        }
        if(houseNr === ''){
            errors = [...errors, I18n.get('error_houseNr_required')]
        }
        if(zipCode === ''){
            errors = [...errors, I18n.get('error_zip_required')]
        }
        if(city === ''){
            errors = [...errors, I18n.get('error_city_required')]
        }

        return errors
    }

    const clear = () => {
        setProviderName('')
        setContactName('')
        setWebsite('')
        setAddress('')
        setStreet('')
        setHouseNr('')
        setZipCode('')
        setCity('')
        setCountryCode('de')
        setTelephone('')
        setHasError(false)
        setFormErrors([])
    }

    const createProviderInDB = () => {

        let input = {
            providerName,
            address,
            contactName,
            telephone,
            website
        }
        API.graphql(graphqlOperation(createProvider, { input }))
            .then((data) => {
                const createdProvider = data.data.createProvider
                dispatch({ type: 'CREATE_PROVIDER', provider: createdProvider })
                handleClose()
            })
            .catch((data) => {
                const errors = data.errors
                errors.map((error) => {
                    if (error.errorType === "DynamoDB:ConditionalCheckFailedException") {
                        setFormErrors([...formErrors, I18n.get('error_provider_exists')])
                        setHasError(true)
                    } else {
                        let path = ''
                        if (error.path) {
                            path = `${error.path[0]}, `
                        }
                        const message = error.message
                        const errorString = `${path}${message}`
                        setFormErrors([...formErrors, errorString])
                        setHasError(true)
                    }
                })
            })
    }

    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
            <DialogTitle id="form-dialog-title">{I18n.get('action_new_edit_provider')}</DialogTitle>
            <form>
                <DialogContent>
                    {hasError && <Box component="div" m={1}>
                        {formErrors.map((error, index) => (
                            <Typography key={index} variant="body2" color="error"> {error}</Typography>
                        ))
                        }
                    </Box>
                    }
                    <Grid container>
                        <Grid item sm={3}>
                            <TextField
                                className={classes.textInput}
                                autoFocus={true}
                                required
                                label={I18n.get('label_provider_name')}
                                value={providerName}
                                onChange={e => setProviderName(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={4}>
                            <TextField
                                className={classes.textInput}
                                required
                                label={I18n.get('label_street')}
                                value={street}
                                onChange={e => setStreet(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={2}>
                            <TextField
                                className={classes.textInput}
                                required
                                label={I18n.get('label_house_nr')}
                                value={houseNr}
                                onChange={e => setHouseNr(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={2}>
                            <TextField
                                className={classes.textInput}
                                required
                                label={I18n.get('label_zip_code')}
                                value={zipCode}
                                onChange={e => setZipCode(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                className={classes.textInput}
                                required
                                label={I18n.get('label_city')}
                                value={city}
                                onChange={e => setCity(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={3}>
                            <TextField
                                className={classes.textInput}
                                label={I18n.get('label_website')}
                                value={website}
                                onChange={e => setWebsite(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={3}>
                            <TextField
                                className={classes.textInput}
                                required
                                label={I18n.get('label_contact_name')}
                                value={contactName}
                                onChange={e => setContactName(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <TextField
                                className={classes.textInput}
                                label={I18n.get('label_telephone')}
                                value={telephone}
                                onChange={e => setTelephone(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {I18n.get('action_cancel')}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {I18n.get('action_save')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )

}

export default ProviderFormDialog