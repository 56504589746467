import React, {useReducer, useEffect, useContext, useState} from 'react'
import { I18n } from 'aws-amplify'
import GlobalContext from '../../context/global-context'
import API, {graphqlOperation} from '@aws-amplify/api'
import {createMessage, closeTicket} from '../../graphql/mutations'
import {getCustomerByNumber, listMessages} from '../../graphql/queries'
import {onMessageCreated} from '../../graphql/subscriptions'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'

import { makeStyles } from '@material-ui/core/styles';
import ticketMessagesReducer from '../../reducers/ticketMessages'
import CustomerInfo from '../customer/CustomerInfo'
import Chat from './Chat'
import CustomerOrdersPage from '../customer/CustomerOrdersPage'

const useStyles = makeStyles(theme => ({
    root:{
        height: 750
    },
    chat:{
        width: '30%'
    },
    orders:{
        width: '70%'
    },
    grid: {
        padding: theme.spacing(5),
        margin: theme.spacing(1)
    },
    closeTicketButton:{
        width: '100%'
    },
    input: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const OpenTicketPage = (props)=>{

    const classes = useStyles();
    const {currentUser} = useContext(GlobalContext)
    const [{customer, messages}, dispatch] = useReducer(ticketMessagesReducer, {messages:[]})
    const [closingTicket, setClosingTicket] = useState(false)
    const [messurement, setMessurement] = useState('')

    const { match: { params } } = props;
    const customerNumber = params.customerNumber
    const ticketId = params.ticketId
    const createDate = params.createDate
    const countryCode = params.countryCode

    let loaded = false

    useEffect(() => {
        if(!loaded){
            loadCustomer()
            loadMessages()
            subscribeToMessages()
            loaded = true
        }
        
    
    }, [])

    const loadCustomer = async ()=>{
        const args = {
            customerNumber
        }

        try {
            const response = await API.graphql(graphqlOperation(getCustomerByNumber, args))
            dispatch({ type: 'SET_CUSTOMER', customer: response.data.getCustomerByNumber })
            
        } catch (error) {
            console.log(error)
        }
    }

    const loadMessages = async ()=>{
        const args = {
            ticketId
        }

        try {
            const response = await API.graphql(graphqlOperation(listMessages, args))
            dispatch({ type: 'LOAD_MMESSAGES', messages: response.data.listMessages })
            
        } catch (error) {
            console.log(error)
        }
    }

    const subscribeToMessages = ()=>{
        const args = {
            ticketId,
            sender: 'CUSTOMER'
        }
        API.graphql(graphqlOperation(onMessageCreated, args)).subscribe({
            next: (evt) =>{
                const message = evt.value.data.onMessageCreated;
                dispatch({ type: 'ADD_NEW_MESSAGE', message: message })
            },
            error: (error)=>{
                console.log(error)
            }
          });
    }

    const handleSendMessage = async (sentMessage)=>{
        const args = {
            ticketId,
            sender: 'ALADDIN',
            type: 'TEXT',
            message: sentMessage,
            employeeId: currentUser.username,
            employeeName: currentUser.employeeName
        }
        try {
            const response = await API.graphql(graphqlOperation(createMessage, args))
            dispatch({ type: 'ADD_NEW_MESSAGE', message: response.data.createMessage })
            
        } catch (error) {
            console.log(error)
        }
    }

    const doCloseTicket = async (messurement)=>{
        const args = {
            ticketId,
            countryCode,
            createDate,
            customerNumber,
            messurement,
            employeeId: currentUser.username,
            employeeName: currentUser.employeeName
        }
        try {
            const response = await API.graphql(graphqlOperation(closeTicket, args))
            props.history.push(`/tickets/new`)
            
        } catch (error) {
            console.log(error)
        }
    }

    const handleCloseTicket = ()=>{
        if(!closingTicket){
            setClosingTicket(true)
        }else{
            if(messurement !== ''){
                doCloseTicket(messurement)
            }
        }
    }

    return (
        <Paper className={classes.root}>
            {customer && <Grid className={classes.grid} container spacing={3} justify="space-between">
                <Grid item className={classes.chat}>
                    <CustomerInfo customer={customer} />
                    {closingTicket && <TextField
                        variant = "outlined"
                        className={classes.input}
                        value={messurement}
                        placeholder={I18n.get('label_ticket_mesurement')}
                        onChange={e => setMessurement(e.target.value)}
                        multiline
                        rows= '6'
                    />}
                    <Button variant="contained" className={classes.closeTicketButton} onClick={handleCloseTicket} color="primary">
                        {I18n.get('action_close_ticket')}
                    </Button>
                    
                    {!closingTicket && <Chat messages={messages} sendMessageHandler={handleSendMessage}/>}
                </Grid>
                <Grid item className={classes.orders}>
                    <CustomerOrdersPage customer={customer} />

                </Grid>
            </Grid>
            }
        </Paper>
    )
}

export default OpenTicketPage