import { Button, Divider, Grid, ListItem } from "@material-ui/core"
import React from "react"
import { getFormattedDateTime } from "../../utils/dateUtil"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { setOccasionIsActive } from "../../graphql/mutations"

const Occasion = (props) => {
  const openDetails = () => {
    props.history.push(
      `/occasionDetails/${props.occasion.warehouseId}/${props.occasion.createDate}`
    )
  }

  const changeStatus = async () => {
    const params = {
      id: props.occasion.createDate,
      warehouseId: props.occasion.warehouseId,
      isActive: !props.occasion.isActive,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(setOccasionIsActive, params)
      )
      if (response.data.setOccasionIsActive === true) {
        props.dispatch({
          type: "UPDATE_OCCASION",
          occasion: { ...props.occasion, isActive: !props.occasion.isActive },
        })
      }
    } catch (error) {}
  }
  return (
    <React.Fragment>
      <ListItem onClick={openDetails}>
        <Grid container>
          <Grid item xs={6}>
            {I18n.get("label_create_date")}:{" "}
            {getFormattedDateTime(props.occasion.createDate)}
          </Grid>
          <Grid item xs={6}>
            {I18n.get("label_name")}: {props.occasion.name}
          </Grid>
          <Grid item xs={6}>
            {I18n.get("label_occasion_items")}:{" "}
            {props.occasion.items ? props.occasion.items.length : 0}
          </Grid>
          <Grid item xs={6}>
            {I18n.get("label_last_activation_date")}:{" "}
            {props.occasion.lastActivationDate
              ? getFormattedDateTime(props.occasion.lastActivationDate)
              : "-"}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={changeStatus}
              color="primary"
              disabled={
                !props.occasion.items || props.occasion.items.length === 0
              }
            >
              {props.occasion.isActive
                ? I18n.get("action_deactivate")
                : I18n.get("action_activate")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={openDetails} color="primary">
              {I18n.get("action_details")}
            </Button>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </React.Fragment>
  )
}

export default Occasion
