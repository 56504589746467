import React, { useState, useContext, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from "@material-ui/core/styles";

import { I18n } from 'aws-amplify'
import API, { graphqlOperation } from '@aws-amplify/api'

import { createCategory } from '../../graphql/mutations'
import GlobalContext from '../../context/global-context'
import CategoriesContext from '../../context/categories-context'
import Aladdin from '../../aladdin-config'



const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    },
    skuInput: {
        marginBottom: theme.spacing(4)
    }
}));

const aladdin = Aladdin()

const CreateCategoryDialog = (props) => {
    const classes = useStyles()
    const { parent, dispatch } = useContext(CategoriesContext)
    const { currentUser } = useContext(GlobalContext)
    const [hasError, setHasError] = useState(false)
    const [formErrors, setFormErrors] = useState([])
    const [nameTranslations, setNameTranslations] = useState([])

    let languagesOptions = []
    aladdin.languages.map((language) => {
        const label = I18n.get(language.code)
        const value = language.locale
        languagesOptions.push({ label, value })

    })
    const addTranslationControlls = () => {
        setNameTranslations([...nameTranslations, { locale: '', value: '' }])
    }
    const addTranslationLocale = (selectedIndex, selectedLocale) => {
        let tempTranslations = []
        nameTranslations.map((translation, index) => {
            let locale = translation.locale
            const value = translation.value
            if (index === selectedIndex) {
                locale = selectedLocale
            }
            tempTranslations.push({ locale, value })
        })
        setNameTranslations(tempTranslations)
    }

    const addTranslationValue = (selectedIndex, newValue) => {
        let tempTranslations = []
        nameTranslations.map((translation, index) => {
            const locale = translation.locale
            let value = translation.value
            if (index === selectedIndex) {
                value = newValue
            }
            tempTranslations.push({ locale, value })
        })
        setNameTranslations(tempTranslations)
    }

    const removeTranslation = (selectedIndex) => {
        let tempTranslations = nameTranslations.filter((translation, index) => {
            if (index !== selectedIndex) {
                return translation
            }
        })
        setNameTranslations(tempTranslations)
    }

    const createCategoryInDB = () => {
        let locales = []
        let names = []
        nameTranslations.map((nameTranslation) => {
            locales.push(nameTranslation.locale)
            names.push(nameTranslation.value)
        })
        let input = {
            userLocale: currentUser.locale,
            sku: parent.nextChildSKU,
            parent: parent.sku,
            locales,
            names
        }
        API.graphql(graphqlOperation(createCategory, { input }))
            .then((data) => {
                const createdCategory = data.data.createCategory
                const parent = createdCategory.parent
                dispatch({ type: 'CREATE_CATEGORY', category: createdCategory })
                //The parent has not been changed but since a new category was created, then we need to reload it to get its new nextChildSKU
                dispatch({type:'SET_PARENT', parent})
                handleClose()
            })
            .catch((data) => {
                const errors = data.errors
                errors.map((error) => {
                    if (error.errorType === "DynamoDB:ConditionalCheckFailedException") {
                        setFormErrors([...formErrors, I18n.get('error_category_exists')])
                        setHasError(true)
                    } else {
                        let path = ''
                        if (error.path) {
                            path = `${error.path[0]}, `
                        }
                        const message = error.message
                        const errorString = `${path}${message}`
                        setFormErrors([...formErrors, errorString])
                        setHasError(true)
                    }
                })
            })
    }

    const validateInputs = () => {
        let errors = []
        if (nameTranslations.length === 0) {
            errors = [...errors, I18n.get('error_name_required')]
        } else {
            nameTranslations.map((nameTranslation) => {
                if (nameTranslation.locale === '') {
                    errors = [...errors, I18n.get('error_select_language')]
                } else if (nameTranslation.value === '') {
                    const languageCode = 'language_' + nameTranslation.locale
                    const errorMessage = I18n.get('error_translation_for_language_required').concat(': ', I18n.get(languageCode))
                    errors = [...errors, errorMessage]
                }
            })
        }
        return errors
    }

    const handleSave = () => {
        const inputsErrors = validateInputs()
        if (inputsErrors.length === 0) {
            createCategoryInDB()
        } else {
            setFormErrors(inputsErrors)
            setHasError(true)
        }
    }

    const handleClose = () => {
        clear()
        props.onClose()
    }

    const keyPressedOnName = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addTranslationControlls()
        }
    }

    const clear = () => {
        setHasError(false)
        setFormErrors([])
        setNameTranslations([{ locale: currentUser.locale, value: '' }])
    }

    useEffect(() => {
        setNameTranslations([{ locale: currentUser.locale, value: '' }])
    }, [currentUser])
    useEffect(()=>{console.log('Create Category mounted')},[])

    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
                <DialogTitle id="form-dialog-title">{I18n.get('action_new_section')}</DialogTitle>
                <form >
                    <DialogContent>
                        {hasError && <Box component="div" m={1}>
                            {formErrors.map((error, index) => (
                                <Typography key={index} variant="body2" color="error"> {error}</Typography>
                            ))
                            }
                        </Box>
                        }
                        <TextField
                            className={classes.skuInput}
                            autoFocus={false}
                            label={I18n.get('label_sku')}
                            value={parent.nextChildSKU}
                            disabled={true}
                        />
                        <Typography variant="body1">
                            {I18n.get('label_translations')}
                            <IconButton color="primary" onClick={addTranslationControlls}>
                                <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                        {nameTranslations.map((translation, index) => (
                            <Grid container key={index}>
                                <Grid item sm={1}>
                                    <IconButton color="primary" onClick={() => { removeTranslation(index) }} disabled={index === 0}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item sm={2}>
                                    <FormControl required className={classes.formControl}>
                                        <InputLabel htmlFor={`language-${index}`}>{I18n.get('label_language')}</InputLabel>
                                        <Select
                                            onChange={e => addTranslationLocale(index, e.target.value)}
                                            value={translation.locale}
                                            inputProps={{
                                                id: `language-${index}`
                                            }}
                                        >
                                            {languagesOptions.map((languagesOption, languageOptoinIndex) => (
                                                <MenuItem
                                                    key={`${index}-${languageOptoinIndex}`}
                                                    value={languagesOption.value}
                                                >
                                                    {languagesOption.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        autoFocus={index === 0 ? (true) : (false)}
                                        fullWidth
                                        required
                                        label={I18n.get('label_translation')}
                                        value={translation.value}
                                        onChange={e => addTranslationValue(index, e.target.value)}
                                        onKeyPress={keyPressedOnName}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {I18n.get('action_cancel')}
                        </Button>
                        <Button onClick={handleSave} color="primary">
                            {I18n.get('action_save')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateCategoryDialog