import React from 'react';
import { Route } from 'react-router-dom';

import ResponsiveDrawer from '../components/ResponsiveDrawer'

const DashboardsRoute = ({component: Component, ...rest}) => {
  return (
      <Route {...rest} component={(props) => (
            <ResponsiveDrawer >
              <Component  {...props} />
            </ResponsiveDrawer>
       )} />
    )
}
export default DashboardsRoute