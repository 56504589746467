import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select'

import {I18n} from 'aws-amplify'

import Aladdin from '../../aladdin-config'

const aladdin = Aladdin()

const TranslationEditDialog = (props) => {
    const [hasError, setHasError] = useState(false)
    const [error, setError] = useState(null)
    const [translation, setTranslation] = useState('')
    const [locale, setLocale] = useState(props.locale)

    let languagesOptions = []
    aladdin.languages.map((language) => {
        const label = I18n.get(language.code)
        const value = language.locale
        languagesOptions.push({label, value})

    })

    const handleSave = () => {
        if(!locale){
            setHasError(true)
            setError(I18n.get('error_select_language'))
        }else if(!translation){
          setHasError(true)
          setError(I18n.get('error_name_required'))
        }else{
          props.onSave(locale, translation)
        }
    }
    const handleClose = () => {
        setHasError(false)
        setError(null)
        props.onClose()
    }


    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md" >
                <DialogTitle id="form-dialog-title">{props.locale ? (I18n.get('label_edit_translation')) : (I18n.get('action_new_translation'))}</DialogTitle>
                <DialogContent>
                    {hasError && <div className="error-row">
                            <div> {error}</div>
                        </div>
                    }
                {props.locale ? (
                    <DialogContentText>
                        {I18n.get('message_edit_translation_for')}{I18n.get(`language_${props.locale}`)}
                    </DialogContentText>
                ) : (
                    <Select
                        onChange={selectionOption => setLocale(selectionOption.value)}
                        options={languagesOptions}
                    />
                )}
                <div className="input-row" />
                <div className="input-row" />
                <div className="input-row" />
                <TextField
                    autoFocus
                    margin="dense"
                    label={I18n.get('label_translation')}
                    fullWidth
                    onChange={e => setTranslation(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {I18n.get('action_cancel')}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {I18n.get('action_save')}
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default TranslationEditDialog