import React, { useEffect, useState } from "react"
import { API, graphqlOperation, I18n } from "aws-amplify"
import { listOrdersAsResposeToDiscountsCampaign } from "../../graphql/queries"
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import OrdersList from "../order/OrdersList"
const useStyles = makeStyles({
  container: {
    width: "70%",
  },
  tableWrapper: {
    height: 600,
    overflow: "auto",
  },
  customerRow: {
    cursor: "pointer",
  },
})

const CampaignResponseDetails = (props) => {
  const classes = useStyles()
  const {
    match: { params },
  } = props
  const campaignDate = params.campaignDate
  const warehouseId = params.warehouseId
  const [orders, setOrders] = useState([])

  let subscribed = true
  useEffect(() => {
    getOrders()
    return () => {
      subscribed = false
    }
  }, [])

  const getOrders = () => {
    const params = {
      campaignDate,
      warehouseId: warehouseId,
    }
    API.graphql(
      graphqlOperation(listOrdersAsResposeToDiscountsCampaign, params)
    )
      .then((response) => {
        if (subscribed) {
          setOrders(response.data.listOrdersAsResposeToDiscountsCampaign)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleGetOrderDetails = (order) => {
    window.open(`/customerDetails/${order.customerNumber}`, "_blank")
  }
  return (
    <Paper>
      <Typography>
        {I18n.get("label_customers_responded_friend_code_reminder")}:{" "}
        {orders.length}
      </Typography>
      <div className={classes.tableWrapper}>
        <OrdersList orders={orders} clickHandler={handleGetOrderDetails} />
      </div>
    </Paper>
  )
}

export default CampaignResponseDetails
