import React, {useEffect, useState} from 'react'
import { I18n } from 'aws-amplify'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 400,
      marginTop: theme.spacing(1),
      direction: 'rtl',
    },
    input: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    customerMessage:{
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(1),
        direction: 'rtl'
    },
    aladdinMessage:{
        width: '90%',
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        margin: theme.spacing(1),
        direction: 'rtl'
    },
    sendButton:{
        width: '100%'
    }
  }));


const Chat = ({messages, sendMessageHandler})=>{
    const classes = useStyles();
    const [message, setMessage] = useState('')

    const endOfMessages = React.createRef();

    useEffect(() => {
        endOfMessages.current.scrollIntoView({ behavior: "smooth" })
        
      }, [messages]);

    const sendMessage = ()=>{
        if(message !== ''){
            sendMessageHandler(message)
            setMessage('')
        }
        
    }

    return (
            <React.Fragment>
                <List className={classes.root}>
                    {messages.map((message, index)=>(
                        <ListItem className={message.sender === 'CUSTOMER' ? classes.customerMessage : classes.aladdinMessage} key={index}>
                            <ListItemText primary={message.message} />
                        </ListItem>
                    ))}
                    <div style={{ float:"left", clear: "both" }}
                    ref={endOfMessages}>
                    
                    </div>
                </List>
                <TextField
                    variant = "outlined"
                    className={classes.input}
                    autoFocus={true}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    multiline
                    rows= '3'
                />
                <Button variant="contained" className={classes.sendButton} onClick={sendMessage} color="primary">
                    {I18n.get('action_send')}
                </Button>
            </React.Fragment>
    )
}   

export default Chat