
const sectionsReducer = (state, action) => {
    switch(action.type){
        case 'SET_PARENT':
            return {...state, parent: action.parent}
        case 'LOAD_SECTIONS':
            return {...state, sections: action.sections}
        case 'CREATE_SECTION':
            return {...state, sections: [...state.sections, action.section]}
        case 'UPDATE_SECTION':
            let newSectionsList = state.sections.map((section)=>{
                if(section.sku === action.section.sku){
                    return action.section
                }
                return section
            })
            return {...state, sections: newSectionsList}
        default:
            return state
    }
}
export default sectionsReducer