const employeesReducer = (state, action) => {
    switch(action.type){
        case 'START_EDITING':
            state = {...state, isEditing: true, username: action.username}
            return state
        case 'FINISH_EDITING':
                state = {...state, isEditing: false, username: ''}
                return state
        case 'LOAD_EMPLOYEES':
            state = {...state, employees: [...state.employees, ...action.employees]}
            return state
        case 'CREATE_EMPLOYEE':
            state = {...state, employees: [...state.employees, action.employee]}
            return state
        default:
            return state
    }
}

export { employeesReducer as default}