import React, { useReducer, useContext, useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { I18n } from 'aws-amplify'
import { API, graphqlOperation } from 'aws-amplify'

import GlobalContext from '../../context/global-context'
import BrandsContext from '../../context/brands-context'
import brandsReducer from '../../reducers/brands'
import { listBrands } from '../../graphql/queries'
import BrandsList from './BrandsList'
import CreateBrandDialog from './CreateBrandDialog'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120

    },
    grid: {
        padding: theme.spacing(5)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const BrandsPage = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(brandsReducer, { parent: null, brands: [] })
    const { currentUser } = useContext(GlobalContext)
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const userLocale = currentUser.locale
    useEffect(() => {
        let isSubscribed = true
        if(isSubscribed){
            loadBrands()
        }
        
        return () => {
            isSubscribed = false
        }

    }, [])

    const populateLoadedBrands = (loadedBrands) => {
        dispatch({ type: 'LOAD_BRANDS', brands: loadedBrands })
    }

    const loadBrands = () => {
        loadBrandsFromDB()
            .then((data) => {
                populateLoadedBrands(data.data.listBrands.items)
            }
            ).catch((data) => {
                console.log(data.errors)
            })
    }

    async function loadBrandsFromDB() {
        let params = { userLocale, limit:100 }
        return API.graphql(graphqlOperation(listBrands, params))

    }
    const newBrand = () => {
        setOpen(true);
    }

    return (
        <Paper >
            <BrandsContext.Provider value={{ ...state, dispatch }} >
                <Grid className={classes.grid} container spacing={3} justify="space-between">
                    <Grid item>
                        <Typography variant="h5" color='textPrimary'>
                            {I18n.get('label_brands')}
                            <IconButton color="primary" onClick={newBrand}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Typography>

                    </Grid>
                  </Grid>
                <BrandsList />
                <CreateBrandDialog
                    open={open}
                    onClose={handleClose}
                />
            </BrandsContext.Provider>
        </Paper>

    )

}
export default BrandsPage