import React, { useState } from "react"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getOrder } from "../../graphql/queries"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { getFormattedDateTime } from "../../utils/dateUtil"
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core"
import { Fragment } from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    height: 750,
  },
  coloumn: {
    width: "25%",
  },
}))

const ContestOrderPage = () => {
  const classes = useStyles()
  const [orderNumber, setOrderNumber] = useState("")
  const [order, setOrder] = useState()
  const [formError, setFormError] = useState()

  const reset = () => {
    setOrder()
    setOrderNumber("")
    setFormError()
  }

  const findByOrderNumber = async () => {
    const orderParams = {
      orderNumber: orderNumber.toUpperCase(),
    }
    const orderResponse = await API.graphql(
      graphqlOperation(getOrder, orderParams)
    )
    const order = orderResponse.data.getOrder
    if (!order) {
      setFormError(I18n.get("error_order_not_exists"))
      return
    }
    setOrder(order)
  }
  const formatName = (address) => {
    if (!address) return ""
    let formattedName = address.firstName + " " + address.lastName

    return formattedName
  }

  const getLocalizedOrderStatus = () => {
    const statusCode = `order_status_${order.orderStatus}`
    let status = I18n.get(statusCode)
    if (order.orderStatus === "CANCELLED") {
      const reasonCode = `cancel_reason_${order.cancelReason}`
      const reason = I18n.get(reasonCode)
      status = status + "-" + reason
    }
    return status
  }

  const getLocalizedPaymentType = () => {
    if (
      order.paymentType !== "ALADDIN_INVOICE" &&
      order.paymentType !== "ALADDIN_IN_ADVANCE"
    )
      return order.paymentType
    const aladdinPaymentType = `order_payment_type_${order.paymentType}`
    return I18n.get(aladdinPaymentType)
  }

  const getLocalizedAladdinPayment = () => {
    let statusCode = ""
    if (order.paymentType !== "ALADDIN_INVOICE") {
      statusCode = "order_aladdin_payment_status_IN_TIME"
    } else {
      statusCode = `order_aladdin_payment_status_${order.aladdinPaymentStatus}`
    }

    return I18n.get(statusCode)
  }
  return (
    <div className={classes.container}>
      <Typography>{formError}</Typography>
      <Grid container spacing={1}>
        <Grid item md={6} lg={2}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-error">
              {I18n.get("label_order_number")}
            </InputLabel>
            <OutlinedInput
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              aria-describedby="component-error-text"
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={findByOrderNumber}
            color="primary"
          >
            {I18n.get("action_search")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={reset} color="primary">
            {I18n.get("action_reset")}
          </Button>
        </Grid>
      </Grid>
      {order && (
        <Fragment>
          <div style={{ marginTop: 20 }} />
          <Grid container>
            <Grid item className={classes.coloumn}>
              <div>
                {I18n.get("label_order_number")}: {order.orderNumber}
              </div>
            </Grid>
            <Grid item className={classes.coloumn}>
              <div>
                {I18n.get("label_order_total")}: {order.orderTotal}
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className={classes.coloumn}>
              <div>
                {I18n.get("label_date")}:{" "}
                {getFormattedDateTime(order.orderDate)}
              </div>
            </Grid>

            <Grid item className={classes.coloumn}>
              <div>
                {I18n.get("label_payment_type")}: {getLocalizedPaymentType()}
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className={classes.coloumn}>
              <div>
                {I18n.get("label_name")}: {formatName(order.billingAddress)}
              </div>
            </Grid>
            <Grid item className={classes.coloumn}>
              <div>
                {I18n.get("label_status")}: {getLocalizedAladdinPayment()}
              </div>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </div>
  )
}

export default ContestOrderPage
