import React, { useState, useContext, useEffect } from "react"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined"
import KeyboardArrowLeftOutlinedIcon from "@material-ui/icons/KeyboardArrowLeftOutlined"

import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { createProduct, updateProduct } from "../../graphql/mutations"
import { listBrands } from "../../graphql/queries"
import GlobalContext from "../../context/global-context"
import ProductsContext from "../../context/products-context"
import Aladdin from "../../aladdin-config"
import googleCategories from './googleCategories.json'

const useStyles = makeStyles((theme) => ({
  translationContainer: {
    marginTop: theme.spacing(4),
  },
  formControl: {
    minWidth: 100,
    marginBottom: theme.spacing(2),
  },
  skuInput: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  translationInput: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    width: 40,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const aladdin = Aladdin()

const CreateProductDialog = (props) => {
  const classes = useStyles()
  const { parent, dispatch } = useContext(ProductsContext)
  const { currentUser } = useContext(GlobalContext)
  const [hasError, setHasError] = useState(false)
  const [formErrors, setFormErrors] = useState([])
  const [translations, setTranslations] = useState([])
  const [barcode, setBarcode] = useState("")
  const [brandId, setBrandId] = useState("")
  const [brands, setBrands] = useState([])
  const [googleCategory, setGoogleCategory] = useState("")
  const [gender, setGender] = useState("")
  const [ageGroup, setAgeGroup] = useState("")
  const [pricingBaseMeasureUnit, setPricingBaseMeasureUnit] = useState("")
  const [pricingBaseMeasreValue, setPricingBaseMeasreValue] = useState("")
  const [pricingMeasreValue, setPricingMeasreValue] = useState("")
  const [contentExample, setContentExample] = useState("")

  let languagesOptions = []
  aladdin.languages.map((language) => {
    const label = I18n.get(language.code)
    const value = language.locale
    languagesOptions.push({ label, value })
  })

  let measureUnitOptions = []
  aladdin.baseMeasureUnit.map((unit)=>{
    const label = I18n.get(unit.code)
    const value = unit.value
    measureUnitOptions.push({label, value})
  })
  let genderOptions = []
  aladdin.gender.map((g)=>{
    const label = I18n.get(g.code)
    const value = g.value
    genderOptions.push({label, value})
  })
  let ageGroupOptions = []
  aladdin.ageGroup.map((group)=>{
    const label = I18n.get(group.code)
    const value = group.value
    ageGroupOptions.push({label, value})
  })

  const prepareToEdit = () => {
    if (props.product) {
      let translationsToEdit = []
      const product = props.product
      const localesToEdit = product.locales
      localesToEdit.map((localeToEdit, index) => {
        const name = product.nameTranslations[index].translation
        const color = product.colorNameTranslations[index]
          ? product.colorNameTranslations[index].translation
          : ""
        const description = product.descriptionTranslations[index]
          ? product.descriptionTranslations[index].translation
          : ""
        const details = product.detailsTranslations[index]
          ? product.detailsTranslations[index].translation
          : ""
        translationsToEdit.push({
          locale: localeToEdit,
          name: name,
          colorName: color,
          description,
          details,
        })
      })
      setTranslations(translationsToEdit)
      if (product.barcode) {
        setBarcode(product.barcode)
      }
      if (product.brand) {
        setBrandId(product.brand.id)
      }
      if(product.googleCategory){
        setGoogleCategory(product.googleCategory)
      }
      if(product.pricingBaseMeasureUnit){
        setPricingBaseMeasureUnit(product.pricingBaseMeasureUnit)
      }
      if(product.pricingMeasreValue){
        setPricingMeasreValue(product.pricingMeasreValue)
      }
      if(product.pricingBaseMeasreValue){
        setPricingBaseMeasreValue(product.pricingBaseMeasreValue)
      }
      if(product.ageGroup){
        setAgeGroup(product.ageGroup)
      }
      if(product.gender){
        setGender(product.gender)
      }
    }
  }

  const copyBarcode = () => {
    setBarcode(props.product ? props.product.sku : parent.nextChildSKU)
  }
  const addTranslationControlls = () => {
    setTranslations([
      ...translations,
      {
        locale: "",
        name: "",
        colorName: "",
        description: "",
        details: "",
      },
    ])
  }

  const addTranslationLocale = (selectedIndex, selectedLocale) => {
    let tempTranslations = []
    translations.map((translation, index) => {
      let locale = translation.locale
      const name = translation.name
      const colorName = translation.colorName
      const description = translation.description
      const details = translation.details
      if (index === selectedIndex) {
        locale = selectedLocale
      }
      tempTranslations.push({
        locale,
        name,
        colorName,
        description,
        details,
      })
    })
    setTranslations(tempTranslations)
  }

  const addNameTranslation = (selectedIndex, newName) => {
    let tempTranslations = []
    translations.map((translation, index) => {
      const locale = translation.locale
      let name = translation.name
      const colorName = translation.colorName
      const description = translation.description
      const details = translation.details
      if (index === selectedIndex) {
        name = newName
      }
      tempTranslations.push({
        locale,
        name,
        colorName,
        description,
        details,
      })
    })
    setTranslations(tempTranslations)
  }
  
  const addColorNameTranslation = (selectedIndex, newColorName) => {
    let tempTranslations = []
    translations.map((translation, index) => {
      const locale = translation.locale
      const name = translation.name
      let colorName = translation.colorName
      const description = translation.description
      const details = translation.details
      if (index === selectedIndex) {
        colorName = newColorName
      }
      tempTranslations.push({
        locale,
        name,
        colorName,
        description,
        details,
      })
    })
    setTranslations(tempTranslations)
  }
  const addDescriptionTranslation = (selectedIndex, newDescription) => {
    let tempTranslations = []
    translations.map((translation, index) => {
      const locale = translation.locale
      const name = translation.name
      const colorName = translation.colorName
      let description = translation.description
      const details = translation.details
      if (index === selectedIndex) {
        description = newDescription
      }
      tempTranslations.push({
        locale,
        name,
        colorName,
        description,
        details,
      })
    })
    setTranslations(tempTranslations)
  }
  const addDetailsTranslation = (selectedIndex, newDetails) => {
    let tempTranslations = []
    translations.map((translation, index) => {
      const locale = translation.locale
      const name = translation.name
      const colorName = translation.colorName
      const description = translation.description
      let details = translation.details
      if (index === selectedIndex) {
        details = newDetails
      }
      tempTranslations.push({
        locale,
        name,
        colorName,
        description,
        details,
      })
    })
    setTranslations(tempTranslations)
  }

  const removeTranslation = (selectedIndex) => {
    let tempTranslations = translations.filter((translation, index) => {
      if (index !== selectedIndex) {
        return translation
      }
    })
    setTranslations(tempTranslations)
  }

  const pricingMeasureChanged = (unit, pricingMeasure, pricingBaseMeasure)=>{
    setPricingBaseMeasreValue(pricingBaseMeasure)
    setPricingBaseMeasureUnit(unit)
    setPricingMeasreValue(pricingMeasure.replace(",", "."))
    const unitCode = `base_measure_unit_${unit}`
    const example = `${I18n.get("lable_contents")} ${pricingMeasure} ${I18n.get(unitCode)} (2,43 / ${pricingBaseMeasure} ${I18n.get(unitCode)})`
    //like: Contents 230 g (2.43/100 g)
    setContentExample(example)
  }

  const createProductInDB = () => {
    let locales = []
    let names = []
    let colorNames = []
    let descriptions = []
    let details = []
    translations.map((translation) => {
      locales.push(translation.locale)
      names.push(translation.name)
      if (translation.colorName) {
        colorNames.push(translation.colorName)
      }
      if (translation.description) {
        descriptions.push(translation.description)
      }
      if (translation.details) {
        details.push(translation.details)
      }
    })

    let input = {
      userLocale: currentUser.locale,
      sku: parent.nextChildSKU,
      parentSku: parent.sku,
      locales,
      names,
      colorNames,
      descriptions,
      details,
    }
    if (brandId) {
      input["brandId"] = brandId
    }
    if (barcode) {
      input["barcode"] = barcode
    }
    if(googleCategory){
      input["googleCategory"] = googleCategory
    }
    if(pricingBaseMeasureUnit){
      input["pricingBaseMeasureUnit"] = pricingBaseMeasureUnit
    }
    if(pricingMeasreValue){
      input["pricingMeasreValue"] = pricingMeasreValue
    }
    if(pricingBaseMeasreValue){
      input["pricingBaseMeasreValue"] = pricingBaseMeasreValue
    }
    if(ageGroup){
      input["ageGroup"] = ageGroup
    }
    if(gender){
      input["gender"] = gender
    }
    console.log(input)
    API.graphql(graphqlOperation(createProduct, { input }))
      .then((data) => {
        const createdProduct = data.data.createProduct
        dispatch({ type: "CREATE_PRODUCT", product: createdProduct })
        //Since the nextChildSKU of the parent has been changed, it needs to be reloaded again
        dispatch({ type: "SET_PARENT", parent: createdProduct.parent })
        handleClose()
      })
      .catch((data) => {
        const errors = data.errors
        errors.map((error) => {
          if (error.errorType === "DynamoDB:ConditionalCheckFailedException") {
            setFormErrors([...formErrors, I18n.get("error_product_exists")])
            setHasError(true)
          } else {
            let path = ""
            if (error.path) {
              path = `${error.path[0]}, `
            }
            const message = error.message
            const errorString = `${path}${message}`
            setFormErrors([...formErrors, errorString])
            setHasError(true)
          }
        })
      })
  }

  const updateProductInDB = async () => {
    let updatedNameTranslations = []
    let updatedColorTranslations = []
    let updatedDescriptionTranslations = []
    let updatedDetailsTranslations = []
    let productToUpdate = props.product
    let locales = []
    let names = []
    let colorNames = []
    let descriptions = []
    let details = []
    let selectedBrand = null
    if (brandId) {
      brands.some((brand) => {
        if (brandId === brand.id) {
          selectedBrand = brand
          return
        }
      })
    }
    productToUpdate.name = ""
    productToUpdate.colorName = ""
    productToUpdate.description = ""
    productToUpdate.details = ""
    translations.map((translation, index) => {
      locales.push(translation.locale)
      names.push(translation.name)
      if (translation.locale === currentUser.locale) {
        productToUpdate.name = translation.name
      }
      updatedNameTranslations.push({
        locale: translation.locale,
        translation: translation.name,
      })
      if (translation.colorName) {
        colorNames.push(translation.colorName)
        updatedColorTranslations.push({
          locale: translation.locale,
          translation: translation.colorName,
        })
        if (translation.locale === currentUser.locale) {
          productToUpdate.colorName = translation.colorName
        }
      }
      if (translation.description) {
        descriptions.push(translation.description)
        updatedDescriptionTranslations.push({
          locale: translation.locale,
          translation: translation.description,
        })
        if (translation.locale === currentUser.locale) {
          productToUpdate.description = translation.description
        }
      }
      if (translation.details) {
        details.push(translation.details)
        updatedDetailsTranslations.push({
          locale: translation.locale,
          translation: translation.details,
        })
        if (translation.locale === currentUser.locale) {
          productToUpdate.details = translation.details
        }
      }
    })

    let input = {
      sku: productToUpdate.sku,
      locales,
      names,
      colorNames,
      descriptions,
      details,
    }
    if (brandId) {
      input["brandId"] = brandId
    }

    if (barcode) {
      input["barcode"] = barcode
    }
    if(googleCategory){
      input["googleCategory"] = googleCategory
    }
    if(pricingBaseMeasureUnit){
      input["pricingBaseMeasureUnit"] = pricingBaseMeasureUnit
    }
    if(pricingMeasreValue){
      input["pricingMeasreValue"] = pricingMeasreValue
    }
    if(pricingBaseMeasreValue){
      input["pricingBaseMeasreValue"] = pricingBaseMeasreValue
    }
    if(ageGroup){
      input["ageGroup"] = ageGroup
    }
    if(gender){
      input["gender"] = gender
    }
    console.log(input)
    try {
      await API.graphql(
        graphqlOperation(updateProduct, { input })
      )
      productToUpdate.brand = selectedBrand
      productToUpdate.nameTranslations = updatedNameTranslations
      productToUpdate.colorNameTranslations = updatedColorTranslations
      productToUpdate.descriptionTranslations = updatedDescriptionTranslations
      productToUpdate.detailsTranslations = updatedDetailsTranslations
      productToUpdate.googleCategory = googleCategory
      productToUpdate.pricingBaseMeasureUnit = pricingBaseMeasureUnit
      productToUpdate.pricingMeasreValue = pricingMeasreValue
      productToUpdate.pricingBaseMeasreValue = pricingBaseMeasreValue
      productToUpdate.ageGroup = ageGroup
      productToUpdate.gender = gender

      
      dispatch({ type: "UPDATE_PRODUCT", product: productToUpdate })
      handleClose()
    } catch (response) {
      const errors = response.errors
      errors.map((error) => {
        let path = ""
        if (error.path) {
          path = `${error.path[0]}, `
        }
        const message = error.message
        const errorString = `${path}${message}`
        setFormErrors([...formErrors, errorString])
        setHasError(true)
      })
    }
  }

  const validateInputs = () => {
    let errors = []
    if(!googleCategory) errors = [...errors, I18n.get("error_google_category_required")]
    const x = (!pricingMeasreValue && !pricingBaseMeasureUnit && !pricingBaseMeasureUnit) || pricingMeasreValue && pricingBaseMeasureUnit && pricingBaseMeasureUnit
    if(!x) errors = [...errors, I18n.get("error_pricing_measure")]
    if (translations.length === 0) {
      errors = [...errors, I18n.get("error_name_required")]
    } else {
      let colorTranlationCounter = 0
      let descriptionsTranslationCounter = 0
      let detailsTranslationCounter = 0
      translations.map((translation) => {
        if (translation.locale === "") {
          errors = [...errors, I18n.get("error_select_language")]
        } else {
          if (translation.name === "") {
            const languageCode = "language_" + translation.locale
            const errorMessage = I18n.get(
              "error_name_for_language_required"
            ).concat(": ", I18n.get(languageCode))
            errors = [...errors, errorMessage]
          }
          if (translation.colorName !== "") {
            colorTranlationCounter += 1
          }
          if (translation.description !== "") {
            descriptionsTranslationCounter += 1
          }
          if (translation.details != "") {
            detailsTranslationCounter += 1
          }
        }
      })
      if (
        colorTranlationCounter > 0 &&
        colorTranlationCounter < translations.length
      ) {
        errors = [...errors, I18n.get("error_color_all_or_nothing")]
      }
      if (
        descriptionsTranslationCounter > 0 &&
        descriptionsTranslationCounter < translations.length
      ) {
        errors = [...errors, I18n.get("error_description_all_or_nothing")]
      }
      if (
        detailsTranslationCounter > 0 &&
        detailsTranslationCounter < translations.length
      ) {
        errors = [...errors, I18n.get("error_details_all_or_nothing")]
      }
    }
    return errors
  }

  const handleSave = () => {
    const inputsErrors = validateInputs()
    if (inputsErrors.length === 0) {
      if (props.product) {
        updateProductInDB()
      } else {
        createProductInDB()
      }
    } else {
      setFormErrors(inputsErrors)
      setHasError(true)
    }
  }

  const handleClose = () => {
    clear()
    props.onClose()
  }

  const clear = () => {
    setHasError(false)
    setFormErrors([])
    setTranslations([
      {
        locale: currentUser.locale,
        name: "",
        colorName: "",
        description: "",
        details: "",
      },
    ])
    setBrandId("")
    setBarcode("")
    setGoogleCategory("")
    setAgeGroup("")
    setGender("")
    setPricingBaseMeasreValue("")
    setPricingBaseMeasureUnit("")
    setPricingMeasreValue("")
  }

  useEffect(() => {
    setTranslations([
      {
        locale: currentUser.locale,
        name: "",
        colorName: "",
        description: "",
        details: "",
      },
    ])
  }, [currentUser])
  useEffect(() => {
    prepareToEdit()
  }, [props.product])
  useEffect(() => {
    loadBrands()
  }, [])

  async function loadBrands() {
    try {
      const brandsData = await API.graphql(
        graphqlOperation(listBrands, { userLocale: currentUser.locale })
      )
      const sortedBrands = brandsData.data.listBrands.items.sort((a, b) => {
        if (a.name <= b.name) return -1
        return 1
      })
      setBrands(sortedBrands)
    } catch (error) {
      setHasError(true)
      setFormErrors([error])
    }
  }
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {I18n.get("action_new_product")}
        </DialogTitle>
        <form>
          <DialogContent>
            {hasError && (
              <Box component="div" m={1}>
                {formErrors.map((error, index) => (
                  <Typography key={index} variant="body2" color="error">
                    {" "}
                    {error}
                  </Typography>
                ))}
              </Box>
            )}
            <TextField
              className={classes.skuInput}
              autoFocus={false}
              label={I18n.get("label_sku")}
              value={props.product ? props.product.sku : parent.nextChildSKU}
              disabled={true}
            />
            <IconButton color="primary" onClick={copyBarcode}>
              {currentUser.locale === "ar" ? (
                <KeyboardArrowLeftOutlinedIcon />
              ) : (
                <KeyboardArrowRightOutlinedIcon />
              )}
            </IconButton>
            <TextField
              className={classes.skuInput}
              autoFocus={true}
              label={I18n.get("label_barcode")}
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
            />
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="select-brand">
                {I18n.get("label_brand")}
              </InputLabel>
              <Select
                onChange={(e) => setBrandId(e.target.value)}
                value={brandId}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "select-brand",
                }}
              >
                <MenuItem value="">{I18n.get("label_no_brand")}</MenuItem>
                {brands.map((brand, index) => (
                  <MenuItem key={index} value={brand.id}>
                    {brand.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div />
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="select-google-category">
                {I18n.get("label_google-category")}
              </InputLabel>
              <Select
                onChange={(e) => setGoogleCategory(e.target.value)}
                value={googleCategory}
                displayEmpty
                fullWidth
                className={classes.selectEmpty}
                inputProps={{
                  id: "select-google-category",
                }}
              >
                <MenuItem value="">{I18n.get("label_no_category")}</MenuItem>
                {googleCategories.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div />
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="select-base-measure-unit">
                {I18n.get("label_base_measure_unit")}
              </InputLabel>
              <Select
                onChange={(e) => pricingMeasureChanged(e.target.value, pricingMeasreValue, pricingBaseMeasreValue)}
                value={pricingBaseMeasureUnit}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "select-base-measure-unit",
                }}
              >
                <MenuItem value="">{I18n.get("label_no_unit")}</MenuItem>
                {measureUnitOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className={classes.skuInput}
                label={I18n.get("label_pricing_measure")}
                value={pricingMeasreValue}
                onChange={(e) => pricingMeasureChanged(pricingBaseMeasureUnit, e.target.value, pricingBaseMeasreValue)}
            />
            <TextField
              className={classes.skuInput}
              label={I18n.get("label_pricing_base_measure")}
              value={pricingBaseMeasreValue}
              onChange={(e) => pricingMeasureChanged(pricingBaseMeasureUnit, pricingMeasreValue, e.target.value)}
          />
          <div />
          {contentExample}
          <div />
          <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="select-age-group">
                {I18n.get("label_age_group")}
              </InputLabel>
              <Select
                onChange={(e) => setAgeGroup(e.target.value)}
                value={ageGroup}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "select-age-group",
                }}
              >
                <MenuItem value="">{I18n.get("label_no_age_group")}</MenuItem>
                {ageGroupOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div />
          <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="select-gender">
                {I18n.get("label_gender")}
              </InputLabel>
              <Select
                onChange={(e) => setGender(e.target.value)}
                value={gender}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                  id: "select-gender",
                }}
              >
                <MenuItem value="">{I18n.get("label_no_gender")}</MenuItem>
                {genderOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div />
            <Typography variant="body1">
              {I18n.get("label_translations")}
              <IconButton color="primary" onClick={addTranslationControlls}>
                <AddCircleOutlineIcon fontSize="small" />
              </IconButton>
            </Typography>
            {translations.map((translation, index) => (
              <Grid
                container
                key={index}
                className={classes.translationContainer}
              >
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    className={classes.deleteButton}
                    onClick={() => {
                      removeTranslation(index)
                    }}
                    disabled={index === 0}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <FormControl required className={classes.formControl}>
                    <InputLabel htmlFor={`language-${index}`}>
                      {I18n.get("label_language")}
                    </InputLabel>
                    <Select
                      onChange={(e) =>
                        addTranslationLocale(index, e.target.value)
                      }
                      value={translation.locale}
                      inputProps={{
                        id: `language-${index}`,
                      }}
                    >
                      {languagesOptions.map(
                        (languagesOption, languageOptoinIndex) => (
                          <MenuItem
                            key={`${index}-${languageOptoinIndex}`}
                            value={languagesOption.value}
                          >
                            {languagesOption.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.translationInput}>
                  <TextField
                    fullWidth
                    required
                    label={I18n.get("label_name")}
                    value={translation.name}
                    onChange={(e) => addNameTranslation(index, e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} className={classes.translationInput}>
                  <TextField
                    fullWidth
                    label={I18n.get("label_color")}
                    value={translation.colorName}
                    onChange={(e) =>
                      addColorNameTranslation(index, e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} className={classes.translationInput}>
                  <TextField
                    fullWidth
                    multiline={true}
                    label={I18n.get("label_description")}
                    value={translation.description}
                    onChange={(e) =>
                      addDescriptionTranslation(index, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} className={classes.translationInput}>
                  <TextField
                    fullWidth
                    multiline={true}
                    label={I18n.get("label_details")}
                    value={translation.details}
                    onChange={(e) =>
                      addDetailsTranslation(index, e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {I18n.get("action_cancel")}
            </Button>
            <Button onClick={handleSave} color="primary">
              {I18n.get("action_save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}

export default CreateProductDialog
