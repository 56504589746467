const Aladdin = () => {
  var AladdinConfig = {
    accessKeyId: "AKIA44HXG6LUQKBMTR6L",
    secretAccessKey: "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",
    roles: [
      {
        code: "role_gm",
        value: "GM",
      },
      {
        code: "role_cm",
        value: "CM",
      },
      {
        code: "role_wm",
        value: "WM",
      },
      {
        code: "role_cc",
        value: "CC",
      },
      {
        code: "role_ff",
        value: "FF",
      },
    ],
    languages: [
      {
        code: "language_de",
        locale: "de",
      },
      {
        code: "language_ar",
        locale: "ar",
      },
    ],
    countries: [
      {
        code: "country_de",
        countryCode: "de",
      },
      {
        code: "country_nl",
        countryCode: "nl",
      },
      {
        code: "country_fr",
        countryCode: "fr",
      },
      {
        code: "country_es",
        countryCode: "es",
      },
    ],
    payments: [
      {
        code: "payment_sofort",
        value: "SOFORT",
      },
      {
        code: "payment_cash",
        value: "CASH",
      },
      {
        code: "payment_invoice",
        value: "INVOICE",
      },
      {
        code: "payment_credit",
        value: "CREDIT",
      },
    ],
    appDestination: [
      {
        code: "app_destination_DISCOUNTS",
        value: "DISCOUNTS",
      },
      {
        code: "app_destination_PRODUCT",
        value: "PRODUCT",
      },
      {
        code: "app_destination_FRIENDSHIP_CODE_HELP",
        value: "FRIENDSHIP_CODE_HELP",
      },
      {
        code: "app_destination_SECTION",
        value: "SECTION",
      },
      {
        code: "app_destination_SHIPMENT_INFO",
        value: "SHIPMENT_INFO",
      },
      ,
      {
        code: "app_destination_OCCASION",
        value: "OCCASION",
      },
    ],
    couponType: [
      {
        code: "coupon_type_PERCENTAGE",
        value: "PERCENTAGE",
      },
      {
        code: "coupon_type_AMOUNT",
        value: "AMOUNT",
      },
    ],
    couponTarget: [
      {
        code: "coupon_target_NEW_CUSTOMERS",
        value: "NEW_CUSTOMERS",
      },
      {
        code: "coupont_target_LOYAL_CUSTOMERS",
        value: "LOYAL_CUSTOMERS",
      },
      {
        code: "coupon_target_SECTION",
        value: "SECTION",
      },
      {
        code: "coupon_target_SINGLE_PRODUCT",
        value: "SINGLE_PRODUCT",
      },
    ],
    discountType: [
      {
        code: "discount_type_SALES",
        value: "SALES",
      },
      {
        code: "discount_type_OFFER",
        value: "OFFER",
      },
    ],
    creditsDescription: [
      {
        code: "credits_description_GIFT",
        value: "GIFT",
      },
      {
        code: "credits_description_APPOLOGIZE",
        value: "APPOLOGIZE",
      },
      {
        code: "credits_description_COMPENSATION",
        value: "COMPENSATION",
      },
      {
        code: "credits_description_MY_FRIENDSHIP_CODE_USED",
        value: "MY_FRIENDSHIP_CODE_USED",
      },
      {
        code: "credits_description_MY_FRIEND_CODE_USED",
        value: "MY_FRIEND_CODE_USED",
      },
      {
        code: "credits_description_REDEEM",
        value: "REDEEM",
      },
      {
        code: "credits_description_OLD_BALANCE",
        value: "OLD_BALANCE",
      },
    ],
    baseMeasureUnit:[
      {
        code:"base_measure_unit_g",
        value:"g"
      },
      {
        code:"base_measure_unit_kg",
        value:"kg"
      },
      {
        code:"base_measure_unit_l",
        value:"l"
      },
      {
        code:"base_measure_unit_ml",
        value:"ml"
      },
      {
        code:"base_measure_unit_cm",
        value:"cm"
      },
      {
        code:"base_measure_unit_m",
        value:"m"
      },
      {
        code:"base_measure_unit_mg",
        value:"mg"
      },
      {
        code:"base_measure_unit_cl",
        value:"cl"
      }
    ],
    gender:[
      {
        code:"gender_male",
        value:"male"
      },
      {
        code: "gender_female",
        value: "female"
      },
      {
        code:"gender_unisex",
        value:"unisex"
      }
    ],
    ageGroup:[
      {
        code:"age_group_newborn",
        value:"newborn"
      },
      {
        code:"age_group_infant",
        value:"infant"
      },
      {
        code:"age_group_toddler",
        value:"toddler"
      },
      {
        code:"age_group_kids",
        value:"kids"
      },
      {
        code:"age_group_adult",
        value:"adult"
      }
    ]
  }
  return AladdinConfig
}

export default Aladdin
