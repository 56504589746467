import React, { useContext } from 'react'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import List from '@material-ui/core/List'
import EditIcon from '@material-ui/icons/Edit'

import { API, graphqlOperation, Storage } from 'aws-amplify'

import PhotoViewer from '../helpers/PhotoViewer'
import GlobalContext from '../../context/global-context'
import ProductsContext from '../../context/products-context'
import { updateProductThumbnail, addProductImage, removeProductImage } from '../../graphql/mutations'
import Translation from '../helpers/Translation'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column1of3: {
        flexBasis: '33.33%',
    },
    column1of6: {
        flexBasis: '16.66%',
    },
    column1of2: {
        flexBasis: '50%',
    },
    column1of4: {
        flexBasis: '25%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    gridList: {
        flexWrap: 'nowrap',
        width: '25em',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    title: {
        color: theme.palette.secondary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    thumbPreview: {
        width: '48px',
        height: '48px',
        objectFit: 'cover',
        borderRadius: '30%',
        cursor: 'pointer',
        '&:hover': {
            width: '25em',
            height: '25em',
        }
    }
    ,
    imagePreview: {
        width: '10em',
        height: '10em',
        objectFit: 'cover',
        cursor: 'pointer'
    },
    barcodeWarning:{
        marginLeft:theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));
const thumbPlaceholder = './images/image-placeholder-250-250.png'
const imagePlaceholder = './images/image-placeholder-512-512.png'
const Product = ({ product, panelExpansionHandler, expanded }) => {
    const { currentUser } = useContext(GlobalContext)
    const { dispatch, startEditBarcodeHandler, startEditProductHandler } = useContext(ProductsContext)
    const classes = useStyles()

    async function uploadThumbnailHandler(file) {
        if (file) {
            const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/products/${product.sku}/thumbnail.${extension}`
            try {
                await Storage.put(key, file, {
                    contentType: mimeType
                })
                const input = {
                    sku: product.sku,
                    userLocale: currentUser.locale,
                    thumbnail: key
                }
                await API.graphql(graphqlOperation(updateProductThumbnail, input))
                //let result = await API.graphql(graphqlOperation(updateProductThumbnail, input))
                let newProduct = product
                product.thumbnail = key
                dispatch({ type: 'UPDATE_PRODUCT', product: newProduct })
            } catch (err) {
                console.log('error: ', err)
            }
        }
    }

    async function uploadImageHandler(file) {
        if (file) {
            const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/products/${product.sku}/${product.nextImageId}.${extension}`
            try {
                await Storage.put(key, file, {
                    contentType: mimeType
                })
                const input = {
                    sku: product.sku,
                    userLocale: currentUser.locale,
                    image: key
                }
                await API.graphql(graphqlOperation(addProductImage, input))
                let newProduct = product
                newProduct.nextImageId += 1
                newProduct.images.push(key)
                dispatch({ type: 'UPDATE_PRODUCT', product: newProduct })
            } catch (err) {
                console.log('error: ', err)
            }
        }
    }

    async function removeImageHandler(image) {
        try {
            await Storage.remove(image)
            const input = {
                sku: product.sku,
                userLocale: currentUser.locale,
                image
            }
            await API.graphql(graphqlOperation(removeProductImage, input))
            let newProduct = product
            let newImages = product.images.filter((value)=>{
                if(value !== image){
                    return value
                }
            })
            newProduct.images = newImages
            dispatch({ type: 'UPDATE_PRODUCT', product: newProduct })
        } catch (err) {
            console.log('error: ', err)
        }
    }
    const handleEditBarcode = () => {
        startEditBarcodeHandler(product)
    }
    const handleEditProduct = () =>{
        startEditProductHandler(product)
    }
    const handleExpandedChange = sku => (event, isExpanded) =>{
        panelExpansionHandler(sku, isExpanded)
    }
    return (
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded} onChange={handleExpandedChange(product.sku)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <div className={classes.column1of6}>
                        <Typography >{!product.barcode && <WarningOutlinedIcon className={classes.barcodeWarning} color="error" onClick={handleEditBarcode} />}{product.sku}</Typography>
                    </div>
                    <div className={classes.column1of6}>
                        <Typography className={classes.heading}>{product.barcode}</Typography>
                    </div>
                    <div className={classes.column1of2}>
                        <Typography className={classes.heading}><EditIcon className={classes.barcodeWarning} onClick={handleEditProduct} />{product.name}</Typography>
                    </div>
                    <div className={clsx(classes.column1of6, classes.alignCenter)} >
                        <PhotoViewer className={classes.thumbPreview} imgKey={product.thumbnail} placeholder={thumbPlaceholder} uploadHandler={uploadThumbnailHandler} />
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    <div className={classes.column1of2}>
                        <List dense={true}>
                            {product.nameTranslations.map((translation) => (
                                <Translation
                                    key={translation.locale}
                                    translation={translation}
                                    canRemove={false}
                                />
                            ))}
                        </List>
                    </div>
                    <div className={classes.column1of6}></div>
                    <div className={clsx(classes.column1of3, classes.helper)}>
                        <Typography variant="caption">
                            Select your destination of choice
                        </Typography>
                        <GridList cellHeight="auto" className={classes.gridList} cols={2.5}>
                            {product.images.map((image, index) => (
                                <GridListTile key={index} className={classes.tile}>
                                    <PhotoViewer disableUpload className={classes.imagePreview} imgKey={image} />
                                    <GridListTileBar
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                        actionIcon={
                                            <IconButton onClick={() => removeImageHandler(image)}>
                                                <DeleteOutlineIcon className={classes.title} />
                                            </IconButton>
                                        }
                                    />
                                </GridListTile>
                            ))}
                            <GridListTile>
                                <PhotoViewer className={classes.imagePreview} imgKey="" placeholder={imagePlaceholder} uploadHandler={uploadImageHandler} />

                            </GridListTile>
                        </GridList>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}
export default Product