import React from "react"
import { Decimal } from "decimal.js"

import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"

import { I18n } from "aws-amplify"
import Aladdin from "../../aladdin-config"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column1of3: {
    flexBasis: "33.33%",
  },
  column1of6: {
    flexBasis: "16.66%",
  },
  column1of12: {
    flexBasis: "8.33%",
  },
  column1of2: {
    flexBasis: "50%",
  },
  column1of4: {
    flexBasis: "25%",
  },
}))
const aladdin = Aladdin()
const OutShelvesItems = ({
  item,
  panelExpansionHandler,
  expanded,
  moveToShelfHandler,
}) => {
  const classes = useStyles()

  const handleExpandedChange = (sku) => (event, isExpanded) => {
    panelExpansionHandler(sku, isExpanded)
  }

  const formateDate = (dateToFormate) => {
    const parts = dateToFormate.split("T")
    return parts[0]
  }

  const moveToShelf = (
    id,
    sku,
    warehouseId,
    inventoryEntryId,
    quantity,
    expires,
    providerId,
    finalCost,
    shippingCostShare,
    barcode
  ) => {
    let costWithShipment = getCostWithShipment(finalCost, shippingCostShare)
    let keepDiscount = false
    //If discount type is SALES so don't ask, just do not keep discount
    if (item.discountType && item.discountType !== "SALES") {
      const r = window.confirm(I18n.get("message_discount_exists"))
      if (r === true) keepDiscount = true
    }
    moveToShelfHandler(
      id,
      sku,
      warehouseId,
      inventoryEntryId,
      quantity,
      expires,
      providerId,
      costWithShipment,
      barcode,
      keepDiscount
    )
  }

  const getCostWithShipment = (cost, shippingCostShare) => {
    const d = new Decimal(cost)
    const costWithShipment = d
      .times(1 + shippingCostShare)
      .toDP(2)
      .toNumber()
    return costWithShipment
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded}
        onChange={handleExpandedChange(item.sku)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column1of4}>
            <Typography>{item.sku}</Typography>
          </div>
          <div className={classes.column1of2}>
            <Typography className={classes.heading}>{item.name}</Typography>
          </div>
          <div className={classes.column1of4}>
            <Typography>{`${I18n.get("label_number_entries")}: ${
              item.inventoryItems.length
            }`}</Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <List className={classes.root}>
            {item.inventoryItems.map((entry) => (
              <ListItem key={entry.id}>
                <ListItemText
                  className={classes.column1of12}
                  primary={I18n.get("label_available_amount")}
                />
                <ListItemText
                  className={classes.column1of12}
                  primary={entry.quantity}
                />
                <ListItemText
                  className={classes.column1of12}
                  primary={I18n.get("label_expires_on")}
                />
                <ListItemText
                  className={classes.column1of12}
                  primary={entry.expires ? formateDate(entry.expires) : "-"}
                />
                <ListItemText
                  className={classes.column1of12}
                  primary={I18n.get("label_final_cost")}
                />
                <ListItemText
                  className={classes.column1of12}
                  primary={getCostWithShipment(
                    entry.finalCost,
                    entry.shippingCostShare
                  )}
                />
                <ListItemSecondaryAction className={classes.column1of12}>
                  <IconButton
                    color="primary"
                    onClick={(e) =>
                      moveToShelf(
                        entry.id,
                        item.sku,
                        entry.warehouseId,
                        entry.inventoryEntryId,
                        entry.quantity,
                        entry.expires,
                        entry.providerId,
                        entry.finalCost,
                        entry.shippingCostShare,
                        entry.barcode
                      )
                    }
                  >
                    <PublishOutlinedIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default OutShelvesItems
