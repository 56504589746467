import React, { useReducer, useContext, useEffect } from "react"

import { API, graphqlOperation } from "aws-amplify"

import GlobalContext from "../../context/global-context"
import itemsReducer from "../../reducers/items"
import OutShelvesItem from "./OutShelvesItem"

import { listRunOutShelves } from "../../graphql/queries"
import { moveItemToShelf } from "../../graphql/mutations"

const RunOutShelves = () => {
  const [{ runOutShelves }, dispatch] = useReducer(itemsReducer, {
    runOutShelves: [],
  })
  const { currentUser, warehouse } = useContext(GlobalContext)
  const [expanded, setExpanded] = React.useState(false)

  useEffect(() => {
    loadRundOutShelves()
  }, [])

  const loadRundOutShelves = async () => {
    const params = {
      warehouseId: warehouse.id,
      userLocale: currentUser.locale,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(listRunOutShelves, params)
      )
      dispatch({
        type: "LOAD_RUN_OUT_SEHLVES",
        items: response.data.listRunOutShelves.items,
      })
    } catch (error) {
      console.log(error.errors)
    }
  }

  const moveToShelfHandler = async (
    id,
    sku,
    warehouseId,
    inventoryEntryId,
    quantity,
    expires,
    providerId,
    cost,
    barcode,
    keepDiscount
  ) => {
    const params = {
      input: {
        id,
        sku,
        warehouseId,
        inventoryEntryId,
        quantity,
        expires,
        providerId,
        cost,
        barcode,
        keepDiscount,
      },
    }
    try {
      const response = await API.graphql(
        graphqlOperation(moveItemToShelf, params)
      )
      if (response.data.moveItemToShelf === true) {
        dispatch({ type: "MOVED_TO_SHELF", sku: sku })
      }
    } catch (responseWithError) {
      console.log(responseWithError.errors)
    }
  }
  const panelExpansionHandler = (sku, isExpanded) => {
    setExpanded(isExpanded ? sku : false)
  }

  return (
    <div>
      {runOutShelves.map((item) => (
        <OutShelvesItem
          key={item.sku}
          item={item}
          expanded={expanded === item.sku}
          panelExpansionHandler={panelExpansionHandler}
          moveToShelfHandler={moveToShelfHandler}
        />
      ))}
    </div>
  )
}

export default RunOutShelves
