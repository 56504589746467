const bannersReducer = (state, action) => {
    switch(action.type){
        case 'UPDATE_BANNER':
            let newBannersList = state.banners.map((banner) => {
                if (banner.id === action.banner.id) {
                    return action.banner
                }
                return banner
            })
            return {...state, banners: newBannersList}
        case 'LOAD_BANNERS':
            return {...state, banners: action.banners}
        case 'ADD_BANNER':
            return {...state, banners: [action.banner, ...state.banners]}
        default:
            return state
    }
}
export default bannersReducer