import React, { useReducer, useState, useEffect } from "react"
import { Paper, Button } from "@material-ui/core"
import API, { graphqlOperation } from "@aws-amplify/api"
import ordersReducer from "../../reducers/orders"
import OrdersFilterForm from "./OrdersFilterForm"
import { searchOrders } from "../../graphql/queries"
import OrdersList from "./OrdersList"
import { I18n } from "aws-amplify"
import { getFormattedShortDate } from "../../utils/dateUtil"

const OrdersPage = (props) => {
  const [{ orders }, dispatch] = useReducer(ordersReducer, { orders: [] })
  const [sum, setSum] = useState(0)

  const clickHandler = (order) => {
    //props.history.push(`/customerDetails/${order.customerNumber}`)
    window.open(`/customerDetails/${order.customerNumber}`, "_blank")
  }

  const filterOrders = async (
    countryCode,
    warehouseId,
    paymentType,
    aladdinPaymentStatus,
    orderStatus,
    fromDate,
    toDate
  ) => {
    const input = {
      countryCode,
      warehouseId,
      paymentType,
      aladdinPaymentStatus,
      orderStatus,
      fromDate,
      toDate,
    }
    const response = await API.graphql(
      graphqlOperation(searchOrders, { input })
    )

    dispatch({ type: "LOAD_ORDERS", orders: response.data.searchOrders })
  }
  const calculateTotal = (orders) => {
    let s = 0
    for (let i = 0; i < orders.length; i++) {
      s = s + orders[i].orderTotal
    }
    return setSum(s)
  }
  useEffect(() => {
    calculateTotal(orders)
  }, [orders])
  const exportToFBCatalog = () => {
    let rows = [
      [
        "Bestell-Nr.",
        "Rechnungsdatum",
        "Name",
        "Rechnungs-Nr.",
        "Betrag",
        "USt",
        "Adresse",
        "Zahlart",
        "Zahlstatus",
      ],
    ]
    orders.map((order) => {
      if(order.orderStatus !== "CANCELLED"){
        const formattedDate = getFormattedShortDate(order.orderDate)
        const orderTotal = order.orderTotal - (order.creditsPromotion ? order.creditsPromotion : 0)
        let paymentStatus = "Bezahlt"
        if(order.paymentType === "ALADDIN_INVOICE"){
          if(order.aladdinPaymentStatus === "PENDING") paymentStatus = "Anstehend"
        }
        const billingAddress = `${order.billingAddress.street} ${order.billingAddress.houseNr} - ${order.billingAddress.zipCode} ${order.billingAddress.city}`
        const fullName = `${order.billingAddress.firstName} ${order.billingAddress.lastName}`

        if(order.VATValues.length === 1){
          const vatCat = parseFloat(order.VATValues[0].category)
          rows.push([
            order.orderNumber,
            formattedDate,
            fullName,
            order.invoiceNumber,
            parseFloat((orderTotal).toFixed(2)).toLocaleString('de-DE'),
            `${parseInt(vatCat * 100)}%`,
            billingAddress,
            order.paymentType,
            paymentStatus
          ])
        }else{
          const vatCat1 = parseFloat(order.VATValues[1].category)
          const total1 = (1 + vatCat1) * order.VATValues[1].value / vatCat1
          rows.push([
            order.orderNumber,
            formattedDate,
            fullName,
            order.invoiceNumber,
            parseFloat((total1).toFixed(2)).toLocaleString('de-DE'),
            `${parseInt(vatCat1 * 100)}%`,
            billingAddress,
            order.paymentType,
            paymentStatus
          ])
          const vatCat0 = parseFloat(order.VATValues[0].category)
          const total0 = orderTotal - total1
          rows.push([
            order.orderNumber,
            formattedDate,
            fullName,
            order.invoiceNumber,
            parseFloat((total0).toFixed(2)).toLocaleString('de-DE'),
            `${parseInt(vatCat0 * 100)}%`,
            billingAddress,
            order.paymentType,
            paymentStatus
          ])
        }
        if(order.creditsPromotion < 0){
          rows.push([
            order.orderNumber,
            formattedDate,
            fullName,
            order.invoiceNumber,
            parseFloat((order.creditsPromotion).toFixed(2)).toLocaleString('de-DE'),
            "",
            billingAddress,
            order.paymentType,
            paymentStatus
          ])
        }
        
      }
      
    })
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(";")).join("\n")
    let encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }
  return (
    <Paper>
      <OrdersFilterForm filterOrders={filterOrders} />
      <div>
        {" "}
        {I18n.get("label_count")}: {orders.length}
      </div>
      <div>
        {" "}
        {I18n.get("label_order_total")}: {sum}
      </div>
      <Button variant="contained" onClick={exportToFBCatalog} color="primary">
        Export
      </Button>
      <OrdersList orders={orders} clickHandler={clickHandler} />
    </Paper>
  )
}

export default OrdersPage
