
const categoriesReducer = (state, action) => {
    switch(action.type){
        case 'SET_PARENT':
            return {...state, parent: action.parent}
        case 'LOAD_CATEGORIES':
            return {...state, categories: action.categories}
        case 'CREATE_CATEGORY':
            return {...state, categories: [...state.categories, action.category]}
        case 'UPDATE_CATEGORY':
            let newCategoriesList = state.categories.map((category)=>{
                if(category.sku === action.category.sku){
                    return action.category
                }
                return category
            })
            return {...state, categories: newCategoriesList}
        default:
            return state
    }
}
export default categoriesReducer