import React, {useEffect, useReducer, useContext, useState} from 'react'

import API, { graphqlOperation } from '@aws-amplify/api'

import EmployeesContext from '../../context/employees-context'
import GlobalContext from '../../context/global-context'
import EmployeesList from './EmployeesList'
import CreateEmployeeForm from './CreateEmployeeForm'
import EditEmployeeForm from './EditEmployeeForm'
import employeesReducer from '../../reducers/employees'
import { listEmployees } from '../../graphql/queries';

const EmployeesPage = ()=> {
    
    const [state, dispatch] = useReducer(employeesReducer, {employees:[], isEditing: false, username:''})
    const [nextToken, setNextToken] = useState(null)
    const [more, setMore] = useState(true)
    
    
    useEffect(()=>{
        let isSubscribed = true
        loadEmployees()
        .then((data)=>{
            if(isSubscribed){
                setEmployees(data.data.listEmployees)
                
            } 
        }).catch((data)=> (console.log(data.errors)))
        
        
        return () => {
            isSubscribed = false
        }
        
        
    },[])

    async function loadEmployees () {
        const queryParams = {
            limit: 10,
            nextToken: nextToken
        }
        return API.graphql(graphqlOperation(listEmployees, queryParams))
        
    }
    const setEmployees = (listEmployeesResult) => {
        console.log(listEmployeesResult)
        const employees = listEmployeesResult.items
        const token = listEmployeesResult.nextToken
        if(token === null){
            setMore(false)
        }
        setNextToken(token)
        dispatch({type: 'LOAD_EMPLOYEES', employees})
    }
    
    return (
        <EmployeesContext.Provider value={{...state , dispatch}}>
            <div className="form-above-list">
                {!state.isEditing && <CreateEmployeeForm/>}
                {state.isEditing && <EditEmployeeForm/>}
            </div>
            <EmployeesList />
            {more && <button onClick={loadEmployees}>Load More...</button>}
        </EmployeesContext.Provider>
    )
}

export default EmployeesPage