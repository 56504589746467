const warehousesReducer = (state, action) => {
    switch(action.type){
        case 'LOAD_WAREHOUSES':
            return {...state, warehouses:action.warehouses}
        case 'CREATE_WAREHOUSE':
            return {...state, warehouses: [...state.warehouses, action.warehouse]}
        default:
            return state
    }
}
export default warehousesReducer