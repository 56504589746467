import React, { useReducer, useContext, useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { I18n } from 'aws-amplify'
import { API, graphqlOperation } from 'aws-amplify'

import GlobalContext from '../../context/global-context'
import CategoriesContext from '../../context/categories-context'
import categoriesReducer from '../../reducers/categories'
import { listCategories, listSections } from '../../graphql/queries'
import CategoriesList from './CategoriesList'
import CreateCategoryDialog from './CreateCategoryDialog'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 150

    },
    grid: {
        padding: theme.spacing(5)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const CategoriesPage = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(categoriesReducer, { parent: null, categories: [] })
    const { currentUser } = useContext(GlobalContext)
    const [sections1, setSections1] = useState([])
    const [sections2, setSections2] = useState([])
    const [selectedSection1SKU, setSelectedSection1SKU] = useState("")
    const [selectedSection2SKU, setSelectedSection2SKU] = useState("")
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const userLocale = currentUser.locale
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            loadSections1()
        }

        return () => {
            isSubscribed = false
        }

    }, [])

    useEffect(() => {
        loadSections2()
    }, [selectedSection1SKU])

    useEffect(() => {
        //Since the nextChildSKU of the parent has been changed, it needs to be replaced
        replaceReloadedSection()
    }, [state.parent])

    const replaceReloadedSection = () => {
        if(state.parent){
            let newSections2List = sections2.map((section2) => {
                if (section2.sku === state.parent.sku) {
                    return state.parent
                }
                return section2
            })
            setSections2(newSections2List)
        }
        
    }

    const section2Selected = (sku) => {
        let selectedParent = null
        sections2.map((section2) => {
            if (sku === section2.sku) {
                selectedParent = section2
            }
        })
        if (selectedParent) {
            dispatch({ type: 'SET_PARENT', parent: selectedParent })
        }
        setSelectedSection2SKU(sku)
        loadCategories(sku)
    }

    const populateLoadedCategories = (loadedCategories) => {
        dispatch({ type: 'LOAD_CATEGORIES', categories: loadedCategories })
    }

    const loadSections1 = () => {
        loadSectionsFromDB()
            .then((data) => {
                setSections1(data.data.listSections.items)
            }
            ).catch((data) => {
                console.log(data)
            })
    }

    const loadSections2 = () => {
        loadSectionsFromDB(selectedSection1SKU)
            .then((data) => {
                setSections2(data.data.listSections.items)
            }
            ).catch((data) => {
                console.log(data)
            })
    }

    const loadCategories = (parentSKU) => {
        loadCategoriesFromDB(parentSKU)
            .then((data) => {
                populateLoadedCategories(data.data.listCategories.items)
            }
            ).catch((data) => {
                console.log(data.errors)
            })
    }

    async function loadSectionsFromDB(selectedParent) {
        let params = { userLocale }
        if (selectedParent) {
            params["parent"] = selectedParent
        }
        return API.graphql(graphqlOperation(listSections, params))

    }

    async function loadCategoriesFromDB(selectedParent) {
        let params = { userLocale }
        params["parent"] = selectedParent
        return API.graphql(graphqlOperation(listCategories, params))

    }
    const newCategory = () => {
        setOpen(true);
    }

    return (
        <Paper >
            <CategoriesContext.Provider value={{ ...state, dispatch }} >
                <Grid className={classes.grid} container spacing={3} justify="space-between">
                    <Grid item>
                        <Typography variant="h5" color='textPrimary'>
                            {I18n.get('label_categories')}
                            <IconButton color="primary" onClick={newCategory}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Typography>

                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="section1-select">{I18n.get('label_section1')}</InputLabel>
                            <Select
                                onChange={e => setSelectedSection1SKU(e.target.value)}
                                value={selectedSection1SKU}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{
                                    id: "section1-select"
                                }}
                            >
                                {sections1.map((section1, index) => (
                                    <MenuItem
                                        key={index}
                                        value={section1.sku}
                                    >
                                        {section1.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="section2-select">{I18n.get('label_section2')}</InputLabel>
                            <Select
                                onChange={e => section2Selected(e.target.value)}
                                value={selectedSection2SKU}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{
                                    id: "section2-select"
                                }}
                            >
                                {sections2.map((section2, index) => (
                                    <MenuItem
                                        key={index}
                                        value={section2.sku}
                                    >
                                        {section2.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <CategoriesList />
                {selectedSection2SKU && <CreateCategoryDialog
                    open={open}
                    onClose={handleClose}
                />}

            </CategoriesContext.Provider>
        </Paper>

    )

}

export default CategoriesPage