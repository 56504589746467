const comparatorByName = (a, b)=>{
    return a.name > b.name ? 1 : -1
}
const productsToBeItems = (state, action) => {
    switch (action.type) {
        case 'SET_WAREHOUSE':
            return {...state, warehouse: action.warehouse}
        case 'SET_PARENT':
            return { ...state, parent: action.parent }
        case 'LOAD_PRODUCTS':
                let sorted1 = action.products
            return { ...state, products: sorted1.sort(comparatorByName) }
        case 'LOAD_MORE_PRODUCTS':
                let sorted2 = [...state.products, ...action.products]
            return { ...state, products: sorted2.sort(comparatorByName) }
        case 'CLEAR_PRODUCTS':
            return { ...state, products: [] }
        case 'START_ITEM_SETUP':
            return {...state, isItemSetup: true, product: action.product}
        case 'FINISH_ITEM_SETUP':
                let newProductsList = state.products.filter((product) => {
                    if (product.sku !== action.sku) {
                        return product
                    }
                })
                return {...state, isItemSetup: false, product: null, products: newProductsList}
        default:
            return state
    }
}
export default productsToBeItems