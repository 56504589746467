
const comparatorByName = (a, b)=>{
    return a.name > b.name ? 1 : -1
}
const brandsReducer = (state, action) => {
    switch(action.type){
        case 'LOAD_BRANDS':
            let sorted = action.brands
            return {...state, brands: sorted.sort(comparatorByName)}
        case 'CREATE_BRAND':
            return {...state, brands: [...state.brands, action.brand]}
        case 'UPDATE_BRAND':
            let newBrandsList = state.brands.map((brand)=>{
                if(brand.id === action.brand.id){
                    return action.brand
                }
                return brand
            })
            return {...state, brands: newBrandsList}
        default:
            return state
    }
}
export default brandsReducer