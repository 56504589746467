const ticketsReducer = (state, action) => {
    switch(action.type){
        case 'LOAD_NEW_TICKETS':
            return {...state, newTickets: action.newTickets}
        case 'LOAD_ASSIGNED_TICKETS':
            return {...state, assignedTickets: action.assignedTickets}
        case 'ADD_NEW_TICKET':
            return {...state, newTickets: [...state.newTickets, action.newTicket]}
        case 'ASSIGN_TICKET':
            let remainTickets = state.newTickets.filter((newTicket)=>{
                if(action.ticket.ticketId !== newTicket.ticketId){
                    return newTicket
                }
            })
            return {...state, newTickets: remainTickets, assignedTickets:[...state.assignedTickets, action.ticket]}
        default:
            return state
    }
}
export default ticketsReducer