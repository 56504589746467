const comparatorByDate = (a, b)=>{
    return a.orderDate < b.orderDate ? 1 : -1
}
const ordersReducer = (state, action) => {
    switch(action.type){
        case 'UPDATE_ORDER':
            let newOrdersList = state.orders.map((order) => {
                if (order.orderNumber === action.order.orderNumber) {
                    return action.order
                }
                return order
            })
            return {...state, orders: newOrdersList}
        case 'LOAD_ORDERS':
            let sorted = action.orders
            return {...state, orders: sorted.sort(comparatorByDate)}
        case 'LOAD_ORDER_ITEMS':{
            return {...state, orderItems: action.orderItems}
        }
        case 'REMOVE_ORDER':
            let newOrders = state.orders.filter((order)=>{
                if(order.orderNumber !== action.order.orderNumber) return order
            })
            return {...state, orders: newOrders, orderItems:[]}
        default:
            return state
    }
}
export default ordersReducer