
const providersReducer = (state, action) => {
    switch(action.type){
        case 'LOAD_PROVIDERS':
            return {...state, providers: action.providers}
        case 'CREATE_PROVIDER':
            return {...state, providers: [...state.providers, action.provider]}
        case 'UPDATE_PROVIDER':
            let newProvidersList = state.providers.map((provider)=>{
                if(provider.id === action.provider.id){
                    return action.provider
                }
                return provider
            })
            return {...state, providers: newProvidersList}
        default:
            return state
    }
}
export default providersReducer