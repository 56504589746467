import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core"
import React from "react"
import { Fragment } from "react"
import { getFormattedDateTime } from "../../utils/dateUtil"
import { I18n } from "aws-amplify"

const FraudDetails = (props) => {
  const getFraudLevelName = (level) => {
    const code = `fraud_level_${level}`
    return I18n.get(code)
  }
  const getFraudColor = (level) => {
    if (level === "LOW_RIKS") return "warning"
    return "error"
  }
  const formatAddress = (address) => {
    if (!address) return ""
    console.log(address)
    let formattedAddress = address.firstName + " " + address.lastName
    formattedAddress =
      formattedAddress + ", " + address.street + " " + address.houseNr
    formattedAddress =
      formattedAddress + ", " + address.zipCode + " " + address.city
    return formattedAddress
  }
  const orderClicked = (customerNumber) => {
    window.open(`/customerDetails/${customerNumber}`, "_blank")
  }
  return (
    <Fragment>
      {props.details.map((fraudDetail, index) => (
        <Fragment key={index}>
          <Typography color={getFraudColor(fraudDetail.level)}>
            {getFraudLevelName(fraudDetail.level)}
          </Typography>
          <Table>
            <TableBody>
              {fraudDetail.suspectedOrders.map((order, i) => (
                <TableRow
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => orderClicked(order.customerNumber)}
                >
                  <TableCell>
                    <Grid container spacing={3}>
                      <Grid item>
                        {I18n.get("label_order_number")}: {order.orderNumber}
                      </Grid>
                      <Grid item>
                        {I18n.get("label_customer_number")}:{" "}
                        {order.customerNumber}
                      </Grid>
                      <Grid item>
                        {I18n.get("label_date")}:{" "}
                        {getFormattedDateTime(order.orderDate)}
                      </Grid>
                      <Grid item>
                        {I18n.get("label_order_total")}: {order.orderTotal}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item item xs={6}>
                        {I18n.get("label_telephone")}: {order.mobileNumber}
                      </Grid>
                      <Grid item item xs={6}>
                        {I18n.get("label_ip_address")}: {order.ipAddress}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {I18n.get("label_billing_address")}:{" "}
                        {formatAddress(order.billingAddress)}
                      </Grid>
                      <Grid item item xs={12}>
                        {I18n.get("label_shipping_address")}:{" "}
                        {formatAddress(order.shippingAddress)}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Fragment>
      ))}
    </Fragment>
  )
}

export default FraudDetails
