import React, {useContext} from 'react';

import {I18n} from 'aws-amplify'

import EmployeesContext from '../../context/employees-context';

const Employee = ({employee}) => {

    const {dispatch} = useContext(EmployeesContext)
    const startEditing = () => {
        dispatch({type: 'START_EDITING', username: employee.username})
    }
    const addressParts = employee.address.split('#')
    const formattedAddress = `${addressParts[2]}, ${addressParts[1]}, ${addressParts[0]}`
    const languageDictionaryKey = `language_${employee.locale}`
    const countryDictionaryKey = `country_${employee.countryCode}`
    let roleDictionaryKey = 'role_'
    const employeeRolesSet = new Set(employee.groups)
    if(employeeRolesSet.has('GM')){
        roleDictionaryKey = `${roleDictionaryKey}gm`
    }else if(employeeRolesSet.has('CM')){
        roleDictionaryKey = `${roleDictionaryKey}cm`
    }else if (employeeRolesSet.has('WM')){
        roleDictionaryKey = `${roleDictionaryKey}wm`
    }else if(employeeRolesSet.has('CC')){
        roleDictionaryKey = `${roleDictionaryKey}cc`
    }else{
        roleDictionaryKey = `${roleDictionaryKey}ff`
    }

    return (
        <div className="list-item">
            <div  className="col-2-of-10">{employee.username}</div>
            <div className="col-1-half-of-10">{employee.employeeName}</div>
            <div className="col-1-of-10">{employee.telephone}</div>
            <div className="col-2-of-10">{formattedAddress}</div>
            <div className="col-1-of-10">{I18n.get(countryDictionaryKey)}</div>
            <div className="col-1-of-10">{I18n.get(languageDictionaryKey)}</div>
            <div className="col-1-of-10">{I18n.get(roleDictionaryKey)}</div>
            <button className="col-half-of-10" onClick={startEditing}>Edit</button>
        </div>
    )
}
export { Employee as default}