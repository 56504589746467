import React from 'react'

import {I18n} from 'aws-amplify'

import {toHumanReadableAddress} from '../../utils/address'

const Warehouse = ({warehouse}) => {
    const countryDictionaryKey = `country_${warehouse.countryCode}`
    const address = toHumanReadableAddress(warehouse.address)
    return (
        <div className="list-item">
            <div  className="col-2-of-10">{warehouse.name}</div>
            <div  className="col-1-of-10">{I18n.get(countryDictionaryKey)}</div>
            <div  className="col-4-of-10">{address}</div>
            <div  className="col-3-of-10">{warehouse.managerName}</div>
        </div>
    )
}

export default Warehouse