import React from 'react'
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';

const OrderItem = ({ orderItem }) => {
    return (
        <TableRow >
            <TableCell
                key='sku'
                style={{ width: 75 }}
            >
                <Typography variant="subtitle1" color='textPrimary'>
                    {orderItem.sku}
                </Typography>
            </TableCell>
            <TableCell
                key='name'
                style={{ width: 200 }}
            >
                <Typography variant="subtitle1" color='textPrimary'>
                    {orderItem.name}
                </Typography>
            </TableCell>
            <TableCell
                key='quantity'
                style={{ width: 25 }}
            >
                <Typography variant="subtitle1" color='textPrimary'>
                    {orderItem.quantity}
                </Typography>
            </TableCell>
            <TableCell
                key='listPrice'
                style={{ width: 50 }}
            >
                <Typography variant="subtitle1" color='textPrimary'>
                    {orderItem.listPrice ? orderItem.listPrice : orderItem.finalPrice}
                </Typography>
            </TableCell>
            <TableCell
                key='discount'
                style={{ width: 50 }}
            >
                <Typography variant="subtitle1" color='textPrimary'>
                    {orderItem.discount}
                </Typography>
            </TableCell>
            <TableCell
                key='finalPrice'
                style={{ width: 50 }}
            >
                <Typography variant="subtitle1" color='textPrimary'>
                    {orderItem.finalPrice}
                </Typography>

            </TableCell>
        </TableRow>
    )
}

export default OrderItem